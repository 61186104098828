import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosSearch } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";
import { useDispatch } from "react-redux";
import { toggleNavbar } from "../features/userSlice";
import FeedbackModal from "./FeedbackModal";
import logo from "../assets/images/logo-circle.png";
const Header = ({ page }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleSearch = (event) => {
    event.preventDefault();
    if (query.trim() !== "") {
      navigate(`/search?query=${query}`);
      setQuery("");
      //   toggleMenu();
    }
  };

  const toggleMenu = () => {
    dispatch(toggleNavbar());
    // document.body.style.overflow = showNav ? "" : "hidden";
  };

  const loginPage = page === "login";
  const buttonClasses = "font-semiBold";
  return (
    <>
      <div className="fixed top-0 left-0 right-0 flex flex-row gap-2 justify-between items-center px-3 md:px-8 z-10 bg-white h-14 shadow-sm border-[1px] border-gray-100">
        <div className="flex flex-row gap-4 lg:gap-12 items-center w-full">
          {/* <img src={logo} alt={"Logo"} className="hidden md:block w-10 h-10" /> */}
          <p
            className="hidden lg:block text-3xl text-gray-700"
            style={{ fontFamily: "dancing-script" }}
          >
            Friengle
          </p>

          <GiHamburgerMenu
            className={`block my-auto md:hidden fill-black size-7 cursor-pointer self-start`}
            onClick={toggleMenu}
          />
          <form className="w-full relative" onSubmit={handleSearch}>
            <IoIosSearch
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"
              size={20}
            />
            <input
              type="search"
              placeholder="@username or Post Title"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              className="w-full md:w-[500px] h-8 pl-10 pr-2 rounded-full focus:outline-none bg-gray-100 font-semibold text-sm text-slate-700 bg-gray-200 placeholder-slate-600 border border-gray-300"
            />
          </form>
        </div>
        <div className="self-end">
          <button
            className="hidden lg:block font-bold text-xs bg-gradient-to-r from-gray-400 via-gray-400 to-black bg-[length:200%_100%] bg-clip-text text-transparent animate-wave-gradient"
            onClick={() => {
              setShowFeedbackModal(!showFeedbackModal);
            }}
          >
            Any suggestions or comments?
          </button>
        </div>
      </div>
      <FeedbackModal show={showFeedbackModal} setShow={setShowFeedbackModal} />
    </>
  );
};

export default Header;
