import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { MdDashboard } from "react-icons/md";
import { FaBook, FaPeopleGroup } from "react-icons/fa6";
import { RxDashboard } from "react-icons/rx";
import { TbTargetArrow } from "react-icons/tb";
import { TbCalendarRepeat } from "react-icons/tb";
import { FaClipboardList, FaStar, FaUserFriends } from "react-icons/fa";
import { IoMdPeople, IoIosNotifications } from "react-icons/io";
import {
  IoSearchOutline,
  IoPersonSharp,
  IoLogOut,
  IoPersonCircleSharp,
} from "react-icons/io5";
import { FaPeoplePulling } from "react-icons/fa6";
import { CgProfile } from "react-icons/cg";
import { GiHamburgerMenu } from "react-icons/gi";
import { resetState as resetGoalState } from "../features/goalSlice";
import { resetState as resetFriendsState } from "../features/friendsSlice";
import { resetState as resetJournalState } from "../features/journalSlice";
import { resetState as resetHabitState } from "../features/habitSlice";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../util/util";
import {
  selectUsername,
  selectLoggedIn,
  toggleNavbar,
  selectProfileInfo,
  selectPoints,
} from "../features/userSlice";
import SocialLinks from "./SocialLinks";
import { selectUnreadNotifications } from "../features/notificationsSlice";
import { selectShowNavbar } from "../features/userSlice";
import { IoNotificationsOutline } from "react-icons/io5";
import { HiMiniTrophy } from "react-icons/hi2";
import Cookies from "js-cookie";
import { calculateLevel } from "../util/level_util";
import { PiLightningFill } from "react-icons/pi";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const Navbar = () => {
  const navigate = useNavigate();
  const unread = useSelector(selectUnreadNotifications);
  const dispatch = useDispatch();
  const username = useSelector(selectUsername);
  const loggedIn = useSelector(selectLoggedIn);
  const [query, setQuery] = useState("");
  const showNav = useSelector(selectShowNavbar);
  const location = useLocation();
  const profileInfo = useSelector(selectProfileInfo);
  const points = useSelector(selectPoints);
  const levelMap = calculateLevel(points);

  const handleSearch = (event) => {
    event.preventDefault();
    if (query.trim() !== "") {
      navigate(`/search?query=${query}`);
      setQuery("");
    }
  };

  const handleNavigation = (path) => {
    if (path) {
      navigate(path);
    }
    dispatch(toggleNavbar());
  };

  const navlinkClasses =
    "flex flex-row gap-3.5 items-center justify-start rounded-full font-medium transition-all duration-50 ";
  const activeLink = "  bg-gray-200 text-black px-4 py-2 text-base ";
  const inactiveLink = " hover:bg-gray-200 py-1.5 px-2 text-sm ";
  const spanClasses = "";
  const NavLinks = ({ display }) => <></>;
  console.log("profile info");
  console.log(profileInfo);
  return (
    <div
      style={{ height: "calc(100vh - 56px)" }}
      className={
        "fixed h-full w-5/6 sm:w-1/2 md:w-[320px] bg-white shadow-xl flex flex-col justify-between px-2 lg:px-5 pt-2 pb-6 transition-all duration-300 ease-in-out " +
        `${
          showNav
            ? "z-50 opacity-100 translate-x-0"
            : "opacity-0 -translate-x-full"
        } md:opacity-100 md:translate-x-0 md:z-auto`
      }
    >
      <div className="flex flex-col gap-2.5 justify-start mt-3 w-auto ">
        <div className="flex flex-row gap-2 mb-8 justify-between items-center">
          <div className="flex flex-row items-center w-full">
            {profileInfo && profileInfo.image ? (
              <img
                src={profileInfo.image}
                className="w-16 h-16 z-10 object-cover rounded-full shadow-xl aspect-square"
              />
            ) : (
              <IoPersonCircleSharp className="fill-gray-200" size={70} />
            )}
            <div className="flex flex-col justify-start items-start flex-1 relative -left-2">
              <p className="ml-7 mb-1 font-semibold text-lg">
                {loggedIn ? username : "Guest"}
              </p>
              {loggedIn && (
                <>
                  <div className="relative w-full flex flex-row items-center relative">
                    {/* <div className="absolute z-20 inset-y-0 -translate-y-1/4 -left-2 bg-green-500 rounded-full p-1 w-8 h-8 flex items-center justify-center shadow-lg">
                      <p className="text-sm font-extrabold italic text-white">
                        {levelMap.level}
                      </p>
                    </div>
                    <div className="h-5 w-full border-[1px] border-slate-300 border-opacity-75 rounded-lg relative bg-gray-100">
                      <div
                        className={`bg-gradient-to-r from-green-200 via-green-400 to-green-500 bg-opacity-85 h-full rounded-full flex flex-row justify-center items-center`}
                        style={{
                          width: `${
                            (levelMap.pointsInCurrentLevel * 100) /
                            levelMap.pointsRequiredToLevelUp
                          }%`,
                        }}
                      ></div>
                      <p className="font-medium text-center text-xs text-gray-700 absolute inset-0 z-10">
                        {levelMap.pointsInCurrentLevel}/
                        {levelMap.pointsRequiredToLevelUp}
                      </p>
                    </div> */}
                  </div>
                  <button
                    onClick={() => {
                      if (location.pathname != `/profile/${username}`) {
                        handleNavigation(`/profile/${username}`);
                      }
                    }}
                    className={`ml-5 text-xs p-0 ${
                      location.pathname === `/profile/${username}`
                        ? "text-primary-color hover:cursor-default font-bold "
                        : " hover:text-primary-color hover:cursor-pointer text-gray-500 font-semibold"
                    }`}
                  >
                    View profile
                  </button>
                </>
              )}
            </div>
          </div>
          {username && (
            <button
              onClick={() => {
                // navigate("/notifications");
                handleNavigation(`/notifications`);
              }}
              className="relative w-9 h-9 p-1 rounded-full bg-accent-color flex justify-center items-center hover:bg-white border-2 border-transparent hover:border-primary-color text-black hover:text-primary-color transition-colors duration-300"
            >
              <IoNotificationsOutline size={20} />
              {unread && (
                <span className="absolute -top-1 -right-1 w-3 h-3 bg-primary-color rounded-full"></span>
              )}
            </button>
          )}
        </div>
        <NavLink
          to="/updates"
          onClick={() => {
            handleNavigation();
          }}
          className={({ isActive }) =>
            `${navlinkClasses} ${isActive ? activeLink : inactiveLink}`
          }
        >
          <FaClipboardList size={22} />
          <span className={spanClasses}>Updates</span>
        </NavLink>
        <NavLink
          onClick={() => {
            handleNavigation();
          }}
          to="/social?section=community"
          className={({ isActive }) =>
            `${navlinkClasses} ${isActive ? activeLink : inactiveLink}`
          }
        >
          <FaUserFriends size={22} />
          <span className={spanClasses}>Social</span>
        </NavLink>
        <NavLink
          to="/goals"
          onClick={() => {
            handleNavigation();
          }}
          className={({ isActive }) =>
            `${navlinkClasses} ${isActive ? activeLink : inactiveLink}`
          }
        >
          <TbTargetArrow size={22} />
          <span className={spanClasses}>Goals & Accountability</span>
        </NavLink>
        {/* <NavLink
          to="/journals"
          onClick={() => {
            handleNavigation();
          }}
          className={({ isActive }) =>
            `${navlinkClasses} ${isActive ? activeLink : inactiveLink}`
          }
        >
          <FaBook size={22} />

          <span className={spanClasses}>Journals</span>
        </NavLink> */}
        {/* <NavLink
          to="/leaderboard"
          onClick={() => {
            handleNavigation();
          }}
          className={({ isActive }) =>
            `${navlinkClasses} ${isActive ? activeLink : inactiveLink}`
          }
        >
          <HiMiniTrophy size={22} />

          <span className={spanClasses}>Leaderboard</span>
        </NavLink> */}
      </div>
      <div className="flex flex-col items-center gap-6 w-full">
        <button
          onClick={() => {
            if (loggedIn) {
              Cookies.remove("setNotifications");
              logout(dispatch, navigate);
            } else {
              navigate("/register");
            }
          }}
          className={`text-base rounded-xl text-center text-white bg-primary-color font-bold w-full py-1 gap-3 border-2 border-primary-color hover:text-primary-color hover:bg-white transition-colors duration-300`}
        >
          {loggedIn ? "Logout" : "Sign up"}
        </button>

        <SocialLinks />
      </div>
    </div>
  );
};

export default Navbar;
