import React, { useState, useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import Modal from "./Modal";
import { request } from "../util/util";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const DeleteModal = ({ type, item_id, cb, show, setShow }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const handleDelete = async () => {
    let apiPath;
    let successStr;
    switch (type) {
      case "goal":
        apiPath = `goal/${item_id}`;
        successStr = "Goal deleted successfully";
        break;
      case "post":
        apiPath = `posts/${item_id}`;
        successStr = "Post deleted successfully";
        break;
      case "category":
        apiPath = `goal/category/${item_id}`;
        successStr = "Category deleted successfully";
        break;
      default:
        apiPath = `goal/${item_id}`;
        successStr = "Goal deleted successfully";
        break;
    }
    const { res, msg, status } = await request("DELETE", apiPath);
    if (status) {
      toast.success(successStr);
      if (cb) {
        cb();
      }
    } else {
      toast.error("Error");
    }
  };

  return (
    <Modal show={show} setShow={setShow}>
      <div className="flex flex-col gap-6 items-center">
        <h1 className="font-bold text-lg text-center">
          Are you sure you want to delete this item?
        </h1>
        <button
          className="bg-red-500 text-white hover:scale-110 transition duration-300 rounded-md px-4 py-1.5 font-semibold"
          onClick={async () => {
            if (isDeleting) return;

            setIsDeleting(true);
            handleDelete()
              .then(() => {
                setShow(false);
              })
              .finally(() => setIsDeleting(false));
          }}
        >
          Delete
        </button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
