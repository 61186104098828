import React, { useEffect, useState, useRef } from "react";
import "./Profile.css";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Friends from "../../components/Friends/Friends";
import Post from "../../components/Post/Post";
import Analytics from "../../components/Analytics/Analytics";
import { useSelector, useDispatch } from "react-redux";
import {
  selectUsername,
  editProfile,
  selectProfileInfo,
} from "../../features/userSlice";
import NotFound from "../../components/NotFound/NotFound";
import ProfilePosts from "../../components/ProfilePosts/ProfilePosts";
import { IoPersonCircleSharp, IoShieldCheckmark } from "react-icons/io5";
import { Helmet } from "react-helmet-async";
import SEO from "../../components/SEO";
import { titleClasses } from "../../util/globals";
import { FaRegEdit } from "react-icons/fa";
import { request } from "../../util/util";
import { OrbitProgress } from "react-loading-indicators";
import { calculateLevel } from "../../util/level_util";
import ProfileGoals from "../../components/ProfileGoals";
import { FaUserShield } from "react-icons/fa6";
import { toast } from "react-toastify";
import { TbError404 } from "react-icons/tb";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const Profile = () => {
  const dispatch = useDispatch();
  const { username } = useParams();
  const currentUser = useSelector(selectUsername);
  const [chosenCategory, setChosenCategory] = useState("friends");
  const [userExists, setUserExists] = useState(true);
  const [loading, setLoading] = useState(true);
  const [friendshipStatus, setFriendshipStatus] = useState({});
  const [userData, setUserData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const hiddenFileInput = useRef(null);
  const [image, setImage] = useState(null);
  const profileInfo = useSelector(selectProfileInfo);
  const [bio, setBio] = useState(
    currentUser === username ? profileInfo.bio : ""
  );
  const location = useLocation();
  const [savingNewInfo, setSavingNewInfo] = useState(false);
  const handleClick = (event) => {
    // Programmatically click the hidden file input element
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage({ image: URL.createObjectURL(file), file: file });
    }
    console.log("File uploaded:", file);
  };
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // setUserData(null);
        setLoading(true);
        // if (username !== currentUser) {
        const response = await fetch(`${BASE_URL}/profile/${username}`, {
          method: "GET",
          credentials: "include",
        });

        if (response.ok) {
          const res = await response.json();
          setFriendshipStatus(res.data.friendship_status);
          setUserExists(true);
          setUserData(res.data.user);
          setBio(res.data.user.bio ?? "");
        } else {
          setUserExists(false);
        }
        // } else {
        //   setBio(profileInfo.bio);
        // }
      } catch (error) {
        console.error("Error fetching user data:", error);
        toast.error("Failed to load profile");
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    if (currentUser === username) {
      setBio(profileInfo.bio ?? "");
      // setImage(profileInfo.image && { image: profileInfo.image });
    } else {
      setBio("");
    }
  }, [currentUser, username]);

  const handleAddFriend = async () => {
    const response = await fetch(
      `${BASE_URL}/profile/${username}/friend-request`,
      {
        method: "POST",
        credentials: "include",
      }
    );

    if (response.ok) {
      setFriendshipStatus({ ...friendshipStatus, sent_req: true });
    }
  };

  const handleCancelRequest = async () => {
    const response = await fetch(
      `${BASE_URL}/profile/${username}/cancel-request`,
      {
        method: "DELETE",
        credentials: "include",
      }
    );

    if (response.ok) {
      setFriendshipStatus({ ...friendshipStatus, sent_req: false });
    }
  };

  const handleRemoveFriend = async () => {
    const response = await fetch(
      `${BASE_URL}/profile/${username}/remove-friend`,
      {
        method: "DELETE",
        credentials: "include",
      }
    );

    if (response.ok) {
      setFriendshipStatus({ ...friendshipStatus, friendship_status: false });
    }
  };
  const handleAcceptRequest = async () => {
    const response = await fetch(
      `${BASE_URL}/profile/${username}/accept-request`,
      {
        method: "POST",
        credentials: "include",
      }
    );

    if (response.ok) {
      setFriendshipStatus({
        ...friendshipStatus,
        friendship_status: true,
        received_req: false,
      });
    }
  };
  const handleDeclineRequest = async () => {
    const response = await fetch(
      `${BASE_URL}/profile/${username}/decline-request`,
      {
        method: "DELETE",
        credentials: "include",
      }
    );

    if (response.ok) {
      setFriendshipStatus({ ...friendshipStatus, received_req: false });
    }
  };

  function getButtonClasses(buttonType) {
    let baseClasses = "text-md p-2 rounded-md";

    switch (buttonType) {
      case "decline":
      case "cancel":
      case "remove":
        return `${baseClasses} bg-red-500 text-white`;
      case "accept":
      case "sendFriendRequest":
        return `${baseClasses} bg-green-500 text-white`;
      default:
        return baseClasses;
    }
  }

  const handleEditProfile = async () => {
    if (isEditing) {
      console.log(`bio ${bio}`);
      let reqObj = {
        image: image ? image?.file : null,
        bio: bio,
      };
      setSavingNewInfo(true);
      const { res, status } = await request(
        "PUT",
        "user/edit-profile",
        reqObj,
        true
      );
      if (status) {
        //dispatch change profile info
        console.log("res:");
        console.log(res);
        dispatch(
          editProfile({
            bio,
            image: res.image,
          })
        );
      } else {
        //toast error
      }
      setSavingNewInfo(false);
    }
    setIsEditing(!isEditing);
  };

  if (loading) {
    return (
      <div className="w-full h-full flex items-start justify-center pt-[10vh]">
        <OrbitProgress
          color="#32cd32"
          size="medium"
          text=""
          textColor=""
          style={{ fontSize: "10px" }}
        />
      </div>
    );
  }

  if (!userExists) {
    return <NotFound message={"User Not Found"}/>;
  }

  let displayedUserData = {
    bio: bio,
    image: currentUser === username ? profileInfo["image"] : userData["image"],
  };

  // let levelMap = calculateLevel(userData.points);
  // console.log("level map ", levelMap);
  return (
    <div className="w-full flex flex-col gap-2 pb-16">
      {/* <Helmet>
          <title>{username}'s Profile - Friengle Community Member</title>
          <meta
            name="description"
            content={`Dive into ${username}'s profile on Friengle to discover goals, achievements, and insights. Connect and collaborate for shared success in our goal-oriented community.`}
          />
        </Helmet> */}
      <SEO
        title={`${username}'s Profile`}
        description={`${username} profile on Friengle.`}
      />
      <div className="flex flex-row gap-4">
        <h1 className={titleClasses}>
          {currentUser == username ? "My " : `${username}'s `}Profile
        </h1>
        {userData.role === "admin" ? (
          <div className="ml-2 flex gap-1 items-center">
            <IoShieldCheckmark size={30} className="text-primary-color" />
            <p className="font-bold text-lg text-primary-color">Admin</p>
          </div>
        ) : (
          userData.role === "mod" && (
            <div className="ml-2 flex gap-1 items-center">
              <FaUserShield size={30} className="text-primary-color" />
              <p className="font-bold text-lg text-primary-color">Moderator</p>
            </div>
          )
        )}
        {savingNewInfo ? (
          <OrbitProgress
            color="#32cd32"
            size="small"
            text=""
            textColor=""
            style={{ fontSize: "6px" }}
          />
        ) : (
          currentUser == username && (
            <button
              className="font-semiBold underline text-primary-color"
              onClick={handleEditProfile}
            >
              {isEditing ? "Save" : "Edit"}
            </button>
          )
        )}
      </div>
      <div className="flex flex-col items-center gap-5">
        <div className="w-fit self-center relative">
          <div className="relative">
            {displayedUserData["image"] || image ? (
              <img
                src={image?.image ?? displayedUserData["image"]}
                className="size-24 rounded-full border-2 border-gray-50 shadow-md"
              />
            ) : (
              <IoPersonCircleSharp className="fill-accent-color size-28" />
            )}
            {/* {levelMap && (
                <div className="absolute z-20 bottom-0 right-0 bg-amber-200 rounded-full p-1 w-9 h-9 flex items-center justify-center shadow-lg border-[1px] border-amber-400">
                  <p className="text-xs font-bold italic text-gray-600">
                    {levelMap.level}
                  </p>
                </div>
              )} */}
          </div>

          {isEditing && (
            <>
              <input
                type="file"
                accept=".png, .jpg, .jpeg"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
              />
              <FaRegEdit
                className="absolute bottom-3 right-1 size-6 text-primary-color hover:cursor-pointer"
                onClick={handleClick}
              />
            </>
          )}
          {/* <h1 className="font-semiBold text-xl text-center">{username}</h1> */}
        </div>
        <div className="w-full md:w-5/6">
          <h2 className="text-lg font-semiBold text-center">Bio</h2>
          {currentUser === username && isEditing ? (
            <div>
              <input
                className={`font-light text-base text-secondary-color w-full border-b-2 p-2 border-gray-200 focus:outline-none`}
                value={bio}
                onChange={(e) => {
                  setBio(e.target.value);
                }}
                maxLength={300}
              />
              <p className="text-end">{bio.length}/300</p>
            </div>
          ) : (
            <p className="font-light text-base text-center text-secondary-color w-full mt-2">
              {bio && bio.trim() !== ""
                ? bio
                : "This user hasn't added a bio yet."}
            </p>
          )}
        </div>
      </div>

      <div className="flex flex-row gap-3 pb-2">
        {username !== currentUser && (
          <>
            {friendshipStatus.friendship_status ? (
              <button
                onClick={handleRemoveFriend}
                className={getButtonClasses("remove")}
              >
                Unfriend
              </button>
            ) : friendshipStatus.sent_req ? (
              <button
                onClick={handleCancelRequest}
                className={getButtonClasses("cancel")}
              >
                Cancel Request
              </button>
            ) : friendshipStatus.received_req ? (
              <>
                <button
                  onClick={handleAcceptRequest}
                  className={getButtonClasses("accept")}
                >
                  Accept Request
                </button>
                <button
                  onClick={handleDeclineRequest}
                  className={getButtonClasses("decline")}
                >
                  Decline
                </button>
              </>
            ) : (
              <button
                onClick={() => {
                  if (currentUser) {
                    handleAddFriend();
                  } else {
                    navigate("/login?redirect=/profile/" + username);
                  }
                }}
                className={getButtonClasses("sendFriendRequest")}
              >
                Add friend
              </button>
            )}
          </>
        )}
      </div>

      {/* <div className="border-b-2 border-gray-200 flex flex-row gap-2">

          {[
            "Friends",
            ...(currentUser === username
              ? // ["Analytics"]
                []
              : ["Goals"]),
          ].map((category) => {
            {
            }
            let categoryToLower = category.toLowerCase();
            return (
              <button
                className={`text-lg p-2 ${
                  chosenCategory === categoryToLower
                    ? "font-semiBold border-b-4 border-solid border-green-400 bg-gray-100"
                    : "font-light border-b-2 border-solid border-gray-300"
                }`}
                onClick={() => {
                  setChosenCategory(categoryToLower);
                }}
                disabled={chosenCategory === categoryToLower}
              >
                {category}
              </button>
            );
          })} */}
      <div className="border-b-2 border-gray-200 flex flex-row gap-2">
        {["Friends", "Goals"].map((category) => {
          let categoryToLower = category.toLowerCase();
          return (
            <button
              className={`text-lg p-2 ${
                chosenCategory === categoryToLower
                  ? "font-semiBold border-b-4 border-solid border-green-400 bg-gray-100"
                  : "font-light border-b-2 border-solid border-gray-300"
              }`}
              onClick={() => {
                setChosenCategory(categoryToLower);
              }}
              disabled={chosenCategory === categoryToLower}
            >
              {category}
            </button>
          );
        })}
      </div>

      {userData &&
        (chosenCategory === "friends" ? (
          <Friends
            isCurrentUser={currentUser === username}
            username={username}
          />
        ) : (
          <ProfileGoals username={username} />
        ))}
    </div>
  );
};

export default Profile;
