import React, { useState, useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import Modal from "./Modal";
import { request } from "../util/util";
import { FaNoteSticky } from "react-icons/fa6";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const NotesModal = ({ viewNotesModal, setViewNotesModal, goal_id }) => {
  const [dataFetched, setDataFetched] = useState(false);
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const { res, msg, status } = await request(
        "GET",
        `goal/notes/${goal_id}`
      );
      if (status) {
        setNotes(res);
        setDataFetched(true);
      }
    };

    if (goal_id) {
      fetchData();
    } else {
      setNotes([]);
    }
  }, [viewNotesModal, dataFetched, goal_id, setNotes]);

  const handleDeleteNote = async (id) => {
    const { res, msg, status } = await request(
      "DELETE",
      `goal/notes/delete/${id}`
    );
    if (status) setNotes(notes.filter((curr) => curr.id !== id));
  };

  const handleAddNote = async () => {
    const currentDate = new Date().toISOString();

    const { res, msg, status } = await request("POST", `goal/notes/new`, {
      goal_id: goal_id,
      content: note,
      submission_time: currentDate,
    });

    if (status) {
      setNotes([
        ...notes,
        { id: res, content: note, submission_time: currentDate },
      ]);
      setNote("");
    }
  };

  // Convert the date to a readable string using toLocaleString
  const formattedDate = (submission_time) => {
    const originalDate = new Date(submission_time);
    return originalDate.toLocaleString();
  };

  return (
    <Modal show={viewNotesModal} setShow={setViewNotesModal}>
      <div className="w-full flex flex-row justify-center items-center gap-2">
        <FaNoteSticky className="fill-[#b58900]" />
        <p className="font-medium text-xl text-center text-primary">Notes</p>
      </div>
      <p className="text-center text-slate-500 text-sm mb-5">
        Notes are private to each person even if the goal is shared
      </p>

      <div className="flex mb-6 items-center">
        <input
          type="text"
          className="flex-grow w-full text-lg border border-gray-400 rounded-md mr-1 text-md font-regular focus:outline-none px-2"
          value={note}
          onChange={(e) => {
            setNote(e.target.value);
          }}
        />
        <button
          className="flex-shrink-0 bg-primary-color rounded-md px-4 py-1 text-md font-regular whitespace-nowrap"
          onClick={handleAddNote}
        >
          Add
        </button>
      </div>

      {notes.length > 0 &&
        notes.map((note, index) => (
          <div
            key={index}
            className="bg-gray-50 rounded-md shadow-sm border border-gray-300 flex justify-between mb-0.5 py-2 px-4 items-center"
          >
            <div>
              <p className="ext-sm text-slate-600 font-semibold text-xs">
                {formattedDate(note.submission_time)}
              </p>
              <p className="text-base ml-2.5 font-regular text-black">
                {note.content}
              </p>
            </div>
            <button
              onClick={() => {
                handleDeleteNote(note.id);
              }}
            >
              <AiFillDelete className="fill-black hover:fill-red-500 size-5" />
            </button>
          </div>
        ))}
    </Modal>
  );
};

export default NotesModal;
