import React, { useEffect } from "react";
import { GrClose } from "react-icons/gr";

const Modal = ({
  show,
  setShow,
  children,
  fullHeight = false,
  disableClose = false,
}) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [show]);

  // Close modal if the user clicks on the backdrop
  const handleBackdropClick = () => {
    if (!disableClose) setShow(false);
  };

  // Stop the click event from propagating to the backdrop
  const handleModalContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    show && (
      <div
        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[999]"
        onClick={handleBackdropClick} // click on the backdrop closes the modal
      >
        <div
          className={`bg-white p-5 rounded-lg shadow-lg w-[95vw] max-w-4xl ${
            fullHeight ? "h-full" : ""
          } max-h-[90vh] flex flex-col`}
          onClick={handleModalContentClick} // click on the backdrop closes the modal
        >
          {/* Close button row */}
          <div className="flex w-full justify-end">
            <button
              className={`text-2xl bg-transparent border-none ${
                disableClose
                  ? "text-gray-300 !cursor-not-allowed"
                  : "text-red-500 cursor-pointer"
              }`}
              onClick={disableClose ? null : () => setShow(false)}
            >
              <GrClose size={25} />
            </button>
          </div>

          {/* Scrollable content area */}
          <div className="h-full overflow-y-auto flex-1 mt-2 min-h-0">
            {children}
          </div>
        </div>
      </div>
    )
  );
};

export default Modal;
