import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { request } from "../util/util";

const initialState = {
  notifications: [],
  unreadNotifications: false,
};

const createNotificationMessage = (notification) => {
  let message = notification.template;
  let data = notification.data;

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const placeholder = `{${key}}`;
      message = message.replace(placeholder, data[key]);
    }
  }
  return { ...notification, message };
};

export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async (_, { rejectWithValue }) => {
    const { res, status } = await request("GET", "user/notifications");
    if (status) {
      return { notifications: res.notifications };
    } else {
      return rejectWithValue("!response.ok");
    }
  }
);
export const setReadNotifications = createAsyncThunk(
  "notifications/setReadNotifications",
  async (_, { rejectWithValue }) => {
    const { res, status } = await request(
      "POST",
      `user/notifications/mark-read`
    );
    if (status) {
      return true;
    } else {
      return rejectWithValue("!response.ok");
    }
  }
);

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    changeReadStatus: (state, action) => {
      state.notifications = state.notifications.map((notification) => {
        return { ...notification, read: true };
      });
      state.unreadNotifications = false;
    },
    addNotification: (state, action) => {
      console.log("addnotif payload: ", action.payload);
      if (window.location.pathname !== "/notifications") {
        state.unreadNotifications = true;
      }
      state.notifications = [
        createNotificationMessage(action.payload),
        ...state.notifications,
      ];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      const { notifications } = action.payload;
      state.notifications = notifications.map((notification) => {
        return createNotificationMessage(notification);
      });
      if (notifications.length > 0 && !notifications[0].read) {
        state.unreadNotifications = true;
      }
      return;
    });
    // .addCase(setReadNotifications.fulfilled, (state, action) => {
    //   state.notifications = state.notifications.map((notification) => {
    //     return { ...notification, read: true };
    //   });
    //   state.unreadNotifications = false;
    // });
  },
});

export const selectNotifications = (state) => state.notifications.notifications;
export const selectUnreadNotifications = (state) =>
  state.notifications.unreadNotifications;

export const { changeReadStatus, addNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;
