import { router } from "../App";
import { store } from "../app/store";
import { toast } from "react-toastify";
const genURI = (URN) => `https://api.friengle.com/${URN}`;
// const genURI = (URN) => `http://localhost:4000/${URN}`;

export const includeParams = (URN, params = {}) => {
  // Filter out keys with null or undefined values
  const filteredParams = Object.keys(params)
    .filter((key) => params[key] != null)
    .reduce((acc, key) => {
      acc[key] = params[key];
      return acc;
    }, {});

  const queryString = new URLSearchParams(filteredParams).toString();
  return `${URN}${queryString ? `?${queryString}` : ""}`;
};

export function genTitle(title) {
  title = title.toLowerCase().replace(/[^a-z0-9\s]/g, "");
  return title
    .split(" ")
    .filter((word) => word.trim())
    .slice(0, 10)
    .join("-");
}
export const logout = async () => {
  fetch(genURI("logout"), {
    method: "POST",
    credentials: "include",
  }).then(() => {
    router.navigate("/login");
    store.dispatch({ type: "USER_LOGOUT" });
  });
};

export const formatDate = (dateString, includeTime = false) => {
  const dateObject = new Date(dateString);
  let options = { year: "numeric", month: "2-digit", day: "2-digit" };
  if (includeTime) {
    options = {
      ...options,
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
  }
  return dateObject.toLocaleDateString(undefined, options);
};

export function formatDateRelative(dateString, includeTime = false) {
  const postDate = new Date(dateString);
  const now = new Date();
  const oneDay = 24 * 60 * 60 * 1000;
  const diffTime = Math.abs(now - postDate);

  if (postDate.toDateString() === now.toDateString()) {
    const diffSeconds = Math.round(diffTime / 1000);
    const diffMinutes = Math.round(diffTime / (1000 * 60));
    const diffHours = Math.round(diffTime / (1000 * 60 * 60));

    if (diffSeconds < 60) return `${diffSeconds} seconds ago`;
    if (diffMinutes < 60) return `${diffMinutes} minutes ago`;
    return `${diffHours} hours ago`;
  }

  let options = {
    month: "long",
    day: "numeric",
  };

  if (includeTime) {
    options = {
      ...options,
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
  }

  if (postDate.getFullYear() === now.getFullYear()) {
    return postDate.toLocaleDateString(undefined, options);
  }

  options["year"] = "numeric";
  return postDate.toLocaleDateString(undefined, options);
}

export function formatDateTimeLocal(dateInput) {
  const d = new Date(dateInput);
  const year = d.getFullYear();
  // Months are zero-indexed so we add 1 and pad with a leading zero if needed
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  const hours = String(d.getHours()).padStart(2, "0");
  const minutes = String(d.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

export const request = async (
  method,
  URN,
  data = null,
  multiformData = false
) => {
  try {
    // console.log(`URN: ${URN}`);
    // console.log(`data: ${data}`);
    let requestArgument;
    switch (method) {
      case "GET": {
        requestArgument = {
          method: "GET",
          credentials: "include",
        };
        break;
      }
      case "PUT":
      case "POST":
      case "DELETE": {
        {
          if (data) {
            if (multiformData) {
              const formData = new FormData();
              for (const key in data) {
                let value = data[key];

                if (value instanceof File) {
                  // Append File objects (like images) directly
                  formData.append(key, value);
                } else if (Array.isArray(value)) {
                  // Loop over arrays and append each item
                  value.forEach((item) => {
                    if (typeof item === "object") {
                      // For arrays of objects, you may need to stringify each item
                      formData.append(`${key}[]`, JSON.stringify(item));
                    } else {
                      // For arrays of primitives (e.g., strings, numbers)
                      formData.append(`${key}[]`, item);
                    }
                  });
                } else if (value !== null && typeof value === "object") {
                  // Stringify plain objects
                  formData.append(key, JSON.stringify(value));
                } else if (value !== undefined && value !== null) {
                  // Append primitive values directly
                  formData.append(key, value);
                }
                // Skip appending if value is undefined or null
              }
              requestArgument = {
                method: method,
                credentials: "include",
                body: formData,
              };
            } else {
              requestArgument = {
                method,
                credentials: "include",
                headers: {
                  "Content-type": "application/json",
                },
                body: JSON.stringify(data),
              };
            }
          } else {
            requestArgument = {
              method,
              credentials: "include",
            };
          }
          break;
        }
      }
      default: {
        requestArgument = {};
        break;
      }
    }
    // console.log("req");
    const req = await fetch(genURI(URN), requestArgument);
    // console.log(req);
    if (req.status === 401 || req.status === 403) {
      logout();
    } else if (req.status === 500 || req.status === 400) {
      // console.log("status 400 500,status false");
      return { status: false, msg: "Error" };
    } else if (req.status === 204) {
      // console.log("204, status true");
      return { status: true };
    } else {
      // console.log("status true");
      const res = await req.json();
      // console.log("res");
      // console.log(res);
      return { res, status: true };
    }
  } catch (err) {
    // console.log("err");
    return { status: false, msg: "Error" };
  }
};
