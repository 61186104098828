import React, { useState, useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import Modal from "./Modal";
import { request } from "../util/util";
import { IoPersonCircleSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const SharedGoalParticipantsModal = ({
  viewParticipantsModal,
  setViewParticipantsModal,
  participants,
}) => {
  return (
    <Modal show={viewParticipantsModal} setShow={setViewParticipantsModal}>
      {participants && participants.length > 0 ? (
        <div className="w-full">
          <div className="text-center mb-6">
            <p className="text-2xl font-semibold">Goal Participants</p>
            <p className="text-gray-500 text-sm font-medium">
              Participants and their roles, including editing permissions, can
              be modified by editing the goal
            </p>
          </div>

          <table className="w-full table-auto">
            <thead>
              <tr>
                <th className="px-4 py-2">Username</th>
                <th className="px-4 py-2">Can Edit</th>
                <th className="px-4 py-2">Role</th>
              </tr>
            </thead>
            <tbody>
              {participants.map((participant, index) => (
                <tr key={index} className="text-center">
                  <td className="border px-4 py-2">
                    <Link
                      to={`/profile/${participant.username}`}
                      className="flex items-center justify-start hover:text-primary-color"
                    >
                      {participant.image ? (
                        <img
                          src={participant.image}
                          className="rounded-full w-8 h-8 object-cover mr-2"
                          alt="Participant"
                        />
                      ) : (
                        <IoPersonCircleSharp
                          className="fill-gray-300 mr-2"
                          size={35}
                        />
                      )}
                      <span className="text-base font-medium">
                        {participant.username}
                      </span>
                    </Link>
                  </td>
                  <td className="border px-4 py-2">
                    {participant.can_edit ? "Yes" : "No"}
                  </td>
                  <td className="border px-4 py-2">
                    {participant.is_accountability_partner
                      ? "Accountability Partner"
                      : "Goal Participant"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-center">
          No Goal Participants
          <br />
          You can add participants and accountability partners by editing the
          goal
        </p>
      )}
    </Modal>
  );
};

export default SharedGoalParticipantsModal;
