import React, { useState, useEffect } from "react";
import Modal from "../Modal";
import "./AddGoalModal.css";
import { formatDateTimeLocal, request } from "../../util/util";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAllFriends } from "../../features/friendsSlice";
import {
  addGoal,
  editGoalAction,
  selectCategories,
} from "../../features/goalSlice";
import { FaUpload } from "react-icons/fa";
import { OrbitProgress } from "react-loading-indicators";
import AsyncSelect from "react-select/async";
import { selectUsername } from "../../features/userSlice";
import { toast } from "react-toastify";

const AddGoalModal = ({ view, setView, editGoal, setEditGoal, category }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const username = useSelector(selectUsername);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [targetValue, setTargetValue] = useState(0);
  const [inputValues, setInputValues] = useState([""]);
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [selectedDeadline, setSelectedDeadline] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [visibility, setVisibility] = useState("friends_only");
  const [status, setStatus] = useState("in progress");
  const [goalImage, setGoalImage] = useState(null);

  const types = ["basic", "milestone", "counting"];
  const categories = useSelector(selectCategories).filter(
    (category) =>
      category.category !== "Shared" && category.category !== "Accountability"
  );

  // Update state when editGoal or category changes
  useEffect(() => {
    if (editGoal) {
      console.log("edit goal ", editGoal);
      setTitle(editGoal.title ?? "");
      setDescription(editGoal.description ?? "");
      setTargetValue(editGoal.total ?? 0);
      setSelectedFriends(
        editGoal.goal_participants
          ? editGoal.goal_participants.map((participant) => ({
              id: participant.id,
              name: participant.username,
              role: participant.is_accountability_partner
                ? "accountability partner"
                : "goal participant",
              can_edit: participant.can_edit,
            }))
          : []
      );
      setSelectedType(editGoal.type ?? "");
      setVisibility(editGoal.visibility ?? "friends_only");
      setStatus(editGoal.status ?? "in progress");
      setGoalImage(editGoal.image ?? null);
      if (editGoal.deadline) {
        // const formattedDate = new Date(editGoal.deadline)
        //   .toISOString()
        //   .split("T")[0];

        // setSelectedDeadline(formattedDate);

        const formattedDate = formatDateTimeLocal(editGoal.deadline);
        setSelectedDeadline(formattedDate);
      }
      if (editGoal.category_id) {
        console.log("edit goal category id", editGoal.category_id);
        let cat = categories.find((cat) => cat.id === editGoal.category_id);
        setSelectedCategory(editGoal.category_id);
        console.log("set selected cate:", cat);
      }
    } else {
      // Reset to defaults if editGoal is cleared
      setTitle("");
      setDescription("");
      setTargetValue(0);
      setSelectedFriends([]);
      setSelectedDeadline("");
      setSelectedType("");
      setVisibility("friends_only");
      setStatus("in progress");
      setGoalImage(null);
    }

    // Default category handling
    // setSelectedCategory(category ?? "");
  }, [editGoal, category]);

  const resetForm = () => {
    setTitle("");
    setTargetValue(0);
    setInputValues([""]);
    setSelectedFriends([]);
    setSelectedDeadline("");
    setSelectedType("");
    setSelectedCategory("");
    setGoalImage(null);
    setDescription("");
  };

  const loadOptions = async (inputValue) => {
    if (!inputValue) return [];
    try {
      const response = await request(
        "GET",
        `profile/search-friends?q=${inputValue}`
      );
      if (response["status"] === true) {
        return response["res"].map((friend) => ({
          value: friend,
          label: friend.username,
        }));
      }
    } catch (error) {
      console.error("Error fetching friends:", error);
      return [];
    }
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (editGoal === null) {
      let goal = {
        title,
        deadline: selectedDeadline,
        status,
        visibility,
        description,
        goal_category_id: selectedCategory,
        selectedFriends,
        image: goalImage,
        type: selectedType,
      };

      if (selectedType === "milestone" && editGoal === null) {
        if (inputValues[inputValues.length - 1].trim() === "") {
          goal.tasksArr = inputValues.slice(0, -1).map((value) => value);
        } else {
          goal.tasksArr = inputValues;
        }
      } else if (selectedType === "counting") {
        goal.total = targetValue;
      }

      dispatch(addGoal({ goal }))
        .unwrap()
        .then((result) => {
          closeModal();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      let updatedGoal = {};

      if (title !== editGoal.title) updatedGoal.title = title;
      if (description !== editGoal.description)
        updatedGoal.description = description;
      if (targetValue !== editGoal.total) updatedGoal.total = targetValue;
      if (selectedDeadline !== editGoal.deadline && selectedDeadline)
        updatedGoal.deadline = selectedDeadline;
      if (selectedType !== editGoal.type) {
        updatedGoal.type = selectedType;
        updatedGoal.previousType = editGoal.type;
      }
      if (
        editGoal.category_id !== selectedCategory &&
        !(Number(selectedCategory) < 0 && editGoal.category_id === "")
      ) {
        updatedGoal.goal_category_id = selectedCategory;
      }
      if (visibility !== editGoal.visibility)
        updatedGoal.visibility = visibility;
      if (status !== editGoal.status) updatedGoal.status = status;
      if (goalImage !== editGoal.image) {
        updatedGoal.image = goalImage;
        if (editGoal?.image) {
          updatedGoal.previousImageUrl = editGoal.image;
        }
      }

      const currentParticipants = selectedFriends.map((friend) => ({
        id: friend.id,
        name: friend.name,
        role: friend.role,
        can_edit: friend.can_edit,
      }));

      const initialParticipants = editGoal.goal_participants.map(
        (participant) => ({
          id: participant.id,
          name: participant.username,
          role: participant.is_accountability_partner
            ? "accountability partner"
            : "goal participant",
          can_edit: participant.can_edit,
        })
      );

      const initialMap = new Map(initialParticipants.map((p) => [p.id, p]));
      const currentMap = new Map(currentParticipants.map((p) => [p.id, p]));

      let addedParticipants = [];
      let removedParticipants = [];
      let updatedParticipants = [];

      currentParticipants.forEach((current) => {
        const initial = initialMap.get(current.id);
        if (!initial) {
          addedParticipants.push(current);
        } else if (
          current.role !== initial.role ||
          current.can_edit !== initial.can_edit
        ) {
          updatedParticipants.push(current);
        }
      });

      initialParticipants.forEach((initial) => {
        if (!currentMap.has(initial.id)) {
          removedParticipants.push(initial);
        }
      });

      if (
        addedParticipants.length ||
        removedParticipants.length ||
        updatedParticipants.length
      ) {
        updatedGoal.goal_participants = {
          added: addedParticipants,
          removed: removedParticipants,
          updated: updatedParticipants,
        };
      }

      if (Object.keys(updatedGoal).length > 0) {
        updatedGoal.id = editGoal.id;
        let editStateData = {};
        if (updatedGoal.goal_category_id) {
          editStateData["previousCategoryId"] = editGoal.category_id;
        }
        if (updatedGoal.status || updatedGoal.goal_category_id) {
          editStateData["previousStatus"] = editGoal.status;
        }
        if (updatedGoal.goal_participants?.updated?.length > 0) {
          let updatedUser = null;
          updatedGoal.goal_participants.updated.forEach((participant) => {
            if (participant.username === username) {
              updatedUser = participant;
            }
          });
          if (updatedUser) {
            const previousUserData = editGoal.goal_participants.find(
              (participant) => participant.username === username
            );
            if (
              !previousUserData.is_accountability_partner &&
              updatedUser.role === "accountability partner"
            ) {
              editStateData["incrementAccountabilityCategory"] = true;
            } else if (
              previousUserData.is_accountability_partner &&
              updatedUser.role !== "accountability partner"
            ) {
              editStateData["decrementAccountabilityCategory"] = true;
            }
          }
        }
        dispatch(
          editGoalAction({
            updatedValues: updatedGoal,
            editStateData: editStateData,
          })
        )
          .unwrap()
          .then((result) => {
            closeModal();
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        toast.info("No Changes Detected");
      }
    }
  };

  const handleInputChange = (index, value) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;

    if (index === inputValues.length - 1 && value !== "" && index < 4) {
      newInputValues.push("");
    }

    setInputValues(newInputValues);
  };

  const handleDateChange = (e) => {
    const localDateString = e.target.value; // e.g. "2024-03-15T14:30"
    const utcISOString = new Date(localDateString).toISOString();
    setSelectedDeadline(localDateString);
    // Use utcISOString as needed (e.g., send to your server)
  };

  const handleFriendsChange = (selectedOption) => {
    const friendFound = selectedFriends.find(
      (friend) => friend.id === selectedOption.value
    );
    if (!friendFound) {
      setSelectedFriends((prev) => [
        ...prev,
        {
          id: selectedOption.value.id,
          name: selectedOption.label,
          image: selectedOption.value.name,
          role: "accountability partner",
          can_edit: false,
        },
      ]);
    }
  };

  const changeFriendPermissions = (type, newValue, friendId) => {
    setSelectedFriends((prev) =>
      prev.map((friend) =>
        friend.id === friendId
          ? {
              ...friend,
              [type]: newValue,
            }
          : friend
      )
    );
  };

  const handleRemoveFriend = (id) => {
    setSelectedFriends((prev) => prev.filter((friend) => friend.id !== id));
  };

  const isButtonDisabled =
    !title ||
    !selectedType ||
    (selectedType === "counting" && !targetValue) ||
    (editGoal !== null &&
      selectedType === "counting" &&
      targetValue < editGoal.total);

  const labelClasses = "font-semibold text-base text-slate-600";
  const selectClasses =
    "border border-gray-300 p-2 rounded-md font-regular text-base";
  const explanationClasses = "text-gray-500 font-medium text-sm mb-2";

  const closeModal = (_) => {
    setEditGoal(null);
    setView(false);
  };

  return (
    <Modal show={view} setShow={closeModal} disableClose={loading}>
      <form
        onSubmit={handleSubmit}
        className=" flex flex-col gap-3 p-1 md:p-3 h-max"
      >
        <h1 className="text-2xl font-semibold mb-1 text-center">
          {editGoal ? "Edit Goal" : "Add a New Goal"}
        </h1>
        <div className="w-full flex flex-col items-stretch gap-8">
          <div className="w-full flex flex-col items-stretch">
            <p className={labelClasses}>Select Visibility:</p>
            <div className={explanationClasses}>
              <p>
                This setting decides who can see your goal and how visible it
                will be.
              </p>
              <p className="font-bold underline">
                Changing it won't automatically delete any previous posts.
              </p>
              <ul className="list-disc pl-4">
                <li>
                  <span className="font-bold underline">Friends</span>: The goal
                  and its updates are automatically shared on your friends' feed
                  and visible to your friends in your profile.
                </li>
                <li>
                  <span className="font-bold underline">Everyone</span>: The
                  goal is visible to anyone visiting your profile, in addition
                  to being shared with friends and appearing in their feed.
                </li>
                <li>
                  <span className="font-bold underline">Only Me</span>: The goal
                  is visible only to you and won’t appear on the feed or your
                  profile.
                </li>
              </ul>
              <p className="font-bold mt-1">
                Reminder: You can share any goal as a post attachment at any
                time using the share button on the goal.
              </p>
            </div>
            <select
              value={visibility}
              onChange={(e) => {
                setVisibility(e.target.value);
              }}
              className={selectClasses}
              required
            >
              <option value="" disabled>
                Select Visibility
              </option>
              {[
                { value: "friends_only", label: "Friends" },
                { value: "only_me", label: "Only Me" },
                { value: "everyone", label: "Everyone" },
              ].map((type, index) => (
                <option key={index} value={type.value}>
                  {type.label}
                </option>
              ))}
            </select>
          </div>
          <div className="w-full flex flex-col items-stretch">
            <p className={labelClasses}>Select Status:</p>
            <div className={explanationClasses}>
              <p>
                This setting tracks the current state of your goal, helps with
                filtering, and can be changed later as needed :
              </p>
              <ul className="list-disc pl-4">
                <li>
                  <span className="font-bold underline">Pending</span>: The goal
                  has been created but hasn’t been started yet.
                </li>
                <li>
                  <span className="font-bold underline">In Progress</span>: Work
                  on the goal is actively ongoing.
                </li>
                <li>
                  <span className="font-bold underline">Completed</span>: The
                  goal has been fully achieved.
                </li>
              </ul>
            </div>
            <select
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
              className={selectClasses}
              required
            >
              <option value="" disabled>
                Select Status
              </option>
              {["in progress", "pending", "completed"].map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <div className="w-full">
            <p className={labelClasses}>Goal Title:</p>
            {/* <p className={explanationClasses}>
              Give your goal a clear and concise title to make it easy to
              identify.<br></br> Tip: Use action words like "Complete,"
              "Achieve," or "Finish" to stay motivated!
            </p> */}
            <input
              type="text"
              value={title}
              onChange={handleTitleChange}
              className="border border-gray-300 p-2 rounded-md w-full font-regular"
              required
            />
          </div>

          <div className="w-full">
            <p className={labelClasses}>Description:</p>
            <p className={explanationClasses}>
              Briefly describe your goal to help you remember why you started
              and stay motivated.
            </p>
            <textarea
              type="text"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              rows={4}
              className="border border-gray-300 p-2 rounded-md w-full font-regular"
              required
            />
          </div>
          <div className="w-full flex flex-col items-stretch">
            <p className={labelClasses}>Deadline( optional ):</p>
            <p className={explanationClasses}>
              If your deadline is approaching, you'll see it on the updates page
              as a reminder to stay on track.
            </p>
            <input
              type="datetime-local"
              value={selectedDeadline}
              min={
                !selectedDeadline ? formatDateTimeLocal(new Date()) : undefined
              }
              onChange={handleDateChange}
              className={selectClasses}
            />
          </div>
          <div className="w-full flex flex-col items-stretch">
            <p className={labelClasses}>Select Goal Category:</p>
            <p className={explanationClasses}>
              Choose All to keep it uncategorized or pick from your previously
              created categories.
              <br></br> You can always change this later by editing the goal.
            </p>
            <select
              value={selectedCategory}
              onChange={(e) => {
                setSelectedCategory(e.target.value);
              }}
              className={selectClasses}
            >
              {categories.map((category, index) => (
                <option key={index} value={category.id}>
                  {category.category}
                </option>
              ))}
            </select>
          </div>

          <div className="w-full flex flex-col items-stretch">
            <p className={labelClasses}>Select Type:</p>
            <div className={explanationClasses}>
              <p>Choose a goal type that best suits your needs:</p>
              <ul className="list-disc pl-4">
                <li>
                  Basic: A straightforward goal with a deadline to keep you
                  focused.
                </li>
                <li>
                  Counting: For tracking progress towards a total, like reading
                  a 500-page book. Log each session to see your overall
                  progress.
                </li>
                <li>
                  Milestone: Break your goal into smaller subtasks to achieve it
                  step by step. Perfect for complex or long-term goals.
                </li>
              </ul>
            </div>
            <select
              value={selectedType}
              onChange={handleTypeChange}
              className={selectClasses}
              required
            >
              <option value="" disabled>
                Select Type
              </option>
              {types.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          {selectedType === "milestone" && !editGoal ? (
            <div>
              <p className={labelClasses}>Add tasks:</p>
              <p className={explanationClasses}>
                Add up to 5 tasks now to get started.<br></br> Don’t worry—you
                can always add more or delete tasks later from the goal listing!
              </p>
              <div className="flex flex-row flex-wrap gap-2">
                {inputValues.map((inputValue, index) => (
                  <input
                    key={index}
                    type="text"
                    value={inputValue}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    className="border border-gray-300 p-2 rounded-md my-1 font-regular"
                  />
                ))}
              </div>
            </div>
          ) : (
            selectedType === "counting" && (
              <div className="self-center md:self-start">
                <p className={labelClasses}>Target Value:</p>
                <p className={explanationClasses}>
                  Set the total amount you want to achieve for this goal, like
                  500 pages for a reading goal.
                </p>
                <input
                  type="number"
                  min={0}
                  value={targetValue}
                  onChange={(e) => setTargetValue(e.target.value)}
                  className="border border-gray-300 p-2 rounded-md w-full md:max-w-44 font-regular"
                  required
                />
                {targetValue < 0 ? (
                  <p className="text-red-500 text-sm font-bold mt-2">
                    Target value must be greater than 0.
                  </p>
                ) : (
                  editGoal &&
                  editGoal.count > targetValue && (
                    <p className="text-red-500 text-sm font-bold mt-2">
                      Target value can't be less than the current total (
                      {editGoal.count}).
                    </p>
                  )
                )}
              </div>
            )
          )}

          <div className="w-full">
            <label htmlFor="goal-image" className={labelClasses}>
              Choose an image( optional ):
            </label>
            <p className={explanationClasses}>
              Add an image to represent your goal and make it more visually
              inspiring.<br></br> You can always change it later if you want a
              new look!
            </p>

            <div className="relative">
              <label
                htmlFor="goal-image"
                className="flex justify-start gap-4 items-center px-4 py-2 border border-gray-300 rounded-md cursor-pointer"
              >
                <div className="flex flex-row items-center gap-1 text-gray-600 border-r-2 border-gray-700 pr-2">
                  <FaUpload className="" size={15} />
                </div>
                <div className="w-full flex justify-between items-center">
                  <p className="text-sm font-medium text-black">
                    {editGoal?.image
                      ? "Click to choose a new image"
                      : goalImage?.name ?? "No File Chosen"}
                  </p>
                  {goalImage && (
                    <button
                      type="button"
                      className="text-red-500 font-semibold text-sm"
                      onClick={() => {
                        setGoalImage(null);
                      }}
                    >
                      Clear Image
                    </button>
                  )}
                </div>
              </label>
              <input
                id="goal-image"
                type="file"
                accept="image/jpeg, image/jpg, image/png, image/webp"
                className="hidden"
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    setGoalImage(e.target.files[0]);
                  } else {
                    setGoalImage(null);
                  }
                }}
              />
            </div>
          </div>

          <div className=" flex flex-col items-stretch ">
            <p className={labelClasses}>Select Friends( optional ):</p>
            <p className={explanationClasses}>
              Add goal participants or accountability partners by searching for
              your friends' usernames.
              <br></br>
              To connect with new users, close this modal and use the search bar
              at the top. Simply type @ followed by their username (e.g.,
              @friengle).
            </p>
            <AsyncSelect
              cacheOptions
              loadOptions={loadOptions}
              placeholder="Type to search friends..."
              onChange={handleFriendsChange}
            />
            {selectedFriends && selectedFriends.length > 0 && (
              <div className="mt-1.5 flex flex-col">
                <p className="font-semibold">Participants:</p>
                {selectedFriends.map((friend) => (
                  <div
                    key={friend.id}
                    className="w-full grid grid-cols-7 gap-7 border-b-2 border-gray-300 p-3"
                  >
                    <p className="col-span-2 font-medium text-lg">
                      {friend.name}
                    </p>
                    <div className="col-span-2 flex flex-col items-center">
                      <p className="font-semibold">Role:</p>
                      <select
                        className="text-sm text-center outline-none border-none"
                        value={
                          friend.role ??
                          (friend.is_accountability_partner
                            ? "accountability partner"
                            : "goal participant")
                        }
                        onChange={(e) =>
                          changeFriendPermissions(
                            "role",
                            e.target.value,
                            friend.id
                          )
                        }
                      >
                        <option value={"accountability partner"}>
                          Accountability Partner
                        </option>
                        <option value={"goal participant"}>
                          Goal Participant
                        </option>
                      </select>
                    </div>
                    <div className="col-span-2 flex flex-col items-center">
                      <p className="font-semibold">Can Edit Goal:</p>
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          changeFriendPermissions(
                            "can_edit",
                            e.target.checked,
                            friend.id
                          )
                        }
                        checked={friend.can_edit}
                      />
                    </div>
                    {friend.name !== username && (
                      <button
                        onClick={() => handleRemoveFriend(friend.id)}
                        className="hover:scale-105 py-2 text-white bg-red-500 rounded-lg text-sm"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <button
          type="submit"
          className={`w-full mt-5 md:w-96 self-center flex justify-center items-center ${
            isButtonDisabled
              ? "bg-gray-200 cursor-wait"
              : "cursor-pointer bg-primary-color"
          } px-4 py-1 h-fit font-regular rounded-md`}
          disabled={isButtonDisabled}
        >
          {loading ? (
            <OrbitProgress
              color="#000000"
              size="small"
              text=""
              textColor=""
              style={{ fontSize: "6px" }}
            />
          ) : (
            <p>Save</p>
          )}
        </button>
      </form>
    </Modal>
  );
};

export default AddGoalModal;
