import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom"; // Import Link from react-router-dom if you're using it
import "./Register.css";
import { useDispatch, useSelector } from "react-redux";
import { logIn, selectLoggedIn } from "../../features/userSlice";
import registrationImg from "../../assets/images/register-img.jpg";
import AuthHeader from "../../components/AuthHeader/AuthHeader";
import { Helmet } from "react-helmet-async";
import SEO from "../../components/SEO";
import SocialLinks from "../../components/SocialLinks";
import { FaArrowRight } from "react-icons/fa";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Register = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(selectLoggedIn);
  // const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();
  // useEffect(() => {
  //   fetch("http://localhost:4000/api/auth/check", {
  //     method: "post",
  //     withCredentials: true,
  //     credentials: "include",
  //   })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setUserLoggedIn(true);
  //       } else {
  //         setUserLoggedIn(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setUserLoggedIn(false);
  //     });
  // }, []);

  if (loggedIn) return <Navigate to="/social" />;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
      const res = await fetch(`${BASE_URL}/register`, {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          username: username,
          password: password,
        }),
      });
      if (res.ok) {
        const user = await res.json();
        // setIsSubmitted(true);
        dispatch(logIn(user));
      } else {
        const respObject = await res.json();
        alert(respObject.error);
      }
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleUserNameInput = (e) => {
    const MAX_LENGTH = 15;
    const regex = /^[a-zA-Z0-9_]*$/; // Only letters, numbers, and underscores

    let input = e.target.value.trim().replace(/\s+/g, "_"); // Replace spaces with underscores
    input = input.replace(/[^a-zA-Z0-9_]/g, ""); // Remove special characters

    if (input.length > MAX_LENGTH) {
      input = input.substring(0, MAX_LENGTH); // Enforce max length
    }

    if (regex.test(input) || input === "") {
      setUsername(input.toLowerCase()); // Convert to lowercase (like your SQL logic)
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const inputClasses =
    "border-[1px] p-2 border-gray-200 rounded-md w-full w-full focus:outline-none focus:border-primary-color font-regular text-sm";
  return (
    <div className="min-h-screen w-full  bg-gradient-to-b from-white via-primary-color/5 to-primary-color/10">
      {/* <Helmet>
        <title>Join Friengle: Social Goal Tracking & Community Support</title>
        <meta
          name="description"
          content="Friengle is the ultimate social platform for setting, tracking, and achieving your goals. Join a community of motivated individuals ready to support your personal growth journey. Start achieving more today."
        />
      </Helmet> */}
      <SEO
        title="Register - Friengle"
        description="Join Friengle to set, track, and achieve your goals."
      />

      <AuthHeader page={"register"} />
      <div className="pt-[5vh] pb-4 w-5/6  m-auto flex flex-col items-center gap-8">
        <div className="flex flex-col md:flex-row gap-4 items-center justify-center">
          {/* <img src={registrationImg} className="max-w-full h-[30vh]" /> */}
          <div className="flex flex-col gap-6 w-full max-w-[400px] mb-5">
            <h1 className="text-2xl  xl:text-3xl font-medium text-center">
              {/* Every step you take today brings you closer to the person you are
              meant to be */}
              Sign Up for Free
              <br /> and <br /> Start using Friengle Now!
            </h1>
            {/* <p className="text-md font-semiBold text-center text-secondary-color underline"></p> */}
            {/* <Link
              to={"/about"}
              className="mt-4 flex flex-row gap-1 md:gap-2 justify-center items-center text-primary-color text-md font-semiBold"
            >
              Find out More
              <FaArrowRight className="fill-primary-color" size={20} />
            </Link> */}
          </div>
        </div>
        <form
          className="flex flex-col justify-center w-full gap-6 items-center"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col gap-4 items-center w-full max-w-[450px] items-start">
            <div className="w-full">
              <label
                for="email"
                className="font-semibold text-gray-700 text-lg mb-3"
              >
                Email
              </label>
              <input
                placeholder="Email address"
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                required
                className={`${inputClasses}`}
              />
              <p className="font-semibold text-gray-500 text-sm">
                This email will be used to log in.
              </p>
            </div>
            <div className="w-full">
              <label
                for="username"
                className="font-semibold text-gray-700 text-lg mb-3"
              >
                Username
              </label>
              <input
                placeholder="Choose a username"
                type="text"
                id="username"
                value={username}
                onChange={handleUserNameInput}
                required
                className={`${inputClasses}`}
              />

              <p className="font-semibold text-gray-500 text-sm">
                Usernames can only include letters, numbers, and underscores.
                Maximum length: 15 characters.
              </p>
            </div>
            <div className="w-full">
              <label
                for="password"
                className="font-semibold text-gray-700 text-lg mb-3"
              >
                Password
              </label>
              <input
                placeholder="Create a password"
                type="password"
                id="password"
                value={password}
                onChange={handlePasswordChange}
                required
                className={inputClasses}
              />
              {/* <p className="font-semibold text-gray-500 text-sm">
                Usernames can only include letters, numbers, and underscores.
                Maximum length: 15 characters.
              </p> */}
            </div>

            <div className="w-full">
              <label
                for="confirmPassword"
                className="font-semibold text-gray-700 text-lg mb-3"
              >
                Confirm Password
              </label>
              <input
                placeholder="Confirm password"
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                required
                className={inputClasses}
              />
              {/* <p className="font-semibold text-gray-500 text-sm">
                Usernames can only include letters, numbers, and underscores.
                Maximum length: 15 characters.
              </p> */}
            </div>

            {!passwordMatch && (
              <p className="font-semibold text-red-500">
                Passwords do not match
              </p>
            )}
          </div>

          <div className="flex flex-col gap-2 items-center">
            <button
              type="submit"
              className="bg-primary-color rounded-md px-5 py-2 font-semibold text-white text-md w-48 border-2 border-primary-color hover:bg-white hover:text-primary-color transition-colors duration-300"
            >
              Sign Up
            </button>
            <p className="font-medium text-lg">Or</p>
            <button
              style={{
                color: "--text-color",
                backgroundColor: "--accent-color",
              }}
              type="button"
              className="bg-gray-200 rounded-md px-5 py-2 font-semibold w-48 border-2 border-gray-200 hover:bg-white hover transition-colors duration-300"
              onClick={() => {
                navigate("/social?section=community");
              }}
            >
              Continue as guest
            </button>

            <p className="text-start w-full text-sm mt-5">
              <span className="font-regular">Already have an account? </span>
              <button
                className="text-primary-color font-semibold"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Log in
              </button>
            </p>
          </div>
        </form>
        <SocialLinks />
      </div>
    </div>
  );
};

export default Register;
