import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { request } from "../util/util";

const initialState = {
  friends: [],
  received: [],
  pending: [],
  friendCount: null,
};

export const getFriendsData = createAsyncThunk(
  "friends/getFriendsData",
  async (_, { rejectWithValue, dispatch }) => {
    console.log("get friends dat");
    const { res, status } = await request("GET", "profile/friends-slice");
    if (status) {
      return res;
    } else {
      return rejectWithValue("!response.ok");
    }
  }
);

export const friendsSlice = createSlice({
  name: "friends",
  initialState,
  reducers: {
    resetState: (state, action) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFriendsData.fulfilled, (state, action) => {
        const { friends, pending, received } = action.payload;
        state.friends = friends;
        state.pending = pending;
        state.received = received;
        state.friendCount = friends.length;
      })
      .addCase(getFriendsData.rejected, (state, action) => {
        // console.log(action.payload);
      });
  },
});

export const selectAllFriends = (state) => state.friends.friends;
export const selectPendingRequests = (state) => state.friends.pending;
export const selectReceivedRequests = (state) => state.friends.received;
export const selectFriendCount = (state) => state.friends.friendCount;

export const { resetState } = friendsSlice.actions;

export default friendsSlice.reducer;
