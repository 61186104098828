import React, { useState } from "react";
import "./BasicGoal.css";
import { useNavigate } from "react-router-dom";
import NotesModal from "../NotesModal";
import { useDispatch, useSelector } from "react-redux";
import { deleteGoal, changeGoalStatus } from "../../features/goalSlice";
import { AiFillDelete } from "react-icons/ai";
import { FaNoteSticky } from "react-icons/fa6";
import { FaShare } from "react-icons/fa";
import ShareModal from "../ShareModal";
import { FaCircleInfo } from "react-icons/fa6";
import SharedGoalParticipantsModal from "../SharedGoalParticipantsModal";
import { request } from "../../util/util";
import { FaUpload } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { IoIosChatboxes } from "react-icons/io";
import { MdGroup } from "react-icons/md";
import { FaPerson } from "react-icons/fa6";
import { selectUsername } from "../../features/userSlice";

// const BASE_URL = process.env.REACT_APP_BASE_URL;

const BasicGoal = ({
  goal,
  viewNotes,
  shareGoal,
  viewParticipants,
  deleteGoal,
  editGoal,
  viewChat,
  userParticipantData,
}) => {
  // const [addNote, setAddNote] = useState(false);
  const [viewShareModal, setViewShareModal] = useState(false);
  const [viewNotesModal, setViewNotesModal] = useState(false);
  const [viewParticipantsModal, setViewParticipantsModal] = useState(false);
  const [changingStatus, setChangingStatus] = useState(false);
  const [notes, setNotes] = useState([]);
  const [goalStatus, setGoalStatus] = useState(goal.status);
  const [accountabilityPartners, setAccountabilityPartners] = useState(
    goal.accountability_partners
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const username = useSelector(selectUsername);

  const handleGoalDelete = async () => {
    await dispatch(deleteGoal({ goal, navigate }));
  };

  const formatDate = (dateString, displayTime = false) => {
    const dateObject = new Date(dateString); // Converts UTC string to Date object
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      ...(displayTime && { hour: "2-digit", minute: "2-digit", hour12: true }), // 12-hour format, no seconds
    };
    return dateObject.toLocaleString(undefined, options); // Returns formatted date with or without time
  };

  const handleChangeStatus = async (newGoalStatus) => {
    setChangingStatus(true);
    const { res, status } = await request(
      "PUT",
      "goal/status",

      {
        status: newGoalStatus,
        goalId: goal.id,
      }
    );
    if (status) {
      setGoalStatus((prev) => newGoalStatus);
      dispatch(changeGoalStatus({ id: goal.id, status: newGoalStatus }));
    }
    setChangingStatus(false);
  };

  const canEditGoal = userParticipantData?.can_edit ?? false;

  return (
    <div
      className={`border-2 border-slate-200 bg-slate-50 bg-opacity-50 shadow-sm p-5 rounded-lg flex flex-col flex-col-reverse md:flex-row ${
        goal.image ? " justify-between " : " justify-center items-center "
      } gap-5 w-full`}
    >
      <div
        className={`flex flex-col w-full ${
          goal.image ? "md:w-[60%]" : " items-center "
        } gap-4`}
      >
        <div className="flex flex-col gap-1 w-full">
          <p className="text-lg font-bold text-start w-full leading-tight">
            {goal.title}
          </p>
          {!goal.image && (
            <p className="font-semibold italic text-xs text-gray-500">
              You can add an image by editing the goal
            </p>
          )}
        </div>

        <div className="w-full flex flex-col">
          <p className="font-bold underline text-sm text-start">Description</p>
          <p className="font-semibold text-sm text-start text-gray-600">
            {goal.description ?? (
              <span className="italic text-xs text-gray-400">
                No description set<br></br>You can set one by editing the goal
              </span>
            )}
          </p>
        </div>
        <div className="flex flex-col w-full">
          <p className="text-xs text-start font-semibold text-gray-500">
            Start Date: {formatDate(goal.start_date, true)}
          </p>
          {goal.deadline && (
            <p className="text-xs text-start font-semibold text-gray-500">
              Deadline: {formatDate(goal.deadline, true)}
            </p>
          )}
        </div>
        <div className="flex flex-col md:flex-row gap-6 justify-start w-full">
          {goal.goal_participants.length > 1 ? (
            <>
              <button
                className="w-full md:w-1/2 flex flex-row  gap-1 items-start text-slate-600 group"
                onClick={() => {
                  viewParticipants(goal.goal_participants);
                }}
              >
                <MdGroup size={23} className="group-hover:text-primary-color" />
                <div className="flex flex-col items-start">
                  <p className="text-sm font-extrabold group-hover:text-primary-color text-start">
                    View Members
                  </p>
                  <p className="text-xs font-semibold text-gray-400 group-hover:text-primary-color text-start">
                    {goal.goal_participants
                      .slice(0, 2)
                      .map((participant) => participant.username)
                      .join(", ")}
                    {goal.goal_participants.length > 2 && <span>...</span>}{" "}
                  </p>
                </div>
              </button>
              <button
                onClick={viewChat}
                className="w-full md:w-1/2 flex flex-row gap-1 items-start text-slate-600 group"
              >
                <IoIosChatboxes
                  size={23}
                  className="group-hover:text-primary-color flex-shrink-0"
                />
                <div className="flex-1 min-w-0 w-full flex flex-col items-start justify-start">
                  {goal.last_message && goal.last_message.message ? (
                    <>
                      <p className="text-sm font-extrabold group-hover:text-primary-color text-start">
                        {goal.last_message.username ?? "Username unavailable"}
                      </p>
                      <p className="text-xs font-medium text-gray-400 group-hover:text-primary-color text-start truncate w-full">
                        {goal.last_message.message ?? "Message unavailable"}
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="text-sm font-extrabold group-hover:text-primary-color text-start">
                        View Chat
                      </p>
                      <p className="text-xs font-semibold text-gray-400 group-hover:text-primary-color text-start">
                        -
                      </p>
                    </>
                  )}
                </div>
              </button>
            </>
          ) : (
            <>
              <div className="flex flex-row gap-1 items-start w-full md:w-1/2">
                <FaPerson size={23} />
                <div className="flex flex-col items-start gap-1">
                  <p className="text-sm font-extrabold">Personal Goal</p>
                  <p className="text-xs font-semibold text-gray-400 italic text-start">
                    You can add accountability partners or participants at any
                    time by editing the goal
                  </p>
                </div>
              </div>
              <div className="w-full md:w-1/2 flex flex-row gap-1 items-start text-slate-600">
                <IoIosChatboxes size={23} />
                <div className="flex flex-col items-start justify-start gap-1">
                  <p className="text-sm font-extrabold text-start">
                    Chat Unavailable
                  </p>
                  <p className="text-xs font-semibold text-gray-400 italic text-start">
                    A chat is available to goals with more than one member
                  </p>
                </div>
              </div>
            </>
          )}
        </div>

        <div
          className={`grid grid-cols-2 gap-2 w-full ${
            goal.image ? "md:w-[70%]" : "md:w-[60%]"
          }`}
        >
          <button
            onClick={() => {
              // setViewNotesModal(true);
              viewNotes(goal.id);
            }}
            className="flex flex-row justify-center items-center hover:text-amber-500 gap-1 items-center text-xs font-bold border-2 border-slate-300 rounded-full w-fit py-1 w-full"
          >
            <span>Notes</span> <FaNoteSticky className="fill-[#b58900]" />
          </button>
          <button
            onClick={() => {
              // setViewShareModal(true);
              shareGoal(goal.id);
            }}
            className="flex flex-row justify-center items-center hover:text-primary-color gap-1 items-center text-xs font-bold border-2 border-slate-300 rounded-full w-fit py-1 w-full"
          >
            <span>Share</span>
            <FaShare className="fill-primary-color" />
          </button>
          <button
            disabled={!canEditGoal}
            onClick={
              canEditGoal
                ? () => {
                    // handleGoalDelete();
                    editGoal();
                  }
                : undefined
            }
            className={`flex flex-row justify-center items-center ${
              canEditGoal
                ? "hover:text-amber-200"
                : "bg-gray-200 !cursor-not-allowed"
            } gap-1 items-center text-xs font-bold border-2 border-slate-300 rounded-full py-1 w-full`}
          >
            <span>Edit</span>
            <MdEdit className="fill-yellow-500" />
          </button>
          <button
            onClick={canEditGoal ? deleteGoal : null}
            className={`${
              canEditGoal
                ? "hover:text-red-500"
                : "bg-gray-200 !cursor-not-allowed"
            } flex flex-row justify-center items-center  gap-1 items-center text-xs font-bold border-2 border-slate-300 rounded-full py-1 w-full`}
          >
            <span>Delete</span>
            <AiFillDelete className="fill-black" />
          </button>
        </div>
      </div>

      {goal.image && (
        <div className="w-full h-auto md:w-[35%] border-2 border-gray-400 rounded-xl bg-white flex justify-center items-center overflow-hidden">
          <img src={goal.image} className="object-cover w-full h-full" />
        </div>
      )}
    </div>
  );
};

export default BasicGoal;
