import React, { useState, useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { formatDateRelative, request } from "../util/util";
import { toast } from "react-toastify";
import { IoPersonCircleSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import CommentInput from "./CommentInput";

const Comment = ({
  comment,
  handleCommentDelete,
  profileInfo,
  role,
  isReply = false,
}) => {
  const [commentHasReplies, setCommentHasReplies] = useState(
    comment.reply_count > 0
  );
  const [viewReplies, setViewReplies] = useState(false);
  const [isReplying, setIsReplying] = useState(false);
  const [submittingComment, setSubmittingComment] = useState(false);
  const [replies, setReplies] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const handleReply = async (e, reply, setReply) => {
    e.preventDefault();
    console.log("handlereply");
    setSubmittingComment(true);
    const { status, res } = await request(
      "POST",
      `posts/comment/${comment.post_id}`,
      {
        parentComment: comment.comment,
        author_id: comment.author_id,
        parentCommentId: comment.id,
        comment: reply,
      }
    );
    if (status) {
      if (commentHasReplies) {
        setReplies([
          ...replies,
          {
            image: profileInfo.image,
            username: profileInfo.username,
            comment: reply,
            id: res,
            timestamp: new Date().toUTCString(),
          },
        ]);
      }
      setReply("");
      toast.success("Added reply");
      if (!commentHasReplies) {
        setCommentHasReplies(true);
      }
    }
    setSubmittingComment(false);
  };

  const fetchReplies = async () => {
    if (hasMore) {
      const { res, status } = await request(
        "GET",
        `posts/comment-replies/${comment.id}?page=${page}`
      );
      if (status) {
        // console.log("fetched replies");
        // console.log(res.replies);
        setReplies([...replies, ...res.replies]);
        // console.log("new replies");
        // console.log([...replies, ...res.replies]);
        if (res.replies.length < 5) {
          setHasMore(false);
        } else {
          setPage(page + 1);
        }
      }
    }
  };

  const handleReplyDelete = async (reply_id) => {
    request("DELETE", `posts/deletecomment/${reply_id}`).then(() => {
      setReplies(replies.filter((reply) => reply.id !== reply_id));
    });
  };

  return (
    <div key={comment.id} className="flex flex-col gap-3 px-4 py-2">
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1">
          <div className="flex flex-row gap-2 items-center">
            {comment.image ? (
              <div className="rounded-full border-[1px] border-gray-300 bg-gray-100 shadow-sm">
                <img
                  className="w-10 h-10 rounded-full object-cover"
                  src={comment.image}
                />
              </div>
            ) : (
              <IoPersonCircleSharp className="fill-gray-200 " size={47} />
            )}
            <div className="flex flex-col">
              <Link
                className="font-semibold text-base text-gray-700"
                to={`/profile/${comment.username}`}
              >
                {comment.username}
              </Link>
              <p className="text-xs text-gray-400 font-regular">
                {formatDateRelative(comment.timestamp)}
              </p>
            </div>
          </div>
          <p className="font-regular text-sm text-black">
            {parse(comment.comment)}
          </p>
        </div>
        <div>
          {(comment.username === profileInfo.username || role === "admin") && (
            <button onClick={() => handleCommentDelete(comment.id)}>
              <AiFillDelete className="fill-black hover:fill-red-500 size-5" />
            </button>
          )}
        </div>
      </div>
      {!isReply && (
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-5">
            <button
              className="text-sm  font-semibold"
              onClick={() => {
                setIsReplying(!isReplying);
              }}
            >
              {isReplying ? "Close" : "Reply"}
            </button>
            {commentHasReplies && (
              <button
                className="text-sm font-semibold flex flex-row items-center gap-1"
                onClick={() => {
                  setViewReplies(!viewReplies);
                  if (viewReplies === false && page === 1) {
                    fetchReplies();
                  }
                }}
              >
                {viewReplies ? (
                  <>
                    Hide Replies <FaAngleUp size={20} />
                  </>
                ) : (
                  <>
                    View Replies <FaAngleDown size={20} />
                  </>
                )}
              </button>
            )}
          </div>
          {isReplying && (
            <CommentInput
              disabled={submittingComment}
              handleComment={handleReply}
            />
          )}
        </div>
      )}
      {viewReplies && (
        <div className="ml-5 mb-10 flex flex-col gap-3">
          {replies.map((reply) => {
            return (
              <Comment
                comment={reply}
                isReply={true}
                profileInfo={profileInfo}
                role={role}
                handleCommentDelete={handleCommentDelete}
              />
            );
          })}
          {hasMore && (
            <button
              className="text-xs font-semiBold text-gray-500 flex flex-row"
              onClick={() => {
                fetchReplies();
              }}
            >
              View More <FaAngleDown size={20} />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Comment;
