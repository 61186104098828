import React, { useState, useEffect } from "react";
import Modal from "../Modal";
import "./AddGoalCategoryModal.css";
import { request } from "../../util/util";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAllFriends } from "../../features/friendsSlice";
import {
  addGoalCategory,
  editGoalCategory,
  selectCategories,
} from "../../features/goalSlice";
import { ChromePicker } from "react-color";
import rgbHex from "rgb-hex";
import { toast } from "react-toastify";

const AddGoalCategoryModal = ({
  view,
  setView,
  changeCategory,
  categoryToEdit,
  setCategoryToEdit,
}) => {
  const dispatch = useDispatch();
  const categories = useSelector(selectCategories);
  const [input, setInput] = useState("Custom Category");
  const [error, setError] = useState(false);
  const [colors, setColors] = useState({
    textColor: "#000000",
    underlineColor: "#39e079",
    bgColor: "#e5e7eb",
  });
  const [currentColor, setCurrentColor] = useState(null);
  const [showPicker, setShowPicker] = useState(false);

  useEffect(() => {
    setInput(categoryToEdit?.category ?? "Custom Category");
    // setColors({
    //   textColor: categoryToEdit?.text_color ?? "#000000",
    //   underlineColor: categoryToEdit?.underline_color ?? "#39e079",
    //   bgColor: categoryToEdit?.bg_color ?? "#e5e7eb",
    // });
  }, [categoryToEdit]);

  // const handleColorClick = (colorKey) => {
  //   setCurrentColor(colorKey);
  //   setShowPicker(true);
  // };

  // const handleColorChange = (c) => {
  //   setColors((prevColors) => ({
  //     ...prevColors,
  //     [currentColor]: "#" + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a),
  //   }));
  //   console.log("new color ", "#" + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a));
  // };

  // const closePicker = () => {
  //   setShowPicker(false);
  //   setCurrentColor(null);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    // setError(false);
    // const {res,status,msg}=await request("POST",'/')
    if (
      [
        ...categories,
        { category: "All" },
        { category: "Shared" },
        { category: "Accountability" },
      ]
        .map((category) => category.category.toLowerCase())
        .includes(input.toLowerCase())
    ) {
      toast.error(`Category "${input}" Already Available`);
      // setError(true);
      return;
    } else {
      const action = categoryToEdit ? editGoalCategory : addGoalCategory;
      // const reqBody = { category: input, ...colors };
      const reqBody = { category: input };
      if (categoryToEdit) {
        reqBody["id"] = categoryToEdit.id;
      }
      dispatch(action(reqBody))
        .unwrap()
        .then((result) => {
          setCategoryToEdit(null);
          setInput("");
          setView(false);
          changeCategory(result.id);
        });
    }
  };

  const isButtonDisabled =
    input.trim() === "" || input.trim() === "Custom Category";

  return (
    <Modal
      show={view}
      setShow={(val) => {
        if (!val && categoryToEdit) {
          setCategoryToEdit(null);
        }
        setView(val);
      }}
    >
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center gap-4"
      >
        <div className="w-full flex flex-col justify-center gap-1 items-center">
          <p className="font-semibold text-black text-xl">
            {categoryToEdit && "Edit "}Category
          </p>
          {!categoryToEdit && (
            <p className="text-gray-500 font-medium text-sm mb-2 w-3/4 text-center mb-5">
              When you create a custom category, you can use it anytime you make
              or edit a goal, making it easier to stay organized and find what
              you need.
            </p>
          )}
          <input
            type="text"
            className="w-full md:w-96 p-1 font-semibold rounded-md border-2 border-gray-300 "
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          {error && (
            <p className="text-red-500 text-sm">Category already found</p>
          )}
        </div>
        {/* <div className="flex flex-col gap-3 flex-wrap justify-center items-center">
          <h2 className="font-semibold text-lg text-black">
            Choose Your Custom Category Colors
          </h2>
          <div className="flex flex-row item-start gap-6">
            {Object.keys(colors).map((colorKey) => (
              <div className="flex flex-row gap-1">
                <div
                  key={colorKey}
                  className="w-5 h-5 border "
                  style={{
                    backgroundColor: colors[colorKey],
                    cursor: "pointer",
                    marginBottom: "10px",
                    textAlign: "center",
                  }}
                  onClick={() => handleColorClick(colorKey)}
                ></div>
                <p className="font-medium text-base">
                  {colorKey === "textColor"
                    ? "Text"
                    : colorKey === "bgColor"
                    ? "Background"
                    : "Underline"}
                </p>
              </div>
            ))}
          </div>
        </div> */}

        {/* <div className="w-full flex flex-col items-center gap-1">
          <div className="w-full md:w-[40%]">
            <h2 className="text-base font-bold text-black self-start">
              Preview (when selected):
            </h2>

            <div
              className={` min-w-[100px] border-b-4 py-2.5 px-4 flex justify-center items-center font-semibold`}
              style={{
                backgroundColor: colors.bgColor,
                color: colors.textColor,
                borderBottomColor: colors.underlineColor,
              }}
            >
              {input}
            </div>
          </div>
        </div> */}

        <button
          type="submit"
          className={` ${
            isButtonDisabled ? "bg-gray-200" : "bg-primary-color"
          } mt-2 px-6 py-2 h-fit font-semibold rounded-md`}
          disabled={isButtonDisabled}
        >
          Save
        </button>
      </form>

      {/* {showPicker && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "#fff",
            padding: "10px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.3)",
            borderRadius: "8px",
            zIndex: 1000,
          }}
        >
          <ChromePicker
            color={colors[currentColor]}
            onChange={handleColorChange}
          />
          <button onClick={closePicker} style={{ marginTop: "10px" }}>
            Close
          </button>
        </div>
      )} */}
    </Modal>
  );
};

export default AddGoalCategoryModal;
