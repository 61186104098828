import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import Modal from "./Modal";
import { request } from "../util/util";
import { useSelector } from "react-redux";
import { selectUsername } from "../features/userSlice";
import { toast } from "react-toastify";
import { OrbitProgress } from "react-loading-indicators";
import { BsArrowDown } from "react-icons/bs";

const AccountabilityMessages = ({
  viewAccountabilityMessages,
  setViewAccountabilityMessages,
  goal_id,
  goal,
}) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [sendingMessage, setSendingMessage] = useState(false);
  const username = useSelector(selectUsername);
  const [loading, setLoading] = useState(true);
  const [fetchedInitially, setFetchedInitially] = useState(false);
  const [showArrow, setShowArrow] = useState(false);
  // Refs for scroll management:
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  // Save the scroll offset before a messages update.
  const savedScrollOffset = useRef(0);
  const timerRef = useRef(null);

  function scrollToBottom() {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  // When new messages are rendered, restore the saved scroll offset.
  useLayoutEffect(() => {
    const container = messagesContainerRef.current;
    if (container) {
      container.scrollTop = savedScrollOffset.current;
    }
  }, [messages]);

  // The new fetchData:
  const fetchData = async () => {
    // Save current scroll position before updating messages.
    const container = messagesContainerRef.current;
    if (container) {
      savedScrollOffset.current = container.scrollTop;
    }
    if (!fetchedInitially) setLoading(true);

    const { res, status } = await request(
      "GET",
      `goal/accountability-messages/${goal_id}`
    );
    console.log("get messages res ", res);
    if (status && res) {
      // Update messages unconditionally.
      setMessages(res);
      // if (!fetchedInitially) {
      //   setTimeout(() => {
      //     messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      //   }, 0);
      // }
    }
    if (!fetchedInitially) {
      setLoading(false);
      setFetchedInitially(true);
    }
  };

  // When the modal is open, start polling:
  useEffect(() => {
    if (viewAccountabilityMessages) {
      fetchData();
      timerRef.current = setInterval(fetchData, 60000);
    } else {
      // When the modal is hidden, stop polling.
      if (timerRef.current) clearInterval(timerRef.current);
    }
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, [viewAccountabilityMessages, goal_id, fetchedInitially]);

  // Submit a new message:
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (sendingMessage) return;
    setSendingMessage(true);
    const res = await request("POST", "goal/message", {
      goal_id,
      message: input,
    });
    if (res.status) {
      // Append new message and scroll to bottom.
      setMessages((prev) => [
        ...prev,
        {
          message_id: res.status,
          timestamp: new Date().toISOString(),
          message_text: input,
          username,
        },
      ]);

      setInput("");
      // When adding a new message, scroll to bottom.
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 0);
    } else {
      toast.error("Failed to send message");
    }
    setSendingMessage(false);
  };
  // Listen for scroll events to determine whether to show the arrow.
  useEffect(() => {
    const container = messagesContainerRef.current;
    if (!container) return;
    const threshold = 50; // pixels from bottom

    const handleScroll = () => {
      const nearBottom =
        container.scrollTop + container.clientHeight >=
        container.scrollHeight - threshold;
      setShowArrow(!nearBottom);
    };

    container.addEventListener("scroll", handleScroll);
    // Initial check.
    handleScroll();
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [messages]);

  const formattedDate = (submission_time) => {
    const originalDate = new Date(submission_time);
    return originalDate.toLocaleString();
  };

  return (
    <Modal
      fullHeight={true}
      show={viewAccountabilityMessages}
      setShow={setViewAccountabilityMessages}
    >
      <div className="h-full w-full flex flex-col gap-4 p-1">
        <p className="w-full text-center text-primary-color text-2xl font-medium mb-5">
          {goal?.title ?? "Chat"}
        </p>
        <div
          ref={messagesContainerRef}
          className="flex-1 w-full flex flex-col gap-6 overflow-y-auto min-h-0 relative"
        >
          {showArrow && (
            <button
              onClick={() => {
                scrollToBottom();
              }}
              className="w-fit sticky ml-auto  top-[70%]  rounded-full bg-primary-color bg-opacity-70 text-white p-2"
            >
              <BsArrowDown className="" size={25} />
            </button>
          )}
          {loading ? (
            <div className="w-full flex justify-center items-center my-5">
              <OrbitProgress
                color="#32cd32"
                size="small"
                text=""
                textColor=""
                style={{ fontSize: "6px" }}
              />
            </div>
          ) : messages.length > 0 ? (
            messages.map((message, index) => (
              <div
                key={index}
                className={`bg-gray-50 shadow-md border border-gray-300 rounded-xl flex justify-between px-2 py-1 items-center max-w-[90%] lg:max-w-[70%] w-full min-w-[40%] ${
                  username === message.username
                    ? "self-end rounded-tr-none"
                    : "self-start rounded-tl-none"
                }`}
              >
                <div className="flex flex-col w-full">
                  <p className="self-start font-semibold text-lg lg:text-xl mb-1">
                    {message.username}
                  </p>
                  <p className="w-full text-md pl-2.5 font-regular text-black">
                    {message.message_text}
                  </p>
                  <p className="self-end text-gray-700 font-medium text-xs">
                    {formattedDate(message.timestamp)}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-500 italic text-lg">
              No messages yet. Send the first one and get things rolling!
            </p>
          )}
          <div ref={messagesEndRef} />
        </div>
        <form
          onSubmit={handleSubmit}
          className="w-full flex flex-row justify-center items-end gap-2 mt-5"
        >
          <textarea
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className="px-2 border border-gray-400 flex-1 h-10 rounded-lg"
          ></textarea>
          <button className="w-fit h-10 font-semibold bg-primary-color text-white px-4 rounded-md hover:bg-white hover:text-primary-color border border-primary-color transition-colors duration-300">
            Send
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default AccountabilityMessages;
