import React from "react";
import { TbError404 } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
const NotFound = ({ message }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="w-full h-full flex flex-col gap-4 items-center justify-start pt-[10vh]">
      <div className="flex flex-col items-center">
        <TbError404 className="text-primary-color size-20 lg:size-32" />
        <p className="text-3xl font-bold text-gray-500">
          {message ?? "Page Not Found"}
        </p>
      </div>
      <button
        onClick={() => {
          if (location.key === "default") {
            // No previous history within the app, navigate to a default route
            navigate("/social?section=community");
          } else {
            navigate(-1);
          }
        }}
        className="bg-primary-color text-white font-bold text-xl px-14 py-2 rounded-lg border-2 border-primary-color hover:bg-white hover:text-primary-color transition-colors duration-300"
      >
        Go Back
      </button>
    </div>
  );
};

export default NotFound;
