import React, { useState } from "react";
import ReactQuill from "react-quill";

const CommentInput = ({ disabled, handleComment }) => {
  const [input, setInput] = useState("");

  const modules = {
    toolbar: [
      ["bold", "italic"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  const formats = ["bold", "italic", "list", "bullet"];

  return (
    <form
      onSubmit={(e) => {
        handleComment(e, input, setInput);
      }}
      className="flex flex-col w-full gap-2"
    >
      <ReactQuill
        theme="snow"
        value={input}
        onChange={setInput}
        placeholder="Share your thoughts"
        modules={modules}
        formats={formats}
        className="font-regular text-gray-600"
      />
      <button
        disabled={disabled}
        className="bg-primary-color border-2 border-primary-color hover:bg-white hover:text-primary-color transition-colors duration-50 rounded-md font-bold text-xs px-8 py-2 self-end"
      >
        Comment
      </button>
    </form>
  );
};

export default CommentInput;
