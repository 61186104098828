import React, { useEffect, useRef, useState } from "react";
import { request, formatDate, formatDateRelative } from "../util/util";
import { selectNotifications } from "../features/notificationsSlice";
import { useSelector } from "react-redux";
import { titleClasses } from "../util/globals";
import { AiOutlineMessage } from "react-icons/ai";
import { GoAlert } from "react-icons/go";
import { RiQuillPenLine } from "react-icons/ri";
import { IoPersonAddOutline, IoPersonCircleSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import { LiaUserFriendsSolid } from "react-icons/lia";
import { GiTumbleweed } from "react-icons/gi";
import { TiMessages } from "react-icons/ti";
import { TbUserPlus } from "react-icons/tb";
import {
  MdDateRange,
  MdOutlineEventBusy,
  MdPersonAddDisabled,
  MdReadMore,
} from "react-icons/md";
import { AiOutlineInbox } from "react-icons/ai";
import { FaClipboardList, FaRegSadCry } from "react-icons/fa";
import { BsEmojiTear } from "react-icons/bs";
import { selectUsername } from "../features/userSlice";
import { IoCheckmarkSharp } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import SEO from "../components/SEO";
import { PiLightningFill } from "react-icons/pi";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import AccountabilityMessages from "../components/AccountabilityMessages";
import { OrbitProgress } from "react-loading-indicators";

const Updates = () => {
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [viewGoalChat, setViewGoalChat] = useState(null);
  const notifications = useSelector(selectNotifications);
  const username = useSelector(selectUsername);
  const timerRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await request("GET", "user/dashboard");
      if (res && res.status) {
        setInfo(res.res.data);
        console.log("res", res);
        // console.log(res);
      }
      setLoading(false);
    };
    fetchData();
    const fiveMinutes = 300000; //in ms
    if (timerRef.current) clearInterval(timerRef.current);
    timerRef.current = setInterval(fetchData, fiveMinutes);
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, []);

  const mapUntilIndex = (array, index, callback) => {
    return array.slice(0, index).map(callback);
  };

  const cellClasses = "flex flex-col justify-center items-center gap-4";
  const cellTitleClasses =
    "text-base xl:text-lg font-semibold text-white text-center";
  const iconContainerClasses = "bg-gray-100 p-2 rounded-md";
  const iconClasses = "size-6";
  const noDataIconClasses = "size-7 ";
  const callToActionClasses = "italic underline";
  // NEW CLASSES
  const containerClasses =
    "shadow-xl rounded-xl w-full flex flex-col border-2 border-gray-200 overflow-y-auto";
  const titleContainerClasses =
    "rounded-t-lg py-3 border-gray-200 w-full h-fit flex gap-1 justify-center items-center ";
  const containerTitleClasses = "text-xl font-bold text-gray-600 text-center";
  const containerContentClasses = (listEmpty = null, extraGap = false) =>
    `flex flex-col min-h-0 ${
      listEmpty
        ? " justify-center items-center p-2 "
        : " justify-start items-start p-3 "
    } flex-grow ${extraGap ? " gap-4 " : " gap-3 "} overflow-y-auto`;
  const noDataContainer =
    "flex-grow flex flex-row gap-2 items-center justify-center";
  const noDataTextClasses = "text-base text-gray-600 font-semibold text-center";

  const handleFriendRequest = async (user, type) => {
    const { res, status } = await request(
      "POST",
      `profile/${user}/${
        type === "accept" ? "accept-request" : "decline-request"
      }`
    );
    if (status) {
      setInfo({
        ...info,
        friend_requests_received: info.friend_requests_received.filter(
          (currUser) => currUser.username != user
        ),
      });
      toast.success(
        `${
          type === "accept"
            ? "Friend request accepted!"
            : "Friend request declined"
        }`
      );
    } else {
      toast.error("An error occurred");
    }
  };

  if (loading)
    return (
      <div className="w-full h-full flex flex-col justify-start pt-[15%] items-center">
        <OrbitProgress
          color="#32cd32"
          size="small"
          text=""
          textColor=""
          style={{ fontSize: "8px" }}
        />
      </div>
    );
  else
    return (
      <div className="flex flex-col h-full gap-4 p-3 xl:px-0 xl:py-5">
        <SEO
          title={`Highlights - Friengle`}
          description={`View upcoming deadlines, latest messages, latest friend posts, and friend requests.`}
        />
        {/* <h1 className={titleClasses}>Updates</h1> */}
        <div className="w-full xl:h-full flex flex-col xl:flex-row gap-5 justify-between">
          <div className="xl:flex-1 flex flex-col gap-5 xl:gap-0 justify-between items-start">
            {/* UPCOMING DEADLINES CONTAINER */}
            <div
              className={
                "h-fit max-h-[400px] xl:max-h-auto xl:h-1/2 bg-white shadow-md  w-full rounded-xl flex flex-col border-2 border-gray-200"
              }
            >
              <div className={titleContainerClasses}>
                <MdDateRange className={iconClasses} />
                <h2 className={containerTitleClasses}>Upcoming Deadlines</h2>
              </div>
              <div
                className={`${containerContentClasses(
                  info.upcoming_deadlines === null ||
                    info.upcoming_deadlines?.length === 0
                )}`}
              >
                {info.upcoming_deadlines != null &&
                Array.isArray(info.upcoming_deadlines) &&
                info.upcoming_deadlines.length > 0 ? (
                  <div className="w-full flex flex-col relative">
                    {" "}
                    <div className="sticky -top-3 bg-gray-50 grid grid-cols-3  border-2 border-gray-200 px-6 py-2  rounded-tr-xl rounded-tl-xl ">
                      <p className="text-center  text-base font-bold text-slate-600">
                        Goal
                      </p>
                      <p className="font-semibold text-slate-700 text-center">
                        Status
                      </p>
                      <p className="font-semibold text-slate-700 text-center">
                        Completion percentage
                      </p>
                    </div>
                    <div className="rounded-bl-xl rounded-br-xl  border-2 border-t-0  border-gray-200">
                      {info.upcoming_deadlines.map((goal, index) => {
                        return (
                          <div
                            key={`deadline_${goal.id}`}
                            className={`grid grid-cols-3 px-6 py-2 items-center ${
                              index !== info.upcoming_deadlines.length - 1
                                ? " border-b-2 border-gray-200 "
                                : " "
                            }`}
                          >
                            <div className="flex flex-col items-center">
                              <p className="text-base font-semibold text-slate-700">
                                {goal.title}
                              </p>
                              <p className="text-xs font-medium text-slate-500">
                                Start date: {formatDate(goal.start_date, true)}
                              </p>
                              <p className="text-xs font-medium text-slate-500">
                                Deadline: {formatDate(goal.deadline, true)}
                              </p>
                            </div>
                            <div className="w-full flex flex-col justify-start items-center gap-2">
                              <p className="font-medium text-sm first-letter:uppercase text-slate-700">
                                {goal.status}
                              </p>
                            </div>
                            <div className="w-full flex flex-col justify-start items-center gap-2">
                              <p className="font-medium text-slate-700 first-letter:uppercase text-sm">
                                {goal.type === "basic"
                                  ? "-"
                                  : `${(goal.completion_percentage ?? 0)
                                      .toFixed(2)
                                      .replace(/\.00$/, "")}%`}
                              </p>
                            </div>
                            {/* <div className="self-end justify-self-end">
                              <button className="text-xs font-bold text-slate-600 hover:text-primary-color">
                                View Goal &gt;
                              </button>
                            </div> */}
                          </div>
                          // </div>
                          // <button className="self-end text-sm font-bold hover:text-primary-color">
                          //   View Goal &gt;
                          // </button>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div className="p-5 text-center">
                    <p className={noDataTextClasses}>
                      No upcoming deadlines yet!<br></br>Add a goal with a
                      deadline to track it here when it’s close.
                    </p>
                    <Link
                      to={"/goals"}
                      state={{ new_goal: true }}
                      className="font-bold underline hover:text-primary-color"
                    >
                      Create a goal.
                    </Link>
                  </div>
                )}
              </div>
            </div>

            <div className="xl:h-[48%] w-full grid grid-cols-1 xl:grid-cols-2 gap-5">
              {/* modify latest messages in api to return all messages limit 20 that arent from user starting most recent */}
              <div
                className={`h-fit max-h-[400px] xl:max-h-auto xl:h-auto ${containerClasses}`}
              >
                <div className={titleContainerClasses}>
                  <TiMessages className={iconClasses} />
                  <h2 className={containerTitleClasses}>Latest Messages</h2>
                </div>
                <div
                  className={containerContentClasses(
                    info.accountability_messages === null ||
                      info.accountability_messages?.length === 0
                  )}
                >
                  {info.accountability_messages != null ? (
                    info.accountability_messages.map((message) => {
                      return (
                        <div className="w-full flex flex-col items-start bg-gray-50 rounded-xl p-2">
                          <div className="flex flex-row w-full justify-between items-center">
                            <div className="text-lg text-slate-500 text-start">
                              <Link
                                to={`/profile/${message.username}`}
                                className="text-lg font-bold text-slate-700 hover:text-primary-color"
                              >
                                {message.username}
                              </Link>
                              <span className="font-semibold">
                                , {message.title}
                              </span>
                            </div>
                            <p className="text-xs text-slate-400 font-semibold text-center">
                              {formatDateRelative(message.timestamp, true)}
                            </p>
                          </div>
                          <p className="font-medium text-base">
                            {message.message_text}
                          </p>

                          {message.goal_id && (
                            <button
                              onClick={() => {
                                setViewGoalChat(message.goal_id);
                              }}
                              className="self-end text-slate-700 font-bold text-sm hover:text-primary-color hover:cursor-pointer"
                            >
                              View chat &gt;
                            </button>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <div className={noDataContainer}>
                      <p className={noDataTextClasses}>
                        Once you create a goal with at least two people or are
                        added to one, recent messages will appear here.
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div
                className={`h-fit max-h-[400px] xl:max-h-auto xl:h-auto ${containerClasses}`}
              >
                <div className={titleContainerClasses}>
                  <TbUserPlus className={iconClasses} />
                  <h2 className={containerTitleClasses}>
                    Friend Requests Received
                  </h2>
                </div>
                <div
                  className={containerContentClasses(
                    info.friend_requests_received === null ||
                      info.friend_requests_received?.length === 0
                  )}
                >
                  {info.friend_requests_received != null ? (
                    info.friend_requests_received.map((person) => {
                      return (
                        <div className="flex flex-row w-full justify-between px-5 py-3 rounded-xl bg-gray-50">
                          <Link
                            to={`/profile/${person.username}`}
                            className="text-md font-bold text-slate-700 hover:text-primary-color flex flex-row items-center gap-1"
                          >
                            {person.image ? (
                              <img
                                className="w-10 h-10 rounded-full object-cover"
                                src={person.image}
                              />
                            ) : (
                              <IoPersonCircleSharp
                                className="fill-gray-400"
                                size={40}
                              />
                            )}
                            {person.username}
                          </Link>
                          <div className="flex flex-row gap-2 items-center">
                            <button
                              onClick={() => {
                                handleFriendRequest(person.username, "accept");
                              }}
                              className={
                                "text-lg p-1 rounded-full border-2 border-green-500 hover:bg-green-500 hover:text-white  bg-white text-green-500  transition duration-300"
                              }
                            >
                              <IoCheckmarkSharp />
                            </button>
                            <button
                              onClick={() => {
                                handleFriendRequest(person.username, "decline");
                              }}
                              className={
                                "text-lg p-1 rounded-full border-2 border-red-500 hover:bg-red-500 hover:text-white bg-white text-red-500 transition duration-300"
                              }
                            >
                              <MdClose />
                            </button>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className={noDataContainer}>
                      <p className={noDataTextClasses}>
                        Your friends can find you by searching for @{username}.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`flex-shrink-0 h-fit max-h-[400px] xl:flex-shrink xl:h-auto xl:max-h-none  xl:basis-[25%] mb-5 xl:mb-0 ${containerClasses}`}
          >
            <div className={titleContainerClasses}>
              <LiaUserFriendsSolid className={iconClasses} />
              <h2 className={containerTitleClasses}>Keep up with friends</h2>
            </div>
            <div
              className={containerContentClasses(
                info.latest_friends_posts === null ||
                  info.latest_friends_posts?.length === 0
              )}
            >
              {info.latest_friends_posts != null ? (
                info.latest_friends_posts.map((post) => {
                  return (
                    <div className="w-full flex flex-col items-start gap-2 bg-gray-50 rounded-xl p-2">
                      <div className="flex flex-row gap-2 items-center">
                        <div className="rounded-full w-10 h-10 bg-gray-300 shadow-sm">
                          {post.image ? (
                            <img
                              className="w-10 h-10 rounded-full object-cover"
                              src={post.image}
                            />
                          ) : (
                            <IoPersonCircleSharp
                              className="fill-white"
                              size={40}
                            />
                          )}
                        </div>
                        <div>
                          <p className="text-base font-bold text-slate-500">
                            {post.username}
                          </p>
                          <p className="text-xs text-slate-400 font-semibold">
                            {formatDateRelative(post.post_date, true)}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col flex-1 w-full">
                        <p className="text-base font-medium text-slate-500">
                          {post.title}
                        </p>
                        <Link
                          to={`/comments/${post.id}`}
                          className="text-sm font-bold text-end hover:text-primary-color hover:cursor-pointer"
                        >
                          View Post &gt;
                        </Link>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className={noDataContainer}>
                  <p className={noDataTextClasses}>
                    When friends post something in the Social section, under
                    Friends, it will appear here.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        <AccountabilityMessages
          viewAccountabilityMessages={viewGoalChat !== null}
          setViewAccountabilityMessages={(_) => {
            setViewGoalChat(null);
          }}
          goal_id={viewGoalChat}
          goal={{
            title:
              info.accountability_messages?.find(
                (item) => item.goal_id === viewGoalChat
              )?.title ?? "",
          }}
        />
      </div>
    );
};

export default Updates;
