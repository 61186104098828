import React, { useState, useEffect, useRef } from "react";
import "./Home.css";
import Post from "../../components/Post/Post";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  selectUsername,
  selectRole,
  selectLoading,
} from "../../features/userSlice";
import { FaFireAlt, FaUserFriends } from "react-icons/fa";
import InfiniteScroll from "react-infinite-scroll-component";
import { selectLoggedIn } from "../../features/userSlice";
import {
  selectAllFriends,
  selectFriendCount,
} from "../../features/friendsSlice";
import Skeleton from "react-loading-skeleton";
import {
  useSearchParams,
  useNavigate,
  useLocation,
  NavLink,
  Link,
} from "react-router-dom";
import profilePlaceholder from "../../assets/profile.png";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
import { request } from "../../util/util";
import { IoPersonCircleSharp, IoPersonSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import NewPost from "../../components/NewPost";
import { Helmet } from "react-helmet-async";
import SEO from "../../components/SEO";
import { titleClasses } from "../../util/globals";
import { MdKeyboardArrowRight } from "react-icons/md";
import {
  changeChannel,
  changeFilter,
  changeSection,
  cleanChannelName,
  getChannels,
  getPosts,
  removePost,
  selectChannels,
  selectFetchedInitialData,
  selectFilter,
  selectHasMore,
  selectPage,
  selectPosts,
  selectSection,
  selectSelectedChannelId,
  setFetchedInitialData,
} from "../../features/socialSlice";
import { calculateLevel } from "../../util/level_util";
import DeleteModal from "../../components/DeleteModal";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const Home = () => {
  const dispatch = useDispatch();
  const [deletePostWithId, setDeletePostWithId] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const [members, setMembers] = useState(null);
  const [showPostModal, setShowPostModal] = useState(false);
  const [newPostContent, setNewPostContent] = useState(null);
  const section = useSelector(selectSection);
  const filter = useSelector(selectFilter);
  const username = useSelector(selectUsername);
  const role = useSelector(selectRole);
  const page = useSelector(selectPage);
  // const [posts, setPosts] = useState([]);
  // const [hasMore, setHasMore] = useState(true);
  const posts = useSelector(selectPosts);
  const hasMore = useSelector(selectHasMore);
  const loggedIn = useSelector(selectLoggedIn);
  const loading = useSelector(selectLoading);
  const navigate = useNavigate();
  const location = useLocation();
  const friendCount = useSelector(selectFriendCount);
  const friends = useSelector(selectAllFriends);
  const channels = useSelector(selectChannels);
  const selectedChannelId = useSelector(selectSelectedChannelId);
  // const friends = [];
  // const isInitialRender = useRef(true);
  const fetchedInitialData = useSelector(selectFetchedInitialData);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       let promises = [request("GET", "community/members-count")];
  //       if (!fetchedInitialData) {
  //         promises.push(dispatch(getChannels()));
  //       }
  //       const [response1, response2] = await Promise.allSettled(promises);

  //       if (response1.status === "fulfilled") {
  //         setMembers(response1.value.res.members);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data", error);
  //     }
  //   };

  //   fetchData();
  // }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await request("GET", "community/members-count");
        if (res.status) {
          setMembers(res.res.members);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!channels || channels.length === 0) {
      dispatch(getChannels());
      return;
    }

    const DEFAULT_SECTION = "community";
    const VALID_SECTIONS = ["community", "friends"];
    const DEFAULT_FILTER = "new";
    const VALID_FILTERS = ["new", "trending", "top"];

    const validateSearchParams = () => {
      let sectionParam = searchParams.get("section");
      let filterParam = searchParams.get("filter");
      // let channelParam = searchParams.get("channel");

      let needUpdate = false;
      const updatedParams = new URLSearchParams();

      // Validate section – default to community if invalid.
      if (!VALID_SECTIONS.includes(sectionParam)) {
        sectionParam = DEFAULT_SECTION;
        needUpdate = true;
        updatedParams.set("section", sectionParam);
      }

      if (sectionParam === "community") {
        // Validate filter – default to "new" if invalid.
        if (!VALID_FILTERS.includes(filterParam)) {
          filterParam = DEFAULT_FILTER;
          needUpdate = true;
          updatedParams.set("filter", filterParam);
        }
        if (filter !== filterParam) {
          dispatch(changeFilter(filterParam));
        }

        // console.log("channel param: ", channelParam);
        // // Validate channel.
        // const matchingChannel = channels.find(
        //   (c) => cleanChannelName(c.title) === channelParam
        // );
        // if (matchingChannel) {
        //   console.log("matching channel ", matchingChannel);
        //   updatedParams.set("channel", channelParam);
        //   if (selectedChannelId !== matchingChannel.id) {
        //     dispatch(changeChannel(matchingChannel.id));
        //   }
        // } else {
        //   // If no matching channel, use the first channel as default.
        //   const defaultChannel = channels[0];
        //   if (defaultChannel) {
        //     channelParam = cleanChannelName(defaultChannel.title);
        //     updatedParams.set("channel", channelParam);
        //     if (selectedChannelId !== defaultChannel.id) {
        //       dispatch(changeChannel(defaultChannel.id));
        //     }
        //     needUpdate = true;
        //   }
        // }
      } else if (sectionParam === "friends") {
        // For friends, remove any extra params.
        if (searchParams.has("filter") || searchParams.has("channel")) {
          updatedParams.set("section", "friends");
          needUpdate = true;
        }
      }

      if (section !== sectionParam) {
        dispatch(changeSection(sectionParam));
      }

      // Update the URL only if something has changed.
      if (needUpdate) {
        setSearchParams(updatedParams);
      }
    };

    // Run validation on initial render or if the current searchParams don't match the URL.
    if (!fetchedInitialData) {
      console.log("validatesearchparams");
      validateSearchParams();
      dispatch(setFetchedInitialData(true));
    }
  }, [channels, dispatch, section, filter, selectedChannelId, searchParams]);

  useEffect(() => {
    if (
      !fetchedInitialData &&
      // (section === "friends" || (filter && selectedChannelId))
      (section === "friends" || filter)
    ) {
      dispatch(getPosts());
    }
  }, [section, filter, selectedChannelId, dispatch]);

  const handleChangeSection = (newSection) => {
    dispatch(changeSection(newSection));

    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set("section", newSection);

    // Remove other params not relevant to the new section
    if (newSection !== "community") {
      newSearchParams.delete("filter");
      newSearchParams.delete("channel");
    }

    setSearchParams(newSearchParams);
  };

  const handleChangeFilter = (newFilter) => {
    dispatch(changeFilter(newFilter));

    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set("filter", newFilter);
    setSearchParams(newSearchParams);
  };

  const handleChangeChannel = (channelId) => {
    dispatch(changeChannel(channelId));

    const matchingChannel = channels.find((c) => c.id === channelId);
    const channelName = cleanChannelName(matchingChannel.title);

    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set("channel", channelName);
    setSearchParams(newSearchParams);
  };

  return (
    <div className="flex flex-col gap-5 w-full">
      <SEO
        title={"Social Feed - Friengle"}
        description={
          "Dive into a community where motivation meets action. Share your journey, learn from others, and achieve your goals together."
        }
      />
      <div className="flex flex-row justify-between items-center">
        <h1 className={titleClasses}>
          {/* <FaUserFriends className="fill-primary-color" /> */}
          Community and Friends Feed
        </h1>

        <button
          onClick={() => {
            if (loggedIn) {
              setShowPostModal(true);
            } else {
              navigate("/login");
            }
          }}
          className="md:px-4 md:py-2 rounded-md bg-gray-200 text-black h-fit w-28 font-bold text-sm"
        >
          {loggedIn ? "New Post" : "Login"}
        </button>
      </div>

      <div className="sticky top-14 bg-white z-40 flex flex-col gap-4">
        <div className="flex flex-row border-b-2 border-gray-200 ">
          {["Community", loggedIn && "Friends"]
            .filter(Boolean)
            .map((item, index) => {
              const itemToLower = item.toLowerCase();
              return (
                <button
                  key={index}
                  className={` text-sm font-semibold border-solid py-2.5 transition-all duration-300 ${
                    section === itemToLower
                      ? " text-slate-600  border-b-4 bg-gray-100 px-4 border-primary-color"
                      : " text-slate-400 border-b-2 px-2 border-gray-300"
                  }`}
                  onClick={() => {
                    handleChangeSection(itemToLower);
                  }}
                  disabled={section === itemToLower}
                >
                  {item}
                </button>
              );
            })}
        </div>
        {section === "community" && (
          <div className="flex flex-row gap-3 sticky z-40">
            {["Top", "Trending", "New"].map((item, index) => {
              const itemToLower = item.toLowerCase();
              return (
                <button
                  key={index}
                  onClick={() => {
                    handleChangeFilter(itemToLower);
                  }}
                  className={`py-1 px-3 text-base rounded-md transition-all duration-50 ${
                    filter === itemToLower
                      ? "font-bold bg-slate-300 text-gray-700 "
                      : "font-semibold bg-slate-100 text-gray-500"
                  }`}
                >
                  {item}
                </button>
              );
            })}
          </div>
        )}
      </div>
      {posts !== null ? (
        <div className="flex flex-col-reverse xl:flex-row gap-4 w-full h-full">
          <div className="flex-1">
            {section === "friends" && !hasMore && posts.length === 0 ? (
              <div className="w-full h-full flex flex-row justify-center items-center">
                <p className="font-bold text-lg text-gray-500">
                  No friend activity right now. Why not post something yourself?
                </p>
              </div>
            ) : (
              <InfiniteScroll
                dataLength={posts.length}
                next={() => {
                  console.log(
                    "next invoked with data: ",
                    section,
                    filter,
                    page,
                    selectedChannelId
                  );
                  dispatch(getPosts({ section, filter, navigate }));
                }}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                className="w-full flex flex-col items-center gap-4 mt-2 mb-10 px-2"
              >
                {posts &&
                  posts.map((post) => (
                    <Post
                      key={post.id}
                      post={post}
                      section={section}
                      handleDelete={() => {
                        setDeletePostWithId(post.id);
                      }}
                      commentsButtonDisabled={false}
                      display={"preview"}
                    />
                  ))}
              </InfiniteScroll>
            )}
          </div>

          <div
            className={`w-full max-w-[500px]  xl:w-1/4 xl:max-w-[300px] h-fit xl:max-h-[70vh] xl:sticky top-40 overflow-y-auto  xl:z-30 right-5 flex flex-col 
            justify-center mx-auto xl:mx-0  gap-3 mb-10`}
          >
            {section === "community" ? (
              channels && (
                <>
                  <div className="bg-white shadow-xl p-3.5 border-2 border-gray-200 rounded-xl h-fit flex flex-col gap-4 overflow-y-auto">
                    <div className="flex flex-col gap-2">
                      <p className="text-lg font-bold text-slate-700 text-center">
                        Community description
                      </p>
                      <p className="text-gray-500 text-center text-sm font-semibold">
                        View posts based on channels and filters or submit your
                        own post using the "New Post" button at the top right.
                        <br></br>Everyone is welcome and encouraged to
                        participate in this non judgemental, supportive
                        environment🙌
                      </p>
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="text-lg font-bold text-slate-700 text-center">
                        Members
                      </p>
                      <p
                        className="text-gray-500 text-xl font-bold text-center"
                        style={{ fontFamily: "sans-serif" }}
                      >
                        {members}
                      </p>
                    </div>
                  </div>
                  {/* <div className="bg-gray-50 rounded-xl  bg-white border-2 border-gray-200 shadow-xl p-3.5 h-fit  flex flex-col gap-3 overflow-y-auto">
                 
                    <div className="flex flex-col gap-1">
                      {channels.map((channel, index) => (
                        <div
                          className="w-full flex flex-col items-start justify-start gap-2 hover:cursor-pointer "
                          onClick={() => {
                            if (channel.id !== selectedChannelId) {
                              handleChangeChannel(channel.id);
                            }
                          }}
                        >
                          <p
                            className={`text-center text-sm font-bold rounded-lg px-2 py-1  ${
                              selectedChannelId === channel.id
                                ? " bg-primary-color  text-white "
                                : "  bg-transparent text-gray-600 hover:bg-primary-color hover:text-white "
                            }`}
                          >
                            {channel.title}
                          </p>
                          {selectedChannelId === channel.id && (
                            <p
                              className={`text-gray-500 text-sm font-semibold ml-3 mb-2`}
                            >
                              {channel.description}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  </div> */}
                </>
              )
            ) : (
              <div className="flex-1 flex flex-col justify-center items-start w-full gap-2">
                <div className="w-full bg-white shadow-xl p-3.5 border-2 border-gray-200 rounded-xl h-fit flex flex-col gap-1 overflow-y-auto">
                  <p className="text-lg font-bold text-slate-700 text-center mb-4">
                    Friends
                  </p>
                  {friends && friends.length > 0 ? (
                    friends.slice(0, 5).map((friend) => {
                      // const levelMap = calculateLevel(friend.points);
                      return (
                        <Link
                          className="rounded-lg flex flex-row gap-1.5 justify-start items-center hover:italic text-slate-500 hover:text-primary-color hover:cursor-pointer w-full"
                          to={`/profile/${friend.username}`}
                        >
                          <div className="rounded-full w-10 h-10 shadow-sm">
                            {friend.image ? (
                              <img
                                className="w-10 h-10 rounded-full border-2 object-cover"
                                src={friend.image}
                              />
                            ) : (
                              <IoPersonCircleSharp
                                className="fill-gray-300"
                                size={40}
                              />
                            )}
                          </div>
                          <div className="flex flex-col">
                            <p className="text-base font-semibold text-slate-600">
                              {friend.username}
                            </p>
                            {/* <p className="font-semibold text-xs text-slate-400">
                              Level {levelMap.level}
                            </p> */}
                          </div>
                        </Link>
                      );
                    })
                  ) : (
                    <p className="font-bold text-base text-slate-700">
                      You don't have any friends yet! You can find others by
                      searching using @ followed by their username, for example
                      @friengle
                    </p>
                  )}
                  {friends && friends.length > 5 && (
                    <Link
                      className="self-end font-extrabold text-slate-500 text-xs hover:text-primary-color flex mt-3"
                      to={`/profile/${username}`}
                    >
                      View All
                      <MdKeyboardArrowRight
                        size={15}
                        className="fill-slate-600"
                      />
                    </Link>
                  )}
                </div>

                {/* <div className="w-full border-2 border-amber-400 rounded-lg"></div> */}
                <div className="w-full bg-white shadow-xl p-3.5 border-2 border-gray-200 rounded-xl h-fit flex flex-col gap-2 overflow-y-auto">
                  <p className="font-semibold text-sm text-green-700 pb-2">
                    Why not introduce yourself to the community or invite your
                    friends to create an account?
                  </p>
                  <button
                    className="text-start italic text-gray-500 hover:italic hover:text-primary-color font-bold text-sm"
                    onClick={() => {
                      setNewPostContent({
                        title: "Excited to Meet Everyone! 👋",
                        content:
                          "Hey everyone! New here and looking forward to meeting new people. Feel free to say hi! ",
                        section: "community",
                      });
                      setShowPostModal(true);
                    }}
                  >
                    Introduce yourself👋
                  </button>
                  <button
                    onClick={async () => {
                      await navigator.clipboard.writeText(
                        `${window.location.origin}/register`
                      );
                      toast.success("Link copied! Share with your friends");
                    }}
                    className="text-start italic text-gray-500 hover:italic hover:text-primary-color font-bold text-sm"
                  >
                    Invite Friends✉️
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="flex flex-col-reverse xl:flex-row gap-4 w-full h-full">
          <div className="flex-1">
            {Array.from({ length: 5 }).map((_, index) => (
              <div key={index} className="w-full mb-6 p-4 bg-white">
                <div className="flex items-center mb-4">
                  <Skeleton
                    // enableAnimation={false}
                    circle={true}
                    height={60}
                    width={60}
                    // baseColor="#e3e3e3"
                    // baseColor={baseColor}
                    // highlightColor={highlightColor}
                  />
                  <div className="ml-4 flex-1">
                    <Skeleton
                      width="15%"
                      height={15}
                      // baseColor={baseColor}
                      // highlightColor={highlightColor}
                    />
                    <Skeleton
                      width="7%"
                      height={13}
                      className="mt-2"
                      // baseColor={baseColor}
                      // highlightColor={highlightColor}
                    />
                  </div>
                </div>
                <Skeleton count={1} height={20} className="mb-5" />
                <Skeleton count={2} height={15} />
              </div>
            ))}
          </div>

          <div
            className={`w-full max-w-[500px]  xl:w-1/4 xl:max-w-[300px] h-fit xl:max-h-[70vh] xl:sticky top-40 overflow-y-auto  xl:z-30 right-5 flex flex-col 
            justify-center mx-auto xl:mx-0  gap-3 mb-10`}
          >
            {section === "community" ? (
              <>
                <div className="bg-white shadow-xl p-3.5 border-2 border-gray-200 rounded-xl h-fit flex flex-col w-full gap-4 overflow-y-auto">
                  <Skeleton
                    containerClassName="w-1/2 self-center"
                    count={1}
                    height={15}
                  />
                  <div className="flex flex-col items-center justify-start gap-1">
                    {Array.from({ length: 3 }).map((_, index) => (
                      <Skeleton
                        containerClassName="w-3/4"
                        count={1}
                        height={15}
                      />
                    ))}
                  </div>
                  <Skeleton
                    containerClassName="w-1/2 self-center"
                    count={1}
                    height={15}
                  />
                  <Skeleton
                    containerClassName="w-1/4 self-center"
                    count={1}
                    height={15}
                  />
                </div>
                <div className="bg-white shadow-xl p-3.5 border-2 border-gray-200 rounded-xl h-fit flex flex-col gap-2 overflow-y-auto">
                  {Array.from({ length: 4 }).map((_, index) => (
                    <Skeleton
                      containerClassName="w-3/4"
                      count={1}
                      height={15}
                    />
                  ))}
                </div>
              </>
            ) : (
              <>
                <div className="bg-white shadow-xl p-3.5 border-2 border-gray-200 rounded-xl h-fit flex flex-col gap-2 overflow-y-auto">
                  <Skeleton
                    containerClassName="w-1/2 self-center mb-3"
                    count={1}
                    height={18}
                  />
                  {Array.from({ length: 3 }).map((_, index) => (
                    <div className="ml-2 flex flex-row items-center justify-start gap-1">
                      <Skeleton
                        circle={true}
                        count={1}
                        height={30}
                        width={30}
                      />
                      <div className="flex flex-col w-full">
                        <Skeleton
                          containerClassName="w-4/6"
                          count={1}
                          height={15}
                        />
                        <Skeleton
                          containerClassName="w-1/4"
                          count={1}
                          height={15}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="bg-white shadow-xl p-3.5 border-2 border-gray-200 rounded-xl h-fit flex flex-col gap-2 overflow-y-auto">
                  {Array.from({ length: 3 }).map((_, index) => (
                    <Skeleton
                      containerClassName="w-3/4"
                      count={1}
                      height={15}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <NewPost
        section={newPostContent?.section ?? section}
        show={showPostModal}
        setShow={setShowPostModal}
        username={username}
        content={newPostContent}
        setContent={setNewPostContent}
      />
      <DeleteModal
        show={deletePostWithId !== null}
        setShow={(_) => {
          setDeletePostWithId(null);
        }}
        item_id={deletePostWithId}
        cb={() => {
          dispatch(removePost(deletePostWithId));
        }}
        type={"post"}
      />
    </div>
  );
};

export default Home;
