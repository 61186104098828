import React, { useState, useEffect } from "react";
import "./GoalPost.css";
import { GoGoal } from "react-icons/go";
import { formatDate, request } from "../../util/util";

// const GoalPost = ({ data, tasks }) => {
const GoalPost = ({ data, display, navigate = null, dispatch = null }) => {
  const {
    goal_id,
    goal_title,
    description,
    goal_type,
    is_shared,
    deadline,
    start_date,
    countinggoal_count,
    countinggoal_total,
  } = data;
  // const [viewTasks, setViewTasks] = useState(
  //   display === "complete" ? true : false
  // );
  const [viewTasks, setViewTasks] = useState(true);

  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { res, status } = await request("GET", `goal/tasks/${goal_id}`);
      if (status) {
        setTasks(res);
      }
    };

    if (viewTasks && tasks.length === 0) {
      fetchData();
    }
  }, [viewTasks, tasks.length]);

  return (
    <div className="border-2 border-gray-100 bg-slate-50 bg-opacity-50  shadow-md rounded-md py-5 px-2 w-full h-full min-h-[120px] relative flex flex-col justify-center items-center gap-2 self-center mt-2">
      <div
        className={`flex flex-col items-center absolute ${
          is_shared ? "-translate-y-1/4" : "-translate-y-1/2"
        } top-0 left-1`}
      >
        <GoGoal className=" fill-slate-600 size-8" />
        {/* {is_shared && (
          <p className="text-xs text-slate-700 font-bold self-start">Shared</p>
        )} */}
      </div>
      <div className="flex flex-col w-full mt-1 mb-3 items-center">
        <p className="text-lg font-bold text-gray-600 w-full md:w-4/6 text-center">
          {goal_title}
        </p>
        {description && (
          <p className="text-sm font-regular text-slate-600 w-full md:w-5/6  text-center">
            {description}
          </p>
        )}
      </div>

      <div className="flex flex-col lg:flex-row gap-1 lg:gap-4 text-xs text-black self-center">
        <p className="text-center">
          <span className="font-semibold">Started: </span>
          <span className="font-regular">{formatDate(start_date, true)}</span>
        </p>
        {deadline && (
          <p className="text-center">
            <span className="font-semibold">Deadline: </span>
            <span className="font-regular">{formatDate(deadline, true)}</span>
          </p>
        )}
      </div>
      {goal_type === "counting" ? (
        <>
          <p className="text-md font-semibold">
            <span className="text-primary-color">{countinggoal_count}</span>/
            {countinggoal_total}
          </p>
        </>
      ) : (
        goal_type === "milestone" && (
          <div className="self-start w-full">
            {/* <button
              type="button"
              className="text-primary-color text-sm font-bold"
              onClick={() => setViewTasks(!viewTasks)}
            >
              {viewTasks ? "Hide Tasks" : "View Tasks"}
            </button> */}
            {viewTasks && (
              <div className="mt-3 bg-[rgba(0,0,0,0.01)] rounded-md w-full flex flex-col gap-2">
                {tasks.map((task, index) => {
                  return (
                    <p
                      className={`text-md font-regular pl-3 py-1 mx-1 ${
                        task.completion_date
                          ? "line-through text-gray-400 "
                          : " text-black "
                      } ${
                        index + 1 != tasks.length
                          ? " border-b-[1px] border-gray-200 "
                          : ""
                      }`}
                    >
                      {task.task}
                    </p>
                  );
                })}
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default GoalPost;
