import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { request } from "../util/util";
import { toast } from "react-toastify";

const initialState = {
  goals: [],
  categories: [],
  types: ["basic", "milestone", "counting"],
  fetchedData: false,
};

// export const fetchGoalTypes = createAsyncThunk(
//   "goal/fetchTypes",
//   async ({ navigate }, { dispatch }) => {
//     // console.log(`${BASE_URL}/goal/types`);
//     // const jsonResponse = await fetch(`${BASE_URL}/goal/types`);
//     // const response = await jsonResponse.json();
//     // return response;
//     const { res, msg, status } = await request("GET", `goal/types`);
//   }
// );

export const fetchCategories = createAsyncThunk(
  "goal/fetchCategories",
  async (_, { rejectWithValue }) => {
    const { res, msg, status } = await request("GET", `goal/goal-categories`);
    if (status) {
      return res;
    } else {
      return rejectWithValue(msg);
    }
  }
);
export const fetchGoals = createAsyncThunk(
  "goal/fetchData",
  async (params, { rejectWithValue }) => {
    const { res, msg, status } = await request(
      "GET",
      `goal/goals${params.status ? `/${params.status}` : "in progress"}${
        params.category_id ? `/${params.category_id}` : ""
      }`
    );
    if (status) {
      return res;
    } else {
      return rejectWithValue(msg);
    }
  }
);

// export const deleteGoal = createAsyncThunk(
//   "goal/deleteGoal",
//   async ({ goal }, { rejectWithValue }) => {
//     // console.log("in handledelete");
//     // let id = goal.type;
//     // let url;
//     // if (id === 1) {
//     //   url = "basic";
//     // } else if (id === 2) {
//     //   url = "milestone";
//     // } else if (id === 3) {
//     //   url = "counting";
//     // }
//     //---------------------
//     // let url = `${goal.type}/${goal.id}`;
//     // console.log(`fetch delete:${BASE_URL}/goal/delete/${url}`);
//     // await fetch(`${BASE_URL}/goal/delete/${url}`, {
//     //   method: "DELETE",
//     //   credentials: "include",
//     // });
//     // return goal.id;
//     const { res, msg, status } = await request(
//       "DELETE",
//       `goal/delete/${goal.id}`
//     );
//     if (status) {
//       return goal.id;
//     } else {
//       rejectWithValue(msg);
//     }
//   }
// );

export const addGoal = createAsyncThunk(
  "goal/addGoal",
  async ({ goal }, { rejectWithValue, getState }) => {
    const { res, msg, status } = await request("POST", `goal/new`, goal, true);
    if (status) {
      const state = getState();
      const user = state.user;
      const goal_participants = goal.selectedFriends ?? [];
      goal_participants.push({
        id: 41,
        image: user.image,
        can_edit: true,
        username: user.username,
        is_accountability_partner: false,
      });
      return {
        ...goal,
        id: res.id,
        image: res.image,
        title: goal.title,
        type: goal.type,
        is_shared: goal.is_shared,
        start_date: new Date().toISOString(),
        deadline: goal.deadline ? goal.deadline : null,
        count: 0,
        status: goal.status,
        total: goal.total ? parseInt(goal.total, 10) : null,
        goal_participants,
        category_id: goal.goal_category_id
          ? parseInt(goal.goal_category_id, 10)
          : null,
      };
    } else {
      return rejectWithValue(msg);
    }
  }
);

export const editGoalAction = createAsyncThunk(
  "goal/editGoal",
  async ({ updatedValues, editStateData }, { rejectWithValue }) => {
    console.log(`edit goal ${updatedValues}`);
    const { res, msg, status } = await request(
      "PUT",
      `goal/edit`,
      updatedValues,
      true
    );
    if (status) {
      if (res["image"] !== null) {
        updatedValues["image"] = res["image"];
      }
      return { ...updatedValues, previousData: editStateData };
    } else {
      return rejectWithValue(msg);
    }
  }
);

export const addGoalCategory = createAsyncThunk(
  "goal/addGoalCategory",
  async (reqBody, { rejectWithValue }) => {
    const { res, msg, status } = await request(
      "POST",
      `goal/category/new`,
      reqBody
    );
    if (status) {
      console.log("res ", res);
      return res;
    } else {
      return rejectWithValue(msg);
    }
  }
);
export const editGoalCategory = createAsyncThunk(
  "goal/editGoalCategory",
  async (reqBody, { rejectWithValue }) => {
    const { res, msg, status } = await request("PUT", `goal/category`, reqBody);
    if (status) {
      console.log("res ", res);
      return reqBody;
    } else {
      return rejectWithValue(msg);
    }
  }
);

export const goalSlice = createSlice({
  name: "goal",
  initialState,
  reducers: {
    //action.payload
    // setCurrentEntry: (state, action) => {},
    setCount: (state, action) => {
      const { goal_id, newCount } = action.payload;
      const goalToUpdate = state.goals.find((goal) => goal.id === goal_id);
      if (goalToUpdate) {
        // Use immer to mutate the existing state safely
        goalToUpdate.count = newCount;
      }
    },
    removeCategory: (state, action) => {
      const id = action.payload;
      if (state.categories && state.categories.length > 0) {
        state.categories = state.categories.filter(
          (category) => category.id !== id
        );
      }
    },
    setFetchedData: (state, action) => {
      state.fetchedData = action.payload;
    },
    resetState: (state, action) => {
      return initialState;
    },
    changeGoalStatus: (state, action) => {
      state.goals = state.goals.map((goal) => {
        if (goal.id === action.payload.id) {
          return { ...goal, status: action.payload.status };
        }
        return goal;
      });
    },
    setAccountabilityPartners: (state, action) => {
      const { id, newList } = action.payload;
      const goalToUpdate = state.goals.find((goal) => goal.id === id);
      if (goalToUpdate && newList) {
        goalToUpdate.accountability_partners = newList;
      }
    },
    deleteGoal: (state, action) => {
      // Expect action.payload to include both goalId and currentUsername.
      const { goalId, currentUsername } = action.payload;
      // Find the goal to be deleted using its id
      const goalToDelete = state.goals.find((goal) => goal.id === goalId);

      if (goalToDelete) {
        // Helper: safely parse numeric fields so subtraction works correctly
        const parseNum = (val) => Number(val) || 0;

        // Helper: decrement nb_of_goals and the status-specific count for a given category
        function decrementCategoryStatusCount(categoryId, status) {
          console.log(
            "decrementCategoryStatusCount(",
            categoryId,
            ", ",
            status,
            ");"
          );
          if (categoryId == null) {
            console.log("decrementCategoryStatusCount categoryId null");
            return; // guard for null/undefined
          }
          const category = state.categories.find((c) => c.id === categoryId);
          if (!category) {
            console.log("decrementCategoryStatusCount category not found");
            return;
          }
          // Convert string-based fields to numbers before decrementing
          category.nb_of_goals = parseNum(category.nb_of_goals) - 1;
          console.log(
            "decrementCategoryStatusCount nb of goals",
            category.nb_of_goals
          );

          if (status === "pending") {
            category.pending_count = parseNum(category.pending_count) - 1;
            console.log(
              "decrementCategoryStatusCount pending",
              category.pending_count
            );
          } else if (status === "in progress") {
            category.in_progress_count =
              parseNum(category.in_progress_count) - 1;
            console.log(
              "decrementCategoryStatusCount in progress",
              category.in_progress_count
            );
          } else if (status === "completed") {
            category.completed_count = parseNum(category.completed_count) - 1;
            console.log(
              "decrementCategoryStatusCount completed",
              category.completed_count
            );
          }
        }

        // 1) Always decrement the "All" category (id = -3)
        decrementCategoryStatusCount(-3, goalToDelete.status);

        // 2) Process the goal_participants array by checking the current user's participant object
        if (
          goalToDelete.goal_participants &&
          goalToDelete.goal_participants.length > 1
        ) {
          // Find the current user's participant object
          const currentUserParticipant = goalToDelete.goal_participants.find(
            (participant) => participant.username === currentUsername
          );
          if (currentUserParticipant) {
            if (currentUserParticipant.is_accountability_partner) {
              // Decrement accountability category (id = -1)
              decrementCategoryStatusCount(-1, goalToDelete.status);
            } else {
              let participants = goalToDelete.goal_participants.reduce(
                (acc, participant) => {
                  if (!participant.is_accountability_partner) {
                    acc += 1;
                  }
                  return acc;
                },
                0
              );

              if (participants >= 2) {
                decrementCategoryStatusCount(-2, goalToDelete.status);
              }
            }
          }
        }

        // 3) If a custom category was chosen, decrement that category as well
        if (goalToDelete.category_id && Number(goalToDelete.category_id) > 0) {
          decrementCategoryStatusCount(
            goalToDelete.category_id,
            goalToDelete.status
          );
        }
      }

      // Finally, remove the goal from the state
      state.goals = state.goals.filter((goal) => goal.id !== goalId);
    },
  },
  extraReducers: (builder) => {
    //fulfilled, rejected,pending
    builder
      // .addCase(fetchGoalTypes.fulfilled, (state, action) => {
      //   state.types = action.payload;
      // })
      .addCase(fetchGoals.fulfilled, (state, action) => {
        state.goals = action.payload;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.categories = action.payload;
      })
      .addCase(addGoal.fulfilled, (state, action) => {
        const newGoal = action.payload;
        state.goals = [newGoal, ...state.goals];
        // Helper: safely parse numeric fields so adding works correctly
        const parseNum = (val) => Number(val) || 0;

        // Helper: increment nb_of_goals and the status-specific count for a given category
        function incrementCategoryStatusCount(categoryId, status) {
          console.log(
            "incrementCategoryStatus(",
            categoryId,
            ", ",
            status,
            ");"
          );
          if (categoryId == null) {
            console.log("incrementCategoryStatusCount categoryid null");
            return; // guard for null/undefined
          }
          const category = state.categories.find((c) => c.id === categoryId);
          if (!category) {
            console.log("incrementCategoryStatusCount category not found");

            return;
          }

          // Convert string-based fields to numbers before incrementing
          category.nb_of_goals = parseNum(category.nb_of_goals) + 1;
          console.log(
            "incrementCategoryStatusCount nb of goals ",
            category.nb_of_goals
          );

          if (status === "pending") {
            category.pending_count = parseNum(category.pending_count) + 1;
            console.log(
              "incrementCategoryStatusCount pending ",
              category.pending_count
            );
          } else if (status === "in progress") {
            category.in_progress_count =
              parseNum(category.in_progress_count) + 1;
            console.log(
              "incrementCategoryStatusCount inprogress",
              category.in_progress_count
            );
          } else if (status === "completed") {
            category.completed_count = parseNum(category.completed_count) + 1;
            console.log(
              "incrementCategoryStatusCount completed",
              category.completed_count
            );
          }
        }

        // 2) Always increment the "All" category (id = -3) for a newly created goal
        incrementCategoryStatusCount(-3, newGoal.status);

        // 3) If selectedFriends is non-empty, it means the goal is "shared"
        if (newGoal.selectedFriends && newGoal.selectedFriends.length > 0) {
          incrementCategoryStatusCount(-2, newGoal.status); // "Shared" category
        }

        // 4) If a custom category is chosen (goal_category_id), increment that as well
        if (newGoal.category_id && Number(newGoal.category_id) > 0) {
          incrementCategoryStatusCount(newGoal.category_id, newGoal.status);
        }

        toast.success("Added Goal", { position: "top-center" });
      })
      .addCase(addGoal.rejected, (state, action) => {
        toast.error("Error", { position: "top-center" });
      })
      .addCase(editGoalAction.fulfilled, (state, action) => {
        const updatedGoal = action.payload;

        const prevGoalParticipants = state.goals.find(
          (goal) => goal.id === updatedGoal.id
        )?.goal_participants;

        let newCategoryId = null;
        if (updatedGoal.goal_category_id !== undefined) {
          newCategoryId = Number(updatedGoal.goal_category_id);
        }

        const normalizedGoal = {
          ...updatedGoal,
          goal_participants: updatedGoal.goal_participants
            ? {
                ...(updatedGoal.goal_participants.added || []).map((p) => ({
                  ...p,
                  is_accountability_partner:
                    p.role === "accountability partner",
                })),
                ...(updatedGoal.goal_participants.updated || []).map((p) => ({
                  ...p,
                  is_accountability_partner:
                    p.role === "accountability partner",
                })),
              }
            : prevGoalParticipants,
        };
        if (newCategoryId) {
          normalizedGoal.category_id = newCategoryId > 0 ? newCategoryId : "";
        }

        const goalIndex = state.goals.findIndex(
          (goal) => goal.id === normalizedGoal.id
        );

        if (goalIndex !== -1) {
          state.goals[goalIndex] = {
            ...state.goals[goalIndex],
            ...normalizedGoal,
          };
        }

        const previousData = updatedGoal["previousData"];

        console.log("previous data", previousData);
        if (previousData && Object.keys(previousData).length > 0) {
          const {
            previousCategoryId,
            previousStatus,
            incrementAccountabilityCategory,
            decrementAccountabilityCategory,
          } = previousData;

          const newStatus = updatedGoal.status;
          const goal = state.goals[goalIndex];

          // Helper to safely parse numeric fields stored as strings
          const parseNum = (val) => Number(val) || 0;

          // Helper to update counts for a given category ID and status
          function updateCategoryCounts(categoryId, status, increment = true) {
            console.log(
              "update category counts with category Id: ",
              categoryId,
              ", type: ",
              typeof categoryId,
              ",status: ",
              status,
              ", increment: ",
              increment
            );
            if (categoryId == null) return; // guard for null/undefined
            const cat = state.categories.find((c) => c.id === categoryId);
            if (!cat) return;

            const nbOfGoals = parseNum(cat.nb_of_goals);
            const completedCount = parseNum(cat.completed_count);
            const inProgressCount = parseNum(cat.in_progress_count);
            const pendingCount = parseNum(cat.pending_count);

            // increment ? +1 : -1
            const delta = increment ? 1 : -1;

            // Update overall goal count in this category
            cat.nb_of_goals = nbOfGoals + delta;

            // Update the status-specific count
            if (status === "completed") {
              cat.completed_count = completedCount + delta;
            } else if (status === "in progress") {
              cat.in_progress_count = inProgressCount + delta;
            } else if (status === "pending") {
              cat.pending_count = pendingCount + delta;
            }
          }

          // 1) If the category changed, decrement old cat+status, increment new cat+status
          if (
            previousCategoryId &&
            newCategoryId &&
            previousCategoryId !== newCategoryId
          ) {
            if (previousCategoryId !== "" && Number(previousCategoryId) > 0) {
              updateCategoryCounts(
                previousCategoryId,
                previousStatus ?? goal.status,
                false
              );
            }

            if (newCategoryId && newCategoryId > 0)
              updateCategoryCounts(
                newCategoryId,
                newStatus ?? goal.status,
                true
              );
          }
          // Otherwise if only the status changed (same category)
          else if (
            previousStatus &&
            newStatus &&
            previousStatus !== newStatus &&
            goal.category_id
          ) {
            updateCategoryCounts(goal.category_id, previousStatus, false);
            updateCategoryCounts(goal.category_id, newStatus, true);
          }

          // 2) Update the "All" category (-3) if status changed
          if (previousStatus && newStatus && previousStatus !== newStatus) {
            updateCategoryCounts(-3, previousStatus, false);
            updateCategoryCounts(-3, newStatus, true);
          }

          // 3) Shared category logic: if participants changed from 1 -> >1 or >1 -> 1
          const oldParticipantsCount = Array.isArray(prevGoalParticipants)
            ? prevGoalParticipants.length
            : 0;
          const newParticipants = Array.isArray(updatedGoal.goal_participants)
            ? updatedGoal.goal_participants
            : [];
          const newParticipantsCount = newParticipants.length;

          if (oldParticipantsCount === 1 && newParticipantsCount > 1) {
            // Goal was previously personal, now shared
            updateCategoryCounts(-2, newStatus ?? goal.status, true);
          } else if (oldParticipantsCount > 1 && newParticipantsCount === 1) {
            // Goal was shared, now personal
            updateCategoryCounts(-2, newStatus ?? goal.status, false);
          }

          // 4) Accountability category logic: if user changed roles
          //    and is now or is no longer an accountability partner
          if (incrementAccountabilityCategory) {
            // We moved into accountability partner role
            updateCategoryCounts(-1, newStatus ?? goal.status, true);
          }

          if (decrementAccountabilityCategory) {
            // We moved out of accountability partner role
            updateCategoryCounts(-1, newStatus ?? goal.status, false);
          }
        }

        toast.success("Edit Successful", { position: "top-center" });
      })
      .addCase(editGoalAction.rejected, (state, action) => {
        toast.error("Error", { position: "top-center" });
      })
      .addCase(addGoalCategory.fulfilled, (state, action) => {
        state.categories = [
          ...state.categories,
          {
            ...action.payload,
            nb_of_goals: "0",
            completed_count: "0",
            in_progress_count: "0",
            pending_count: "0",
          },
        ];
        toast.success("Added Category", { position: "top-center" });
      })
      .addCase(editGoalCategory.fulfilled, (state, action) => {
        const { id, category } = action.payload;
        const index = state.categories.findIndex(
          (category) => category.id === id
        );

        if (index !== -1) {
          state.categories[index]["category"] = category;
          toast.success("Category Updated", { position: "top-center" });
        } else {
          state.categories.push(action.payload);
          toast.success("Added Category", { position: "top-center" });
        }
      });
  },
});

//SELECTOR
export const selectTypes = (state) => state.goal.types;
export const selectGoals = (state) => state.goal.goals;
export const selectFetchedData = (state) => state.goal.fetchedData;
export const selectCategories = (state) => state.goal.categories;

export const {
  setCount,
  setFetchedData,
  resetState,
  changeGoalStatus,
  setAccountabilityPartners,
  deleteGoal,
  removeCategory,
} = goalSlice.actions;
export default goalSlice.reducer;
