import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Post from "../../components/Post/Post";
import { AiFillDelete } from "react-icons/ai";
import { FaCaretDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUsername,
  selectRole,
  selectProfileInfo,
} from "../../features/userSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import "./Comments.css";
import { Helmet } from "react-helmet-async";
import { genTitle, request } from "../../util/util";
import SEO from "../../components/SEO";
import Comment from "../../components/Comment";
import { IoArrowBack } from "react-icons/io5";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import CommentInput from "../../components/CommentInput";
import DeleteModal from "../../components/DeleteModal";
import { removePost } from "../../features/socialSlice";
import { OrbitProgress } from "react-loading-indicators";
import { MdError } from "react-icons/md";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Comments = () => {
  const navigate = useNavigate();
  const [deletePost, setDeletePost] = useState(false);

  const { postId, postTitle } = useParams();
  const [comments, setComments] = useState([]);
  const [post, setPost] = useState({});
  const username = useSelector(selectUsername);
  const profileInfo = useSelector(selectProfileInfo);
  const role = useSelector(selectRole);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [error, setError] = useState(false);
  const [submittingComment, setSubmittingComment] = useState(false);
  const dispatch = useDispatch();
  // const page = "community";
  // const postState = { id: 80 };
  // const username = "test";
  const fetchPostDetails = async () => {
    setLoading(true);
    let postRes = await fetch(`${BASE_URL}/posts/${postId}`, {
      credentials: "include",
    });
    if (postRes.ok) {
      let post = await postRes.json();
      // console.log(jsonRes);
      setPost(post.post);
      // if (!postTitle) {
      //   window.history.pushState(
      //     {},
      //     "",
      //     `/${post.post.caption}`
      //   );
      // }
      if (!postTitle) {
        navigate(`/comments/${postId}/${genTitle(post.post.title)}`, {
          replace: true,
        });
      }
      // console.log(res.post);
      getComments();
    } else {
      setError(true);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchPostDetails();
  }, [postId]);

  const getComments = async () => {
    let res = await fetch(`${BASE_URL}/posts/comments/${postId}?page=${page}`, {
      credentials: "include",
    });
    if (res.ok) {
      let data = await res.json();
      setComments([...comments, ...data.comments]);
      if (data.comments.length < 10) {
        setHasMore(false);
      }
      setPage(page + 1);
      // console.log(data.comments);
    } else {
      setError(true);
    }
  };

  const handleComment = async (e, input, setInput) => {
    e.preventDefault();
    if (!username) {
      alert("Log in first!");
      return;
    }
    if (input.trim() !== "") {
      setSubmittingComment(true);
      const res = await request("POST", `posts/comment/${postId}`, {
        comment: input,
        author_id: post.author_id,
        post_title: post.title,
      });

      if (res.status) {
        setComments((prevComments) => [
          {
            id: res.res,
            username: username,
            image: profileInfo.image,
            comment: input,
            timestamp: new Date().toUTCString(),
          },
          ...prevComments,
        ]);
        setInput("");
      } else {
        toast.error("Error");
      }
      setSubmittingComment(false);
    }
  };

  const handleDeletePost = async () => {};

  const handleCommentDelete = async (comment_id) => {
    fetch(`${BASE_URL}/posts/deletecomment/${comment_id}`, {
      method: "DELETE",
      credentials: "include",
    }).then(() => {
      setComments(comments.filter((comment) => comment.id !== comment_id));
    });
  };

  if (loading) {
    return (
      <div className="w-full h-full flex items-start justify-center pt-[10vh]">
        <OrbitProgress
          color="#32cd32"
          size="medium"
          text=""
          textColor=""
          style={{ fontSize: "10px" }}
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full h-full flex flex-col gap-4 items-center justify-start pt-[10vh]">
        <div className="flex flex-row gap-2 items-center">
        <MdError className="text-primary-color" size={50}/>
        <p className="text-3xl font-bold text-gray-500">An Error Occurred</p>
        </div>
        <button
          onClick={() => fetchPostDetails()}
          className="bg-primary-color text-white font-bold text-xl px-14 py-2 rounded-lg border-2 border-primary-color hover:bg-white hover:text-primary-color transition-colors duration-300"
        >
          Retry
        </button>
      </div>
    );
  }

  function createMetaDescription(post) {
    if (post.body.length > 0) {
      let cutoff = post.body.indexOf(".", 150);
      if (cutoff === -1 || cutoff > 160) {
        cutoff = 157;
      }
      return post.body.substring(0, cutoff + 1);
    } else {
      return post.title;
    }
  }

  function handleBack() {
    if (location.key === "default") {
      // No previous history within the app, navigate to a default route
      navigate("/social?section=community");
    } else {
      navigate(-1);
    }
  }

  return (
    <div className="flex flex-col gap-6 pb-14">
      {/* <Helmet>
        <title>{post.title} - Friengle</title>
        <meta name="description" content={summarizedDescription} />
      </Helmet> */}
      <SEO title={`${post.title}`} description={createMetaDescription(post)} />
      {/* <Post
        commentsButtonDisabled={true}
        handleDelete={handleDelete}
        username={username}
        post={{
          id: 82,
          caption: "habit post",
          post_date: "2023-12-31T19:52:16.785Z",
          likes: "1",
          username: "test",
          liked: true,
          trend_score: "1",
          journal_data: null,
          goal_data: null,
          milestonegoal_tasks: [null],
          habit_data: null,
        }}
        display={"complete"}
      /> */}
      <button
        className="bg-gray-200 hover:bg-gray-300 duration-300 rounded-full p-2 w-fit"
        onClick={() => handleBack()}
      >
        <IoArrowBack className="text-black" />
      </button>
      <Post
        commentsButtonDisabled={true}
        handleDelete={() => setDeletePost(true)}
        post={post}
        display={"complete"}
      />

      <div className="">
        <CommentInput
          disabled={submittingComment}
          handleComment={handleComment}
        />
        <InfiniteScroll
          dataLength={comments.length}
          next={() => getComments()}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          className="flex flex-col gap-3 mt-7"
        >
          {comments.map((comment) => (
            <Comment
              comment={comment}
              profileInfo={profileInfo}
              role={role}
              handleCommentDelete={handleCommentDelete}
            />
          ))}
        </InfiniteScroll>
      </div>

      <DeleteModal
        show={deletePost}
        setShow={setDeletePost}
        item_id={postId}
        cb={() => {
          dispatch(removePost(parseInt(postId, 10)));
          handleBack();
        }}
        type={"post"}
      />
    </div>
  );
};

export default Comments;
