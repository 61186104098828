import { combineReducers, configureStore } from "@reduxjs/toolkit";
import journalReducer from "../features/journalSlice";
import goalReducer from "../features/goalSlice";
import habitReducer from "../features/habitSlice";
import userReducer from "../features/userSlice";
import friendsReducer from "../features/friendsSlice";
import notificationsReducer from "../features/notificationsSlice";
import socialReducer from "../features/socialSlice";

const rootReducer = combineReducers({
  user: userReducer,
  journal: journalReducer,
  goal: goalReducer,
  habit: habitReducer,
  friends: friendsReducer,
  notifications: notificationsReducer,
  social:socialReducer
});

export const store = configureStore({
  reducer: (state, action) =>
    rootReducer(action.type === "USER_LOGOUT" ? undefined : state, action),
});
