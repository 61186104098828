import React, { useState, useEffect } from "react";
import "./About.css";
import { Link, useNavigate } from "react-router-dom";
import AuthHeader from "../../components/AuthHeader/AuthHeader";
import aboutImg from "../../assets/images/about.jpg";
import communityExample from "../../assets/images/social-feed-example.png";
import goalsExample from "../../assets/images/goals-example.png";
import updatesExample from "../../assets/images/updates-example.png";
// import { Helmet } from "react-helmet-async";
import SEO from "../../components/SEO";
import SocialLinks from "../../components/SocialLinks";
import { selectLoggedIn } from "../../features/userSlice";
import { useSelector } from "react-redux";
import { encode as base64_encode } from "base-64";
import {
  FiCheckCircle,
  FiTrendingUp,
  FiClipboard,
  FiFlag,
  FiAward,
  FiUsers,
  FiShare2,
  FiMessageCircle,
  FiTarget,
  FiArrowLeft,
  FiArrowRight,
  FiStar,
  FiUserCheck,
} from "react-icons/fi";
import { request } from "../../util/util";
import FadeInSection from "../../components/FadeInSection";

const items = [
  {
    icon: FiTarget,
    title: "Goal Tracking",
    text: "Track your progress effortlessly.",
  },
  {
    icon: FiUsers,
    title: "Shared Goals",
    text: "Add friends to your goals.",
  },
  {
    icon: FiUserCheck,
    title: "Accountability Partners",
    text: "Set accountability partners for a goal.",
  },
  {
    icon: FiShare2,
    title: "Social Feed",
    text: "Share updates, milestones, and insights.",
  },
  {
    icon: FiMessageCircle,
    title: "Community",
    text: "Dive into discussions covering personal growth and goal achievement.",
  },
];
const About = () => {
  const loggedIn = useSelector(selectLoggedIn);

  const navigate = useNavigate();

  const textClasses = "text-black text-md mt-2";
  const textFont = "font-regular";
  const emphasizedTextClasses =
    "text-secondary-color text-md lg:text-lg mb-2 font-bold";
  const h2Classes = "text-2xl font-semiBold mb-5";

  if (loggedIn) navigate("/social");

  return (
    <>
      {/* <Helmet>
        <title></title>
        <meta
          name="description"
          content="Learn how Friengle helps individuals and communities achieve their goals through social support and innovative tracking tools. Discover our mission and values."
        />
      </Helmet> */}
      <SEO
        title={"Friengle"}
        description={
          "Learn how Friengle helps individuals and communities achieve their goals through social support and innovative tracking tools. Discover our mission and values."
        }
      />
      <AuthHeader page="about" />
      <div className="flex w-full justify-center items-center bg-gradient-to-b from-white via-white to-primary-color/10">
        <div className="h-fit lg:h-[calc(100vh-60px)] flex flex-col lg:flex-row justify-center items-center w-full gap-5 lg:gap-20 lg:w-[85vw] px-5 lg:px-20 py-10">
          <FadeInSection>
            <div className="flex flex-col items-center justify-center lg:justify-start lg:items-start gap-3 lg:gap-16">
              <h1
                className="text-4xl lg:text-6xl underline text-primary-color"
                style={{ fontFamily: "dancing-script" }}
              >
                Friengle
              </h1>
              <p className="italic !leading-[1.5] text-black text-2xl lg:text-3xl lg:text-start text-center font-medium">
                Ever set goals, only to lose motivation and watch your progress
                slip away? Staying consistent is tough, and without the right
                support, it’s easy to fall off track, miss opportunities, and
                watch the life you envision drift further out of reach. Friengle
                helps you stay accountable and keep moving forward—so you don’t
                just set goals, you achieve them.
              </p>
            </div>
          </FadeInSection>
          <FadeInSection>
            <div className="lg:basis-[30%] flex justify-center rounded-full bg-primary-color bg-opacity-80 p-10 shadow-lg">
              <svg
                viewBox="0 0 120 120"
                xmlns="http://www.w3.org/2000/svg"
                className="h-[10vh] lg:h-[35vh] min-h-[150px] lg:rotate-[15deg]"
              >
                <g>
                  <path
                    fill="#FFC54D"
                    d="M101,34l-0.2-1.7h-10c0.5-3.4,0.8-6.9,1-10.5c0.1-1.9-1.4-3.5-3.1-3.5H31.4c-1.8,0-3.2,1.6-3.1,3.5
          c0.1,3.6,0.5,7.1,1,10.5h-10L19,34c-0.1,0.4-1.2,10.6,5.4,19.8c4.3,6,11,10.1,19.7,12.2c2.8,2.8,5.9,4.9,9.2,6.2
          c-0.4,4.1-0.9,8.1-1.4,11.8h16.3c-0.6-3.8-1.1-7.7-1.5-11.8c3.3-1.2,6.4-3.3,9.2-6.2c8.7-2.1,15.4-6.2,19.7-12.2
          C102.2,44.6,101,34.4,101,34z M27.3,51.3c-4.2-5.8-4.7-12.1-4.7-15.1h7.3c1.9,9.5,5.3,17.9,9.6,24.2
          C34.3,58.4,30.2,55.3,27.3,51.3z M92.7,51.3c-2.9,4-7,7.1-12.2,9.1c4.4-6.4,7.7-14.7,9.6-24.2h7.3
          C97.4,39.2,96.8,45.5,92.7,51.3z"
                  />
                  <path
                    fill="#C19D72"
                    d="M77,98.1H43c-1,0-1.8-0.8-1.8-1.8V83.5c0-1,0.8-1.8,1.8-1.8h34c1,0,1.8,0.8,1.8,1.8v12.8
          C78.8,97.3,78,98.1,77,98.1z"
                  />
                  <path
                    fill="#A88763"
                    d="M37.9,101.9h44.2c1,0,1.8-0.8,1.8-1.8v-3.8c0-1-0.8-1.8-1.8-1.8H37.9c-1,0-1.8,0.8-1.8,1.8v3.8
          C36.1,101,36.9,101.9,37.9,101.9z"
                  />
                  <path
                    fill="#FFC54D"
                    d="M68,91H52c-0.7,0-1.2-0.5-1.2-1.2v-2.5c0-0.7,0.5-1.2,1.2-1.2h16c0.7,0,1.2,0.5,1.2,1.2v2.5
          C69.2,90.5,68.6,91,68,91z"
                  />
                </g>
              </svg>
            </div>
          </FadeInSection>
        </div>
      </div>

      <div className="fade-in-element flex w-full justify-center items-center bg-gradient-to-b from-primary-color/10 via-primary-color/10 to-white animate-fadeInTop">
        <FadeInSection>
          <div className="flex flex-col-reverse lg:flex-row lg:w-[95vw] justify-around items-center w-full gap-4 lg:gap-10 px-5 lg:px-20 pt-5 pb-10 lg:pb-36">
            <div className="w-fit lg:animate-[subtle-bounce_3s_infinite] ">
              <img
                src={communityExample}
                className="w-full h-full max-h-[500px] object-contain shadow-md lg:rotate-[-5deg]"
              />
            </div>

            <div className="flex flex-col items-center justify-center lg:justify-start lg:items-start gap-3 lg:gap-10">
              <p className="text-black text-2xl lg:text-4xl lg:text-start text-center font-medium">
                Connect with others.
                <br />
                Stay motivated.
                <br />
                Turn progress into momentum.
              </p>
            </div>
          </div>
        </FadeInSection>
      </div>

      <div className="flex w-full justify-center items-center bg-gradient-to-b from-white via-white to-primary-color/10 animate-fadeInTop">
        <FadeInSection>
          <div className="flex flex-col lg:flex-row lg:w-[95vw] justify-around items-center w-full gap-4 lg:gap-10 px-5 lg:px-20 py-10 lg:py-36">
            <div className="flex flex-col items-center justify-center lg:justify-start lg:items-start gap-3 lg:gap-10">
              <p className="text-black text-2xl lg:text-4xl lg:text-start text-center font-medium">
                Set goals, collaborate, chat,
                <br /> and push each other toward success.
              </p>
            </div>
            <div className="w-fit lg:animate-[subtle-bounce_3s_infinite] ">
              <img
                src={goalsExample}
                className="w-full h-full max-h-[500px] object-contain shadow-md lg:rotate-[5deg]"
              />
            </div>
          </div>
        </FadeInSection>
      </div>
      <div className="flex w-full justify-center items-center bg-gradient-to-b from-primary-color/10 via-white to-white">
        <FadeInSection>
          <div className="flex flex-col-reverse lg:flex-row lg:w-[95vw] justify-around items-center w-full gap-4 lg:gap-10 px-5 lg:px-20 pt-5 py-10 lg:py-36">
            <div className="w-fit lg:animate-[subtle-bounce_3s_infinite] ">
              <img
                src={updatesExample}
                className="w-full h-full max-h-[500px] object-contain shadow-md lg:rotate-[-5deg]"
              />
            </div>

            <div className="flex flex-col items-center justify-center lg:justify-start lg:items-start gap-3 lg:gap-10">
              <p className="text-black text-2xl lg:text-4xl lg:text-start text-center font-medium">
                Never fall behind— <br />
                track deadlines,
                <br /> follow friends' progress,
                <br /> and never miss a conversation.
              </p>
            </div>
          </div>
        </FadeInSection>
      </div>
      <div className="flex w-full justify-center items-center pt-10 pb-24 lg:pb-32 bg-gradient-to-b from-white via-primary-color/10 to-primary-color/40 ">
        <FadeInSection>
          <Link
            to={"/register"}
            className=" text-center text-lg lg:text-3xl font-bold bg-primary-color rounded-full px-10 py-2 lg:px-16 lg:py-5 text-white hover:scale-105 transition-transform duration-300"
          >
            Sign Up Now for Free!
          </Link>
        </FadeInSection>
      </div>
    </>
  );
};

export default About;
