import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { request } from "../util/util";
import { toast } from "react-toastify";

const initialState = {
  posts: null,
  page: 1, //10 posts per page
  hasMore: true, //more posts to fetch
  section: "community", //community / friends
  filter: "new", //new - top - trending
  channels: null,
  selectedChannelId: null,
  fetchedInitialData: false,
};

// export const getChannels = createAsyncThunk(
//   "social/getChannels",
//   async (_, { getState, rejectWithValue }) => {
//     try {
//       const { res, status } = await request("GET", "community/channels");
//       if (status) {
//         console.log("get channels ", res);
//         return res;
//       } else {
//         return rejectWithValue([]);
//       }
//     } catch (error) {
//       console.log("error ", error);
//     }
//   }
// );

export const getChannels = createAsyncThunk(
  "social/getChannels",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { res, status } = await request("GET", "community/channels");
      if (status) {
        console.log("get channels ", res);
        return res;
      } else {
        return rejectWithValue([]);
      }
    } catch (error) {
      console.log("error ", error);
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      // Access the relevant slice of state. Adjust the path based on your reducer structure.
      const { channels } = getState().social;
      // Proceed only if channels is null.
      if (channels !== null) {
        // Returning false cancels the thunk.
        return false;
      }
    },
  }
);

export const cleanChannelName = (name) => {
  const normalizedName = name.toLowerCase().replace(/[^a-z0-9]/g, "");
  console.log("clean name ", name, ". result: ", normalizedName);
  return normalizedName;
};

export const getPosts = createAsyncThunk(
  "social/getPosts",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState().social;
      const { section, filter, page, selectedChannelId } = state;

      if (section === "community" || section === "friends") {
        let paramsObject = {
          page: String(page),
          section: section === "friends" ? "social" : "community",
        };
        if (section === "community") {
          paramsObject.filter = filter;
          paramsObject.channel = selectedChannelId;
        }

        const params = new URLSearchParams(paramsObject);

        const urn = `posts?${params.toString()}`;

        console.log("get request to ", urn);
        const { res, status } = await request("GET", urn);
        if (status) {
          console.log("getpostsres ", res);
          return res;
        } else {
          return rejectWithValue([]);
        }
      }
    } catch (error) {
      console.log("error ", error);
    }
  }
);

export const socialSlice = createSlice({
  name: "social",
  initialState,
  reducers: {
    setFetchedInitialData: (state, action) => {
      state.fetchedInitialData = action.payload;
    },
    addPost: (state, action) => {
      if (state.posts !== null) {
        state.posts = [action.payload, ...state.posts];
      }
    },
    resetPosts: (state, action) => {
      state.posts = null;
      state.page = 1;
      state.hasMore = true;
      state.fetchedInitialData = false;
    },
    changeSection: (state, action) => {
      const newSection = action.payload;
      if (
        (newSection === "community" || newSection === "friends") &&
        newSection !== state.section
      ) {
        state.section = newSection;
        socialSlice.caseReducers.resetPosts(state);
      }
    },
    changeFilter: (state, action) => {
      const newFilter = action.payload;
      if (
        (newFilter === "new" ||
          newFilter === "top" ||
          newFilter === "trending") &&
        state.filter !== newFilter
      ) {
        state.filter = newFilter;
        socialSlice.caseReducers.resetPosts(state);
      }
    },
    removePost: (state, action) => {
      const postId = action.payload;
      // console.log("remove post with id ", postId);
      if (state.posts && state.posts.length > 0) {
        state.posts = state.posts.filter((post) => post.id !== postId);
      }
    },
    changeChannel: (state, action) => {
      const channelId = action.payload;
      const channel = state.channels.find(
        (channel) => channel.id === channelId
      );
      console.log(
        "change channel to id ",
        channelId,
        ", found channel ",
        channel
      );
      if (channel && state.selectedChannelId !== channel.id) {
        state.selectedChannelId = channelId;
        socialSlice.caseReducers.resetPosts(state);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPosts.fulfilled, (state, action) => {
        const posts = action.payload;

        state.posts = [...(state.posts ?? []), ...posts];
        if (posts.length < 10) {
          state.hasMore = false;
        } else {
          state.hasMore = true;
        }

        state.page += 1;
        console.log(" has more ", state.hasMore);
      })
      .addCase(getChannels.fulfilled, (state, action) => {
        const channels = action.payload;

        state.channels = channels;
        if (state.channels.length > 0 && state.channels[0].id != null) {
          state.selectedChannelId = channels[0].id;
        }
      });
  },
});

export const selectFetchedInitialData = (state) =>
  state.social.fetchedInitialData;
export const selectPage = (state) => state.social.page;
export const selectPosts = (state) => state.social.posts;
export const selectHasMore = (state) => state.social.hasMore;
export const selectFilter = (state) => state.social.filter;
export const selectSection = (state) => state.social.section;
export const selectChannels = (state) => state.social.channels;
export const selectSelectedChannelId = (state) =>
  state.social.selectedChannelId;

export const {
  changeFilter,
  changeSection,
  changeChannel,
  addPost,
  setFetchedInitialData,
  removePost,
} = socialSlice.actions;

export default socialSlice.reducer;
