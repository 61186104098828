import React from "react";
import "./AuthHeader.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo-circle.png";

const AuthHeader = ({ page }) => {
  const navigate = useNavigate();
  const loginPage = page === "login";
  const buttonClasses =
    "text-sm font-semiBold py-1.5 px-3.5 rounded-full border-2 border-transparent";

  const getButtonClasses = (buttonPage) => {
    return `${
      page === buttonPage
        ? "text-black bg-gray-300"
        : "text-white bg-primary-color border border-primary-color hover:text-primary-color hover:bg-white transition-colors duration-300"
    } py-1 text-center min-w-fit w-[100px] rounded-full  text-base font-semibold`;
  };
  return (
    <div className="h-[55px] flex flex-row justify-between items-center lg:items-end py-1 px-5">
      <div className="flex flex-row items-center gap-1 self-center">
        <img src={logo} alt={"Logo"} className="w-[45px] h-[45px]" />
        {/* <p className="font-bold italic text-lg">Friengle</p>  */}
      </div>

      <div className="flex flex-row gap-3 lg:gap-5">
        {/* <Link to={"/pricing"}>Pricing</Link> */}
        <button
          onClick={() => {
            navigate("/");
          }}
          className={getButtonClasses("about")}
        >
          About
        </button>
        <button
          onClick={() => {
            navigate("/register");
          }}
          className={getButtonClasses("register")}
        >
          Sign Up
        </button>
      </div>
    </div>
  );
};

export default AuthHeader;
