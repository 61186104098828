module.exports = {
  calculateLevel: (points) => {
    if(!points) return 1;
    //Level = floor(sqrt(points) / k), k = 5

    const k = 2;
    const level = points > 0 ? Math.floor(Math.sqrt(points) / k) : 0;

    const currentLevelPointsRequired = Math.pow(level * k, 2);
    const nextLevelPointsRequired = Math.pow((Math.max(level, 0) + 1) * k, 2);

    const pointsRequiredToLevelUp =
      nextLevelPointsRequired - currentLevelPointsRequired;

    const pointsInCurrentLevel = points - currentLevelPointsRequired;

    return {
      level,
      pointsInCurrentLevel,
      pointsRequiredToLevelUp,
    };
  },
};
