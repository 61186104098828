import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { request } from "../util/util";
// const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState = {
  loggedIn: false,
  loading: true,
  username: "",
  bio: "",
  image: "",
  email: "",
  role: "",
  points: 0,
  showNavbar: false,
  // fcmTokenSaved: false,
};

export const authCheck = createAsyncThunk(
  "user/checkAuth",
  async (_, { rejectWithValue }) => {
    // const response = await fetch(`${BASE_URL}/api/auth/check`, {
    //   method: "get",
    //   withCredentials: true,
    //   credentials: "include",
    // });
    // if (response.ok) {
    //   // Handle the response status 200 here (or any other successful status code)
    //   const res = await response.json();
    //   console.log(`user role:${res.role}`);

    // const { res, status, msg } = await request("GET", "api/auth/check");
    // if (status) {
    //   return { email: res.email, username: res.username, role: res.role };
    // } else {
    //   // Handle other status codes (e.g., 404, 500) here
    //   // You can use rejectWithValue to send specific data to the rejected action
    //   return rejectWithValue(msg); hyz
    // }
    const request = await fetch("https://api.friengle.com/api/auth/check", {
    // const request = await fetch("http://localhost:4000/api/auth/check", {
      method: "GET",
      credentials: "include",
    });
    if (request.ok) {
      const res = await request.json();
      console.log("data received");
      console.log({
        email: res.email,
        username: res.username,
        role: res.role,
        bio: res.bio,
        image: res.image,
        points: res.points,
        // fcmTokenSaved: res.fcm_token_saved || false,
      });
      return {
        email: res.email,
        username: res.username,
        role: res.role,
        bio: res.bio,
        image: res.image,
        points: res.points,
        // fcmTokenSaved: res.fcm_token_saved || false,
      };
    } else {
      return rejectWithValue("Not authenticated");
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logIn: (state, action) => {
      const {
        username,
        email,
        role,
        bio,
        image,
        points,
        // fcmTokenSaved
      } = action.payload;
      state.username = username;
      state.email = email;
      state.role = role;
      state.loggedIn = true;
      state.loading = false;
      state.bio = bio;
      state.image = image;
      state.points = points;
      // state.fcmTokenSaved = fcmTokenSaved;
    },
    // logOut: (state) => {
    //   return { ...initialState, loading: false };
    // },
    editProfile: (state, action) => {
      console.log(`edit profile: ${action.payload}`);
      Object.keys(action.payload).forEach((key) => {
        if (state.hasOwnProperty(key) && action.payload[key] != null) {
          console.log(`key: ${key}, value: ${action.payload[key]}`);
          state[key] = action.payload[key];
        }
      });
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    toggleNavbar: (state, action) => {
      state.showNavbar = !state.showNavbar;
    },
    // setFcmTokenSaved: (state, action) => {
    //   state.fcmTokenSaved = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authCheck.fulfilled, (state, action) => {
        const { username, email, role, image, bio, points } = action.payload;
        state.loggedIn = true;
        state.username = username;
        state.email = email;
        state.role = role;
        state.loading = false;
        state.image = image;
        state.bio = bio;
        state.points = points;
      })
      .addCase(authCheck.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const selectLoggedIn = (state) => state.user.loggedIn;
export const selectLoading = (state) => state.user.loading;
export const selectUsername = (state) => state.user.username;
export const selectRole = (state) => state.user.role;
export const selectPoints = (state) => state.user.points;
export const selectShowNavbar = (state) => state.user.showNavbar;
// export const selectFcmTokenSaved = (state) => state.user.fcmTokenSaved;
export const selectProfileInfo = (state) => ({
  image: state.user.image,
  bio: state.user.bio,
  username: state.user.username,
});
export const {
  logIn,
  logOut,
  setLoading,
  toggleNavbar,
  editProfile,
  // setFcmTokenSaved
} = userSlice.actions;

export default userSlice.reducer;
