import React, { useState, useEffect } from "react";
import { setCount } from "../features/goalSlice";
import { useDispatch } from "react-redux";
import { GrClose } from "react-icons/gr";
import { AiFillDelete } from "react-icons/ai";
import Modal from "./Modal";
import { request } from "../util/util";
// const BASE_URL = process.env.REACT_APP_BASE_URL;

const LogsModal = ({
  viewLogsModal,
  setViewLogsModal,
  logs,
  setLogs,
  currentValue,
  setCurrentValue,
  goal_id,
  can_delete,
}) => {
  const [dataFetched, setDataFetched] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      // console.log(`${BASE_URL}/goal/logs/${goal_id}`);
      // const jsonResponse = await fetch(`${BASE_URL}/goal/logs/${goal_id}`, {
      //   credentials: "include",
      // });
      // const response = await jsonResponse.json();
      // setLogs(response); // Assuming the API response contains the tasks data
      // setDataFetched(true); // Set the flag to indicate that data has been fetched
      const { res, msg, status } = await request("GET", `goal/logs/${goal_id}`);
      if (status) {
        setLogs(res);
        setDataFetched(true);
      }
    };

    // Fetch data only if viewTasks is true and data has not been fetched yet
    if (viewLogsModal && !dataFetched) {
      fetchData();
    }
  }, [viewLogsModal, dataFetched, goal_id, setLogs]);

  const handleLogDelete = async (log) => {
    // console.log(`GOALID${goal_id}/AMOUNT${log.amount}`);
    // await fetch(`${BASE_URL}/goal/logs/delete`, {
    //   credentials: "include",
    //   method: "DELETE",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     id: log.id,
    //     goal_id: goal_id,
    //     amount: log.amount,
    //   }),
    // });
    const { res, msg, status } = await request("DELETE", `goal/logs/delete`, {
      id: log.id,
      goal_id: goal_id,
      amount: log.amount,
    });
    if (status) {
      console.log(
        "current value ",
        currentValue,
        ", type: ",
        typeof currentValue,
        ", log amount: ",
        log.amount,
        ", type: ",
        typeof log.amount
      );
      let newCount = currentValue - log.amount;
      dispatch(setCount(goal_id, newCount));
      setCurrentValue(newCount);
      setLogs(logs.filter((curr) => curr.id !== log.id));
    }
  };

  const formattedDate = (submission_time) => {
    const originalDate = new Date(submission_time);
    return originalDate.toLocaleString();
  };

  return (
    <Modal show={viewLogsModal} setShow={setViewLogsModal}>
      {logs.length > 0 ? (
        logs.map((log, index) => (
          <div
            key={index}
            className="bg-gray-50 rounded-md shadow-sm border border-gray-300 flex justify-between mb-0.5 py-2 px-4 items-center"
          >
            <div>
              <p className="ext-sm text-slate-600 font-semibold text-xs">
                {formattedDate(log.submission_time)}
              </p>
              <p className="text-base ml-2.5 font-regular text-black">
                {log.amount}
              </p>
            </div>
            {can_delete && (
              <button
                onClick={() => {
                  handleLogDelete(log);
                }}
              >
                <AiFillDelete className="fill-black hover:fill-red-500 size-5" />
              </button>
            )}
          </div>
          // <div
          //   key={index}
          //   className="border border-black flex justify-between mb-0.5 p-0 px-3"
          // >
          //   <div>
          //     <p className="text-sm text-gray-600 font-light text-sm">
          //       {formattedDate(log.submission_time)}
          //     </p>
          //     <p className="text-lg ml-2.5 font-regular text-black">
          //       {log.amount}
          //     </p>
          //   </div>
          //   <button
          //     className="text-red-500 border-none bg-transparent text-5xl"
          //     onClick={() => {
          //       handleLogDelete(log);
          //     }}
          //   >
          //     <AiFillDelete className="fill-black hover:fill-red-500 size-6" />
          //   </button>
          // </div>
        ))
      ) : (
        <p className="text-center">No logs</p>
      )}
    </Modal>
  );
};

export default LogsModal;
