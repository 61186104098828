import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Friends.css";
import {
  getFriendsData,
  selectAllFriends,
  selectPendingRequests,
  selectReceivedRequests,
} from "../../features/friendsSlice";
import { useNavigate } from "react-router-dom";
import { IoPersonCircleSharp } from "react-icons/io5";
import { request } from "../../util/util";
import { toast } from "react-toastify";

const Friends = ({ isCurrentUser, username }) => {
  const [activeTab, setActiveTab] = useState("all");
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const allFriends = useSelector(selectAllFriends);
  const pendingRequests = useSelector(selectPendingRequests);
  const receivedRequests = useSelector(selectReceivedRequests);
  const navigate = useNavigate();
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    async function getFriends() {
      const { res, status } = await request(
        "GET",
        `profile/${username}/friends/all`
      );
      console.log("res ", res);
      if (status && res && res.arr && Array.isArray(res.arr)) {
        setUsers(res.arr);
      } else {
        toast.error("Failed to load data");
      }
    }

    if (isCurrentUser) {
      dispatch(getFriendsData());
    } else {
      getFriends();
    }
  }, [username, isCurrentUser, dispatch]);

  // let displayedUsers;
  // if (activeTab === "all") {
  //   displayedUsers = allFriends;
  // } else if (activeTab === "pending") {
  //   displayedUsers = pendingRequests;
  // } else {
  //   displayedUsers = receivedRequests;
  // }

  const usersList = (users) => {
    return (
      users &&
      Array.isArray(users) &&
      users.map((user, index) => {
        return (
          <button
            key={`user_${user.username}`}
            onClick={() => {
              navigate(`/profile/${user.username}`);
            }}
            className="flex justify-start items-center gap-2 text-xl tracking-wide "
          >
            {user.image ? (
              <img
                className="w-10 h-10 rounded-full border-2 object-cover"
                src={user.image}
              />
            ) : (
              <IoPersonCircleSharp className="fill-gray-300" size={40} />
            )}
            {user.username}
          </button>
        );
      })
    );
  };

  return (
    <div>
      {isCurrentUser && (
        <div className="mx-auto w-full max-w-3xl bg-gray-200 rounded-full flex justify-between p-1">
          {["all", "pending", "received"].map((btn) => {
            return (
              <button
                onClick={() => handleTabClick(btn)}
                className={`basis-2/6  transition-colors duration-300 py-1 rounded-full  ${
                  activeTab === btn ? "bg-white font-semiBold" : "font-regular"
                }`}
              >
                {btn}
              </button>
            );
          })}
        </div>
      )}

      <div className="flex flex-col gap-2 mt-2">
        {usersList(
          isCurrentUser
            ? activeTab === "all"
              ? allFriends
              : activeTab === "pending"
              ? pendingRequests
              : receivedRequests
            : users
        )}
      </div>
    </div>
  );
};

export default Friends;
