import React, { useEffect, useState } from "react";
import { IoFilterSharp } from "react-icons/io5";
import { titleClasses } from "../util/globals";
import { LuListFilter } from "react-icons/lu";
import AsyncSelect from "react-select/async";
import { formatDate, includeParams, request } from "../util/util";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { BsFillJournalBookmarkFill } from "react-icons/bs";
import { Pagination } from "@mui/material";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { MdArrowForwardIos } from "react-icons/md";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const JournalPage = ({}) => {
  const [page, setPage] = useState(1);
  const [goal, setGoal] = useState(null);
  const [data, setData] = useState({ count: 0, entries: [] });
  const [graphData, setGraphData] = useState(null);
  const [loading, setLoading] = useState({
    commitmentGraph: false,
    entries: false,
  });
  const [error, setError] = useState({
    commitmentGraph: false,
    entries: false,
  });
  const [filters, setFilters] = useState({
    start_date: null,
    end_date: null,
    sortBy: null,
    goal_id: null,
  });
  const fetchJournals = async () => {
    const response = await request(
      "GET",
      includeParams("journal/entries", filters)
    );
    if (response.status) {
      setData({ count: response.res.total_count, entries: response.res.rows });
    } else {
      toast.error("Error loading journal entries");
    }
  };
  const fetchGraphData = async () => {
    const response = await request(
      "GET",
      includeParams("journal/score-over-period", filters)
    );
    if (!response.status) {
      toast.error("Error loading journal entries");
    } else if (Array.isArray(response.res)) {
      setGraphData({
        labels: response.res.map((dayData) => dayData.period),
        datasets: [
          {
            label: "Commitment Score",
            data: response.res.map((dayData) => dayData.score),
            borderColor: "rgba(75, 192, 192, 1)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            tension: 0.3,
            pointRadius: 5,
          },
        ],
      });
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
    fetchJournals();
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: true, position: "top" },
    },
    scales: {
      x: {
        title: { display: true, text: "Time" },
      },
      y: {
        title: { display: true, text: "Score" },
        beginAtZero: true,
      },
    },
  };

  const loadOptions = async (inputValue) => {
    if (!inputValue) return [];
    try {
      const response = await request("GET", `goal/search?q=${inputValue}`);
      if (response["status"] === true) {
        return response["res"].map((goal) => ({
          value: goal.id,
          label: goal.title,
        }));
      }
    } catch (error) {
      console.error("Error fetching goals:", error);
      return [];
    }
  };

  const handleGoalChange = (selectedOption) => {
    console.log("SELECTOPTION ", selectedOption);
    setGoal(selectedOption);
    setFilters((filters) => {
      return { ...filters, goal_id: selectedOption?.value };
    });
  };

  const handleDateChange = (e, key) => {
    const dateValue = e.target.value;
    if (!dateValue) {
      setFilters((prev) => ({ ...prev, [key]: null }));
      return;
    }
    const [year, month, day] = dateValue.split("-").map(Number);
    const localDate = new Date(year, month - 1, day);
    if (key === "end_date") {
      localDate.setHours(23, 59, 59, 999);
    }
    const utcDate = localDate.toISOString();
    setFilters((prev) => {
      const newFilters = { ...prev, [key]: utcDate };
      if (
        key === "start_date" &&
        prev.end_date &&
        new Date(utcDate) > new Date(prev.end_date)
      ) {
        newFilters.end_date = utcDate;
      } else if (
        key === "end_date" &&
        prev.start_date &&
        new Date(utcDate) < new Date(prev.start_date)
      ) {
        newFilters.start_date = utcDate;
      }
      return newFilters;
    });
  };

  const toLocalDateString = (utcDateStr) => {
    const d = new Date(utcDateStr);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    fetchJournals();
  }, []);

  return (
    <div className="w-full flex flex-col gap-5 pb-16">
      {/* TITLE */}
      <div className="flex flex-row justify-between gap-4">
        <h1 className={titleClasses}>Journals</h1>
        <div className="flex flex-row gap-2">
          <button
            onClick={() => {}}
            className="bg-primary-color p-2 rounded-lg text-white font-semibold text-sm  border hover:scale-110  transition-all duration-100"
          >
            New Entry
          </button>
        </div>
      </div>
      {/* FILTER */}
      <div className="w-full flex flex-col justify-center items-start gap-5 p-2 lg:p-5 rounded-md bg-gray-100 shadow-md">
        <div className="flex flex-row items-center gap-2">
          <LuListFilter className="text-primary-color size-7" />
          <p className="font-bold text-xl text-slate-600">
            Sorting and Filters
          </p>
        </div>
        <div className="flex flex-col lg:flex-row lg:flex-wrap w-full bg-white rounded-md p-2 lg:p-4 items-center lg:items-end gap-4">
          <div className="w-full flex flex-col gap-1">
            <p className="text-lg font-bold text-slate-500">Goal:</p>
            <div className="w-full max-w-[700px]">
              <AsyncSelect
                isClearable
                cacheOptions
                className="outline-primary-color"
                loadOptions={loadOptions}
                placeholder="Type to search goals..."
                onChange={handleGoalChange}
                value={goal}
              />
              <p className="text-sm font-bold text-gray-400 mt-1">
                {" "}
                Selecting a goal will display its commitment trend over time,
                helping you analyze progress.
              </p>
              {/* {goal && (
                <Link
                  to={"/journals"}
                  className="flex flex-row items-center gap-1 text-primary-color font-semibold my-3 underline cursor-pointer"
                >
                  <p>
                    Set this goal's prompts and rules that determine the score
                  </p>
                  <MdArrowForwardIos size={12} />
                </Link>
              )} */}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row w-full items-center lg:items-end gap-5">
            <div className="w-full lg:max-w-[200px] flex flex-col gap-1">
              <p className="text-sm font-bold text-slate-500">Start Date:</p>
              <input
                type="date"
                className="border border-gray-400 rounded-md w-full px-2"
                placeholder=""
                value={
                  filters.start_date
                    ? toLocalDateString(filters.start_date)
                    : ""
                }
                onChange={(e) => handleDateChange(e, "start_date")}
              />
            </div>
            <div className="w-full lg:max-w-[200px] flex flex-col gap-1">
              <p className="text-sm font-bold text-slate-500">End Date:</p>
              <input
                type="date"
                className="border border-gray-400 rounded-md w-full px-2"
                placeholder=""
                value={
                  filters.end_date ? toLocalDateString(filters.end_date) : ""
                }
                onChange={(e) => handleDateChange(e, "end_date")}
              />
            </div>
            <div className="w-full lg:max-w-[200px] flex flex-col gap-1">
              <p className="text-sm font-bold text-slate-500">Sort by:</p>
              <select className="border border-gray-400 rounded-md w-full px-2 py-1 focus:border-primary-color focus:outline-none">
                <option selected value="">
                  -
                </option>
                <option value="highest_score">Highest Score</option>
                <option value="lowest_score">Lowest Score</option>
                <option value="most_recent">Most Recent</option>
                <option value="least_recent">Least Recent</option>
              </select>
            </div>

            <button
              onClick={() => {
                setPage(1);
                fetchJournals();
              }}
              className="bg-primary-color text-white px-7 py-1 rounded-md font-semibold border border-primary-color hover:bg-white hover:text-primary-color transition-colors duration-300"
            >
              Go
            </button>
          </div>
        </div>
      </div>
      {/* CHART */}
      {graphData && (
        <div className="w-full flex flex-col justify-center items-center">
          <p className="text-xl font-bold text-slate-700 mb-2">
            Commitment Over Time
          </p>

          <select className="border border-gray-400 rounded-md px-2 py-1 focus:border-primary-color focus:outline-none mb-2">
            <option selected value={""}>
              Group by
            </option>
            <option selected value={"day"}>
              Day
            </option>
            <option selected value={"week"}>
              Week
            </option>
            <option selected value={"month"}>
              Month
            </option>
            <option selected value={"year"}>
              Year
            </option>
          </select>
          <div className="w-full max-w-[800px] h-[40vh] min-h-[250px]">
            <Line data={graphData} options={options} />
          </div>
        </div>
      )}
      {/* ENTRIES */}
      <div className="flex flex-col gap-3 p-5">
        <p className="text-2xl font-bold text-slate-700 mb-2">Entries</p>

        <Pagination
          count={Math.max(1, data.count)}
          page={page}
          onChange={handleChange}
        />
        {data &&
          Array.isArray(data.entries) &&
          data.entries.length > 0 &&
          data.entries.map((entry) => {
            return (
              <div className="w-full shadow-md rounded-md p-3 bg-gray-50 hover:bg-gray-100 cursor-pointer flex flex-row gap-2 lg:gap-5 items-center">
                <BsFillJournalBookmarkFill className="text-gray-600 size-7 min-w-0" />
                <div className="flex-1 w-full">
                  <p className="text-slate-500 font-semibold">
                    {formatDate(entry.date, true)}{" "}
                    {entry.score && <span>, Score: {entry.score}</span>}
                  </p>

                  <p className="w-full pl-2 font-medium">
                    <span className="mr-2 underline font-semibold text-lg">
                      Goal:{" "}
                    </span>
                    {entry.goal_title}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default JournalPage;
