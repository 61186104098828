import React, { useState } from "react";
import "./CountingGoal.css";
import LogsModal from "../LogsModal";
import BasicGoal from "../BasicGoal/BasicGoal";
import { FaHistory, FaBook } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { request } from "../../util/util";

const CountingGoal = ({
  goal,
  viewNotes,
  shareGoal,
  viewParticipants,
  deleteGoal,
  editGoal,
  viewChat,
  userParticipantData,
}) => {
  //const [idCounter, setIdCounter] = useState(3); //temporary solution, id will be stored in db
  const [viewLogsModal, setViewLogsModal] = useState(false);
  const [logs, setLogs] = useState([]);
  const [currentValue, setCurrentValue] = useState(goal.count);
  const [inputValue, setInputValue] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const handleAddNote = async () => {
  //   const currentDate = new Date().toISOString();
  //   const response = await fetch(`${BASE_URL}/goal/notes/new`, {
  //     method: "POST",
  //     credentials: "include",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       goal_id: goal.id,
  //       content: note,
  //       submission_time: currentDate,
  //     }),
  //   });

  //   setNotes([...notes, { id: response, content: note, date: currentDate }]);
  //   setNote("");
  // };

  const handleAddClick = async (id) => {
    const newValue = currentValue + inputValue;
    if (newValue <= goal.total) {
      const currentDate = new Date().toISOString();
      const { status, res } = await request(
        "POST",
        "goal/logs/new",

        {
          goal_id: goal.id,
          amount: inputValue,
          submission_time: currentDate,
        }
      );
      if (status) {
        setLogs([
          ...logs,
          {
            id: res,
            amount: inputValue,
            goal_id: goal.id,
            submission_time: currentDate,
          },
        ]);
        setCurrentValue(currentValue + inputValue);
      }
    } else {
      alert("Added value should be less than target value");
    }
    setInputValue(0);
  };

  const isButtonDisabled = inputValue === 0 || isNaN(inputValue);

  const role = userParticipantData?.is_accountability_partner
    ? "accountability partner"
    : "goal participant";
  return (
    <div className="flex flex-col gap-2 ">
      <BasicGoal
        goal={goal}
        viewNotes={viewNotes}
        shareGoal={shareGoal}
        viewParticipants={viewParticipants}
        deleteGoal={deleteGoal}
        editGoal={editGoal}
        viewChat={viewChat}
        userParticipantData={userParticipantData}
      />

      <div className="flex flex-row font-regular items-center w-full justify-around">
        <p className="text-lg font-bold text-center">
          <span className="text-primary-color">{currentValue}</span>/
          {goal.total}
        </p>

        {role === "goal participant" && (
          <div className="flex flex-row gap-2">
            <input
              type="number"
              min={0}
              max={goal.targetValue - currentValue}
              value={inputValue}
              onChange={(e) => setInputValue(parseInt(e.target.value))}
              className="w-36 max-w-52 border-2 h-8 rounded-md outline-none p-1"
            />
            <button
              disabled={isButtonDisabled}
              onClick={handleAddClick}
              className={`text-sm text-black p-1.5 rounded-md outline-none ${
                isButtonDisabled ? "bg-gray-100" : "bg-primary-color"
              }`}
            >
              Add
            </button>
          </div>
        )}
        <button
          onClick={() => {
            setViewLogsModal(!viewLogsModal);
          }}
          className="w-fit flex flex-row items-center gap-1"
        >
          <FaBook className="fill-slate-600" size={20} />
          <span className="text-sm font-semibold">View Entries</span>
        </button>
      </div>

      <LogsModal
        viewLogsModal={viewLogsModal}
        setViewLogsModal={setViewLogsModal}
        logs={logs}
        setLogs={setLogs}
        currentValue={currentValue}
        setCurrentValue={setCurrentValue}
        goal_id={goal.id}
        can_delete={role === "goal participant"}
      />
    </div>
  );
};

export default CountingGoal;
