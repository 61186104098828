import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import { IoPersonCircleSharp } from "react-icons/io5";
import GoalPost from "./GoalPost/GoalPost";
import { useDispatch, useSelector } from "react-redux";
import { selectProfileInfo } from "../features/userSlice";
import ReactQuill, { contextType } from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  addPost,
  changeChannel,
  selectChannels,
  selectSection,
  selectSelectedChannelId,
} from "../features/socialSlice";
import { request } from "../util/util";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const NewPost = ({
  show,
  setShow,
  section,
  username,
  content,
  setContent,
  goal,
}) => {
  // const currentSection = useSelector(selectSection);
  const homePageSelectedChannel = useSelector(selectSelectedChannelId);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const profileInfo = useSelector(selectProfileInfo);
  const channels = useSelector(selectChannels);
  const [title, setTitle] = useState("");
  const [input, setInput] = useState("");
  const [selectedSection, setSelectedSection] = useState("community");
  const [loading, setLoading] = useState(false);
  const user = useSelector(selectProfileInfo);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goalData =
    goal === null || goal === undefined
      ? {}
      : {
          start_date: goal?.start_date ?? null,
          description: goal.description,
          deadline: goal.deadline ? goal.deadline : null,
          goal_title: goal.title,
          goal_type: goal.type,
          goal_id: goal.id,
          countinggoal_count: goal.count,
          countinggoal_total: goal.total,
          goal_participants: goal.goal_participants,
        };

  useEffect(() => {
    if (content) {
      setTitle(content.title ?? "");
      setInput(content.content ?? "");
    } else {
      setTitle("");
      setInput("");
    }
  }, [content]);

  useEffect(() => {
    // if (
    //   (section === "community" || !section) &&
    //   channels &&
    //   channels.length > 0
    // ) {
    //   setSelectedChannel(channels[0].id);
    // } else {
    //   setSelectedChannel(null);
    // }
    setLoading(false);
  }, [channels, show]);

  const handlePost = async (e) => {
    e.preventDefault();
    if (username === "") {
      navigate("/login");
    }

    if (title.trim() !== "") {
      setLoading(true);
      let reqBody = {
        title,
        body: input,
        type: section
          ? section === "community"
            ? "community"
            : "social"
          : selectedSection,
      };

      if (goal) {
        reqBody.attachment_id = goal.id;
      }
      if (
        section === "community" ||
        (!section && selectedSection === "community")
      ) {
        reqBody.label_id = selectedChannel;
      }

      const res = await request("POST", "posts/addpost", reqBody);

      if (res.status) {
        setTitle("");
        setInput("");

        // if (
        //   ((section === "community" ||
        //     (!section && selectedSection === "community")) &&
        //     homePageSelectedChannel === selectedChannel) ||
        //   section === "social" ||
        //   (!section && selectedSection === "social")
        // ) {
        const currentDate = new Date().toLocaleString();

        dispatch(
          addPost({
            id: res.res,
            username: username,
            image: user.image,
            likes: 0,
            comments: 0,
            liked: false,
            body: input,
            title: title,
            post_date: currentDate,
            type: section,
            goal_data: goal ? goalData : null,
          })
        );
        // }

        setShow(false);
        toast.success("Post added");
      } else {
        toast.error("Error");
      }

      setLoading(false);
    }
  };

  const postBtnDisabled = !title.trim() || loading;

  return (
    <Modal
      show={show}
      setShow={(val) => {
        if (typeof setContent === "function") {
          setContent(null);
        }
        setShow(val);
      }}
    >
      <form className="flex flex-col mt-3 mb-10" onSubmit={handlePost}>
        <div className="w-full flex flex-col gap-2 mb-5">
          <p className="font-bold text-2xl text-slate-500 text-center">
            {section
              ? section === "community"
                ? "Community"
                : "Friends"
              : "Share Goal"}
          </p>
          {goal && (
            <div className="w-full px-2 lg:px-5 mt-5 mb-10">
              <GoalPost data={goalData} display={"complete"} />
              <p className="font-bold text-gray-500 text-sm mt-1 underline">
                This goal will be included with your post.
              </p>
            </div>
          )}

          {!section && (
            <select
              id="selectOptions"
              value={selectedSection}
              onChange={(e) => {
                setSelectedSection(e.target.value);
              }}
              className="self-center cursor-pointer rounded-md w-4/6 max-w-96 border-[1px] border-gray-300 bg-gray-50 shadow-md outline-none font-bold p-3 text-center text-gray-600"
            >
              <option value={"community"}>Community</option>
              <option value={"social"}>Friends</option>
            </select>
          )}
          {/* {(section === "community" ||
            (!section && selectedSection === "community")) && (
            <div className="w-full flex flex-col items-center gap-2">
              <select
                id="selectOptions"
                value={selectedChannel}
                onChange={(e) => {
                  setSelectedChannel(Number(e.target.value));
                }}
                className="self-center cursor-pointer rounded-md w-4/6 max-w-96 border-[1px] border-gray-300 bg-gray-50 shadow-md outline-none font-bold p-3 text-center text-gray-600"
              >
                {channels !== null &&
                  channels.length > 0 &&
                  channels.map((channel) => {
                    return (
                      <option key={channel.id} value={channel.id}>
                        {channel.title}
                      </option>
                    );
                  })}
              </select>
              {channels && selectedChannel && (
                <p className="text-gray-500 font-medium text-sm">
                  {
                    channels.find((channel) => channel.id === selectedChannel)
                      ?.description
                  }
                </p>
              )}
            </div>
          )} */}
        </div>
        <div className="flex flex-col gap-5 w-full lg:w-5/6 lg:max-w-5xl self-center bg-white">
          <div className="flex flex-row gap-2 items-center pr-4 ">
            {profileInfo.image ? (
              <img
                src={profileInfo.image}
                alt={"Profile Picture"}
                className="rounded-full w-14 h-14 border-2 border-gray-300 shadow-md"
              />
            ) : (
              <IoPersonCircleSharp className="fill-gray-200" size={70} />
            )}
            <input
              className="w-full border-b-2 border-gray-300 h-[45px] outline-none font-bold text-base bg-inherit "
              placeholder="Title (required)"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          {/* <textarea
            className=" outline-none border-[1px] border-gray-300  rounded-md w-full h-36 text-base p-2 resize-none overflow-y-auto self-center font-regular text-sm"
            placeholder="Content (optional)"
            value={input}
            onChange={(e) => setInput(e.target.value)}
          /> */}
          <ReactQuill
            theme="snow"
            value={input}
            onChange={setInput}
            placeholder="Content (optional)"
            modules={NewPost.modules}
            formats={NewPost.formats}
            className="font-regular text-gray-600 post-content"
          />
          <div
            className={`self-end ${
              postBtnDisabled ? "bg-gray-200" : "bg-primary-color"
            } px-4 py-1 h-fit font-regular rounded-md`}
          >
            <button type="submit">{goal ? "Share" : "Post"}</button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

// NewPost.modules = {
//   toolbar: [
//     [{ font: ["Lato", "sans-serif", "serif", "monospace"] }], // Add custom font option
//     [{ header: "1" }, { header: "2" }, { font: [] }],
//     [{ list: "ordered" }, { list: "bullet" }],
//     ["bold", "italic", "underline", "strike"],
//     [{ align: [] }],
//     [{ color: [] }, { background: [] }],
//     ["clean"],
//   ],
// };

// NewPost.formats = [
//   "font", // Include font
//   "header",
//   "list",
//   "bullet",
//   "bold",
//   "italic",
//   "underline",
//   "strike",
//   "align",
//   "color",
//   "background",
// ];
NewPost.modules = {
  toolbar: [
    ["bold", "italic"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
};

NewPost.formats = ["bold", "italic", "list", "bullet"];

export default NewPost;
