import React, { useState, useEffect } from "react";
import "./GoalTracking.css";
import MilestoneGoal from "../../components/MilestoneGoal/MilestoneGoal";
import CountingGoal from "../../components/CountingGoal/CountingGoal";
import BasicGoal from "../../components/BasicGoal/BasicGoal";
import { useDispatch, useSelector } from "react-redux";
import { selectUsername, selectLoggedIn } from "../../features/userSlice";
import { getFriendsData, selectAllFriends } from "../../features/friendsSlice";
import { MdEdit } from "react-icons/md";
import {
  // fetchGoalTypes,
  selectCategories,
  selectTypes,
  selectGoals,
  addGoal,
  selectFetchedData,
  setFetchedData,
  resetState,
  fetchGoals,
  fetchCategories,
  setAccountabilityPartners,
  deleteGoal,
  removeCategory,
} from "../../features/goalSlice";
import { useLocation, useNavigate } from "react-router-dom";
import AddGoalModal from "../../components/AddGoalModal/AddGoalModal";
import AddGoalCategoryModal from "../../components/AddGoalCategoryModal/AddGoalCategoryModal";
import { Helmet } from "react-helmet-async";
import SEO from "../../components/SEO";
import { titleClasses } from "../../util/globals";
import { AiFillDelete } from "react-icons/ai";
import { toast } from "react-toastify";
import { setChosenCategory } from "../../features/journalSlice";
import { LuListFilter } from "react-icons/lu";
import { TbEditOff, TbTargetArrow } from "react-icons/tb";
import NotesModal from "../../components/NotesModal";
import ShareModal from "../../components/ShareModal";
import { FaLock } from "react-icons/fa";
import SharedGoalParticipantsModal from "../../components/SharedGoalParticipantsModal";
import DeleteModal from "../../components/DeleteModal";
import AccountabilityMessages from "../../components/AccountabilityMessages";
import NewPost from "../../components/NewPost";

// const BASE_URL = process.env.REACT_APP_BASE_URL;

const GoalTracking = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const username = useSelector(selectUsername);
  const types = useSelector(selectTypes);
  const goals = useSelector(selectGoals);
  const fetchedData = useSelector(selectFetchedData);
  const [isAddingGoal, setIsAddingGoal] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [title, setTitle] = useState("");
  const [deadline, setDeadline] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  // const [customInputValue, setCustomInputValue] = useState(""); // Add more state variables for custom inputs based on the type
  // const [types, setTypes] = useState(["basic", "milestone", "counting"]);
  const [inputValues, setInputValues] = useState([""]); // Initial state with one input
  const [selectedDeadline, setSelectedDeadline] = useState("");
  const [targetValue, setTargetValue] = useState(0);
  const [selectedFriends, setSelectedFriends] = useState([]);
  // const [friendsList, setFriendsList] = useState([]);
  const friendsList = useSelector(selectAllFriends);
  const [status, setStatus] = useState("in progress");
  const categories = useSelector(selectCategories);
  const [selectedCategory, setSelectedCategory] = useState(-3);
  const [showAddGoalModal, setShowAddGoalModal] = useState(false);
  const [section, setSection] = useState("goals");
  const [showAddGoalCategoryModal, setShowAddGoalCategoryModal] =
    useState(false);
  const [editCategoryWithId, setEditCategoryWithId] = useState(null);
  const [viewGoalNotes, setViewGoalNotes] = useState(null);
  const [shareGoal, setShareGoal] = useState(null);
  const [goalParticipants, setGoalParticipants] = useState(null);
  // const [deleteGoalWithId, setDeleteGoalWithId] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const [editGoal, setEditGoal] = useState(null);
  const [viewGoalChat, setViewGoalChat] = useState(null);

  useEffect(() => {
    if (location.state) {
      if (location.state.new_goal) {
        setShowAddGoalModal(true);
      }
    }
  }, [location.state]);

  useEffect(() => {
    dispatch(
      fetchGoals({
        status,
        category_id: selectedCategory,
      })
    );
    if (!fetchedData) {
      dispatch(fetchCategories());
      dispatch(setFetchedData(true));
    }
  }, [dispatch, fetchedData, username, status, selectedCategory]);

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
  };

  const renderGoalComponent = (goal) => {
    const userParticipantData =
      goal.goal_participants &&
      Array.isArray(goal.goal_participants) &&
      goal.goal_participants.find(
        (participant) => participant.username === username
      );

    const sharedProps = {
      key: goal.id,
      goal,
      viewNotes: (id) => setViewGoalNotes(id),
      shareGoal: (id) => setShareGoal(id),
      viewParticipants: (goal_participants) =>
        setGoalParticipants(goal_participants),
      deleteGoal: () => setDeleteItem({ id: goal.id, type: "goal" }),
      editGoal: () => {
        setEditGoal(goal);
        console.log("edit goal ", goal);
        setShowAddGoalModal(true);
      },
      viewChat: () => setViewGoalChat(goal.id),
      userParticipantData,
    };

    switch (goal.type) {
      case "milestone":
        return <MilestoneGoal {...sharedProps} />;
      case "counting":
        return <CountingGoal {...sharedProps} />;
      default:
        return <BasicGoal {...sharedProps} />;
    }
  };

  const selectedCategoryObj = categories.find(
    (category) => selectedCategory === category.id
  );

  return (
    <div className="flex flex-col gap-5 w-full">
      {/* <Helmet>
        <title>Track and Achieve Your Goals - Friengle Goal Tracking</title>
        <meta
          name="description"
          content="Set, track, and achieve your personal and professional goals with Friengle. Join our community to stay motivated and reach new heights together."
        />
      </Helmet> */}
      <SEO
        title={"Goal Tracking - Friengle"}
        description={"Set, track, and achieve your goals with Friengle."}
      />
      <div className="flex flex-row justify-between gap-4">
        <h1 className={titleClasses}>Goals & Accountability</h1>
        <div className="flex flex-row gap-2">
          <button
            onClick={() => {
              setShowAddGoalCategoryModal(true);
            }}
            className="bg-gray-200 p-2 rounded-lg font-semibold text-sm border hover:scale-110 transition-all duration-100"
          >
            Add Category
          </button>
          <button
            onClick={() => {
              setShowAddGoalModal(true);
            }}
            className="bg-primary-color p-2 rounded-lg text-white font-semibold text-sm  border hover:scale-110  transition-all duration-100"
          >
            New Goal
          </button>
        </div>
      </div>
      <div className="w-full rounded-md bg-gray-100 shadow-md p-1 md:p-5 flex flex-col gap-6">
        <div className="flex flex-row gap-1 items-center">
          <LuListFilter size={28} className="text-primary-color" />
          <h2 className="font-bold text-xl text-slate-600">
            Sorting and Filters
          </h2>
        </div>
        <div className="w-full flex flex-col gap-3 rounded-xl bg-white p-4">
          <div className="flex flex-col md:flex-row gap-3 md:gap-9 w-full">
            <div className="flex flex-col gap-1 w-full md:w-auto">
              <p className="text-sm font-bold text-slate-500">
                Choose Category:
              </p>
              <div className="flex flex-row justify-start items-center gap-2">
                <select
                  className="w-full md:w-auto p-2 capitalize rounded-lg font-medium text-sm bg-white border-2 border-gray-300 focus:outline-none focus:border-primary-color"
                  value={selectedCategory}
                  onChange={(e) => {
                    const selectedId = e.target.value;
                    setSelectedCategory(parseInt(selectedId));
                  }}
                >
                  {categories.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.category}: {item.nb_of_goals}
                    </option>
                  ))}
                </select>

                {selectedCategory && selectedCategory > 0 ? (
                  <div className="flex flex-col justify-around gap-1">
                    <button
                      onClick={() => {
                        setEditCategoryWithId(selectedCategory);
                        setShowAddGoalCategoryModal(true);
                      }}
                    >
                      <MdEdit />
                    </button>
                    <button
                      onClick={() => {
                        setDeleteItem({
                          id: selectedCategory,
                          type: "category",
                        });
                      }}
                    >
                      <AiFillDelete />
                    </button>
                  </div>
                ) : (
                  <TbEditOff size={23} className="text-black" />
                )}
              </div>
            </div>
            <div className="flex flex-col gap-1 w-full md:w-auto">
              <p className="text-sm font-bold text-slate-500">Choose Status:</p>

              <select
                className="w-full md:w-auto p-2 capitalize rounded-lg font-medium text-sm bg-white border-2 border-gray-300 focus:outline-none focus:border-primary-color"
                value={status}
                onChange={(e) => {
                  const itemToLower = e.target.value;
                  setStatus(itemToLower);
                  handleStatusChange(itemToLower);
                }}
              >
                {(() => {
                  let currentCategory = categories.find(
                    (category) => category.id === selectedCategory
                  );
                  console.log("current cat ", currentCategory);

                  return ["In Progress", "Pending", "Completed"].map(
                    (item, index) => {
                      const itemToLower = item.toLowerCase();

                      const count = currentCategory
                        ? itemToLower === "in progress"
                          ? currentCategory.in_progress_count
                          : itemToLower === "pending"
                          ? currentCategory.pending_count
                          : currentCategory.completed_count
                        : 0;

                      return (
                        <option key={index} value={itemToLower}>
                          {item}: {count}
                        </option>
                      );
                    }
                  );
                })()}
              </select>
            </div>
          </div>
          {selectedCategoryObj?.description && (
            <p className="text-sm font-semibold text-slate-600">
              {selectedCategoryObj.description}
            </p>
          )}
        </div>
      </div>
      <div className="shadow-md bg-gray-100 rounded-md p-1 md:p-5 w-full mt-4 mb-8 flex flex-col gap-6 items-center ">
        <div className="w-full flex flex-row items-center gap-1">
          <TbTargetArrow size={28} className={"text-primary-color"} />

          <h2 className="w-full text-start font-bold text-xl text-slate-600">
            Goals List
          </h2>
        </div>
        {types.length > 0 && goals.length > 0 && (
          <div className="rounded-xl bg-white p-4 w-full flex justify-center">
            <div className="w-full lg:w-5/6 lg:max-w-5xl self-center flex flex-col gap-10">
              {goals
                .filter((goal) => {
                  console.log(
                    "selected category ",
                    selectedCategory,
                    ", typeof id: ",
                    typeof selectedCategory,
                    ". goal category id: ",
                    goal.category_id,
                    ", typeof: ",
                    typeof goal.category_id,
                    ", goal status: ",
                    goal.status,
                    ", current chosen status",
                    status
                  );
                  return (
                    (selectedCategory > 0
                      ? goal.category_id === selectedCategory
                      : true) && goal.status === status
                  );
                })
                .map((goal) => renderGoalComponent(goal))}
            </div>
          </div>
        )}
      </div>
      <AddGoalModal
        view={showAddGoalModal}
        setView={setShowAddGoalModal}
        editGoal={editGoal}
        setEditGoal={setEditGoal}
        category={
          selectedCategory > 0
            ? categories.find((category) => category.id === selectedCategory)
                ?.id ?? null
            : null
        }
      />
      <AddGoalCategoryModal
        view={showAddGoalCategoryModal}
        setView={setShowAddGoalCategoryModal}
        changeCategory={(id) => {
          setSelectedCategory(id);
        }}
        categoryToEdit={categories.find(
          (category) => category.id === editCategoryWithId
        )}
        setCategoryToEdit={setEditCategoryWithId}
      />
      <NotesModal
        viewNotesModal={viewGoalNotes !== null}
        setViewNotesModal={() => {
          setViewGoalNotes(null);
        }}
        goal_id={viewGoalNotes}
      />
      <NewPost
        show={shareGoal !== null}
        setShow={() => {
          setShareGoal(null);
        }}
        username={username}
        goal={shareGoal ? goals.find((g) => g.id === shareGoal) : null}
      />
      <SharedGoalParticipantsModal
        viewParticipantsModal={goalParticipants !== null}
        setViewParticipantsModal={() => {
          setGoalParticipants(null);
        }}
        participants={goalParticipants}
      />
      <DeleteModal
        show={deleteItem !== null}
        setShow={(_) => {
          setDeleteItem(null);
        }}
        item_id={deleteItem?.id}
        cb={() => {
          if (deleteItem.type === "goal") {
            dispatch(
              deleteGoal({ goalId: deleteItem.id, currentUsername: username })
            );
          } else if (deleteItem.type === "category") {
            dispatch(removeCategory(deleteItem.id));
            setSelectedCategory(-3);
          }
        }}
        type={deleteItem?.type}
      />
      <AccountabilityMessages
        viewAccountabilityMessages={viewGoalChat !== null}
        setViewAccountabilityMessages={(_) => {
          setViewGoalChat(null);
        }}
        goal_id={viewGoalChat}
        goal={goals.find((goal) => goal.id === viewGoalChat)}
      />
    </div>
  );
};

export default GoalTracking;
