import React, { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  createBrowserRouter,
  RouterProvider,
  Outlet,
  ScrollRestoration,
} from "react-router-dom";
import Login from "./pages/login/Login";
import MainHub from "./pages/mainhub/MainHub";
import Register from "./pages/register/Register";
import Journal from "./pages/journaling/Journal";
// import SocialFeed from "./pages/social-feed/SocialFeed";
import GoalTracking from "./pages/goal-tracking/GoalTracking";
import Home from "./pages/home/Home";
import HabitTracking from "./pages/habit-tracking/HabitTracking";
import PrivateRoute from "./components/PrivateRoute";
import NotFound from "./components/NotFound/NotFound";
import Profile from "./pages/profile/Profile";
import About from "./pages/about/About";
import AdminPanel from "./pages/adminpanel/AdminPanel";
import { useDispatch, useSelector } from "react-redux";
import {
  authCheck,
  incrementTime,
  selectLoggedIn,
  selectUsername,
  // selectFcmTokenSaved,
  logOut,
  setLoading,
  // setFcmTokenSaved,
} from "./features/userSlice";
import { addNotification } from "./features/notificationsSlice";
import { getFriendsData } from "./features/friendsSlice";
import Search from "./pages/search/Search";

import Comments from "./pages/comments/Comments";
import Navbar from "./components/Navbar";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AccountabilityCenter from "./pages/accountability-center/AccountabilityCenter";
import Updates from "./pages/Updates.js";
import Notifications from "./pages/Notifications";
import { getNotifications } from "./features/notificationsSlice";
import Header from "./components/Header";
import { request } from "./util/util";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import Cookies from "js-cookie";
import Pricing from "./pages/pricing/Pricing";
import "react-loading-skeleton/dist/skeleton.css";
import { getChannels } from "./features/socialSlice.js";
import JournalPage from "./pages/JournalPage.js";
import AddJournal from "./pages/AddJournal.js";

export const router = createBrowserRouter([
  { path: "/", element: <About /> },
  // { path: "/pricing", element: <Pricing /> },
  { path: "/register", element: <Register /> },
  { path: "/login", element: <Login /> },
  { path: "/admin-panel", element: <AdminPanel /> },
  // { path: "*", element: <NotFound /> },
  {
    element: (
      <div className="flex flex-col h-screen">
        <Header />
        <div
          className="flex flex-row mt-14 w-full"
          style={{ height: "calc(100vh - 3.5rem)" }}
        >
          <Navbar />
          <div
            className="p-2 md:p-5 ml-0 md:ml-[320px] w-full md:w-[calc(100vw-320px)] bg-white"
            style={{ height: "calc(100vh - 3.5rem)" }}
          >
            {/* <ScrollRestoration /> */}
            <Outlet />
          </div>
        </div>
      </div>
    ),
    children: [
      {
        path: "/social",
        element: <Home />,
      },
      {
        path: "/profile/:username",
        element: <Profile />,
      },
      {
        path: "/search",
        element: <Search />,
      },
      {
        path: "/comments/:postId",
        element: <Comments />,
      },
      {
        path: "/comments/:postId/:postTitle",
        element: <Comments />,
      },
      {
        element: (
          <PrivateRoute>
            <Outlet />
          </PrivateRoute>
        ),
        children: [
          { path: "updates", element: <Updates /> },
          { path: "notifications", element: <Notifications /> },
          { path: "goals", element: <GoalTracking /> },
          // { path: "journals", element: <JournalPage /> },
          // { path: "journals/add", element: <AddJournal /> },
          // { path: "journals/edit/:id", element: <AddJournal /> },
          // // { path: "goal-prompt/:goalId", element:< GoalPrompt /> }
          // // {
          // //   path: "accountability-center",
          // //   element: <AccountabilityCenter />,
          // // },
        ],
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

function App() {
  const dispatch = useDispatch();
  const username = useSelector(selectUsername);
  const loggedIn = useSelector(selectLoggedIn);

  // const fcmTokenSaved = useSelector(selectFcmTokenSaved);

  const setupNotifications = async () => {
    // console.log("setup notifs");
    const setNotifications = Cookies.get("setNotifications");
    console.log("setNotifications", setNotifications);
    if (!setNotifications) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          // console.log("permission granted");
          const messaging = getMessaging(); // Get the Messaging service instance
          getToken(messaging) // Pass your VAPID key if using web push
            .then(async (token) => {
              // console.log(`fcm token ${token}`);
              const { status } = await request("POST", "save-token", {
                token: token,
              });
              if (status) {
                const expirationDate = new Date(2147483647 * 1000); // Maximum date supported by most browsers
                Cookies.set("setNotifications", "true", {
                  expires: expirationDate,
                });
                // console.log("token saved");
                // dispatch(setFcmTokenSaved(true));
              } else {
                // console.log("failed to save token");
              }
            })
            .catch((error) => {
              // console.error("Error getting token:", error);
            });
          // messaging.onMessage((payload) => {
          //   console.log("Received message:", payload);
          //   // Display the notification
          //   const notification = new Notification(payload.notification.title, {
          //     body: payload.notification.body,
          //   });
          // });
        } else {
          // console.log("User has not granted permission");
        }
      });
    }
  };

  useEffect(() => {
    let timer;
    dispatch(getChannels());
    if (loggedIn) {
      console.log("get friends");
      dispatch(getFriendsData());
      dispatch(getNotifications());
      timer = setInterval(() => {
        request("PUT", "user/increment-time");
      }, 60000);
      setupNotifications();
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [loggedIn]);

  useEffect(() => {
    const formatNotificationPayload = (payload) => {
      return {
        data: {
          username: payload.username,
          goal_title: payload.goal_title,
        },
        ...payload,
      };
    };
    // Foreground message listener
    const messaging = getMessaging();
    const unsubscribeForeground = onMessage(messaging, (payload) => {
      console.log("Foreground message received: ", payload);
      dispatch(addNotification(formatNotificationPayload(payload.data)));
      const { title, body } = payload.notification;
      new Notification(title, { body });
    });

    // Service worker background listener
    let messageHandler;
    if ("serviceWorker" in navigator) {
      messageHandler = (event) => {
        if (
          event.data &&
          event.data.type === "BACKGROUND_NOTIFICATION_RECEIVED"
        ) {
          console.log("Background notification received:", event.data.payload);
          dispatch(
            addNotification(formatNotificationPayload(event.data.payload.data))
          );
        }
      };
      navigator.serviceWorker.addEventListener("message", messageHandler);
    }
    dispatch(authCheck());

    // Cleanup both listeners on unmount
    return () => {
      if (unsubscribeForeground) unsubscribeForeground();
      if (messageHandler) {
        navigator.serviceWorker.removeEventListener("message", messageHandler);
      }
    };
  }, [dispatch]);

  // USE WHEN UPDATING
  // useEffect(() => {
  //   toast.info(
  //     "The website is currently under maintenance. Please check back later.",
  //     {
  //       autoClose: false, // Prevents auto-dismissal
  //       closeOnClick: true, // Allows user to close manually
  //       draggable: true, // Enables drag-to-dismiss
  //       position: "top-right", // Optional: Adjust position
  //     }
  //   );
  // }, []);

  return (
    <div className="app">
      {/* <Navbar
        showNav={showNav}
        toggleMenu={toggleMenu}
        loggedIn={loggedIn}
        username={username}
      /> */}
      <RouterProvider router={router} />
      <ToastContainer />
    </div>
  );
}

export default App;
