import React, { useState, useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import Modal from "./Modal";
import { request } from "../util/util";
import { FaNoteSticky } from "react-icons/fa6";
import { toast } from "react-toastify";
import GoalPost from "./GoalPost/GoalPost";
import { OrbitProgress } from "react-loading-indicators";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ProfileGoals = ({ username }) => {
  const [goals, setGoals] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const { res, msg, status } = await request(
        "GET",
        `profile/goals/${username}`
      );
      console.log("res: ", res);
      if (status) {
        setGoals(res);
      } else {
        toast.error("Error");
      }
      setLoading(false);
    };

    fetchData();
  }, [username]);

  return (
    <div className="p-3 lg:px-10 flex flex-col w-full items-center">
      {loading ? (
        <OrbitProgress
          color="#32cd32"
          size="small"
          text=""
          textColor=""
          style={{ fontSize: "6px" }}
        />
      ) : goals && goals.length > 0 ? (
        <div className="w-full max-w-[850px] flex flex-col items-center gap-3 lg:gap-8 pt-3">
          <p className="text-lg font-medium text-gray-500">
            Below are {username}’s in-progress goals, shown according to their
            chosen visibility settings.
          </p>
          {goals.map((goal) => (
            <GoalPost data={goal} />
          ))}
        </div>
      ) : (
        <p>No in-progress goals are visible to you at this time.</p>
      )}
    </div>
  );
};

export default ProfileGoals;
