import React, { useState, useEffect } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom"; // Import Link from react-router-dom if you're using it
import "./Login.css";
import { useSelector, useDispatch } from "react-redux";
import { selectLoggedIn, logIn } from "../../features/userSlice";
import AuthHeader from "../../components/AuthHeader/AuthHeader";
import { Helmet } from "react-helmet-async";
import SEO from "../../components/SEO";
import SocialLinks from "../../components/SocialLinks";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const Login = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(selectLoggedIn);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const redirectURL = params.get("redirect") || "/social";
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  if (loggedIn) return <Navigate to={`${redirectURL}`} />;

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Email:", email);
    // console.log("Password:", password);

    try {
      let response = await fetch(BASE_URL + "/login", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        withCredentials: true,
        credentials: "include",
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        let res = await response.json();
        dispatch(logIn(res));
        // console.log("login successful");
      } else {
        alert("Email or password incorrect");
        // console.log("Email or password incorrect");
      }
    } catch (error) {
      // console.error("Error:", error.message);
    }
  };

  const inputClasses =
    "border-[1px] p-2 border-gray-200 rounded-md w-full w-full focus:outline-none focus:border-primary-color font-regular text-sm";
  return (
    <>
      {/* <Helmet>
        <title>Login to Friengle - Access Your Goal Tracking Dashboard</title>
        <meta
          name="description"
          content="Log in to your Friengle account to continue your goal achievement journey. Stay connected with your community and track your progress."
        />
      </Helmet> */}
      <SEO
        title={"Login - Friengle"}
        description={
          "Log in to your Friengle account to continue your goal achievement journey."
        }
      />
      <AuthHeader page={"login"} />
      <form
        className="flex flex-col gap-12 items-center w-auto pt-[15vh]"
        onSubmit={handleSubmit}
      >
        <h1 className="text-2xl  xl:text-3xl font-medium text-center">Login</h1>
        <div className="flex flex-col gap-2 w-full max-w-[400px]">
          <input
            placeholder="Email Address"
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            className={inputClasses}
            required
          />
          <input
            placeholder="Password"
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            className={inputClasses}
            required
          />
          {/* <Link className="self-start text-sm text-secondary-color font-light text-md">
            Forgot your password?
          </Link> */}
        </div>
        <div className="flex flex-col items-center gap-2">
          <button
            type="submit"
            className="bg-primary-color rounded-md px-5 py-2 font-semibold text-white text-md w-48 border-2 border-primary-color hover:bg-white hover:text-primary-color transition-colors duration-300"
          >
            Login
          </button>
          <p className="font-medium text-lg">Or</p>
          <button
            type="button"
            onClick={() => {
              navigate("/social?section=community");
            }}
            className="bg-gray-200 rounded-md px-5 py-2 font-semibold w-48 border-2 border-gray-200 hover:bg-white hover transition-colors duration-300"
          >
            Continue as guest
          </button>
          <p className="text-start w-full text-sm mt-5">
            <span className="font-regular">Don't have an account? </span>
            <button
              className="text-primary-color font-semibold"
              onClick={() => {
                navigate("/register");
              }}
            >
              Sign Up for Free
            </button>
          </p>
        </div>
        <SocialLinks />
      </form>
    </>
  );
};

export default Login;
